import React from 'react'
import { Text } from '../../ui-kit'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

const SUPPLI_TOS_URL = 'https://www.gosuppli.com/terms-of-service'
const SUPPLI_PP_URL = 'https://www.gosuppli.com/privacy-policy'
const SUPPLI_CAA_URL = 'https://www.gosuppli.com/customer-account-agreement'
const TermsAndConditions = ({ className, color, disclaimerKey, specialMessaging }) => {
  const { t } = useTranslation()

  return (
    <>
      <Text className={className} color={color}>
        {t(disclaimerKey)}{' '}
        <a className={'blue'} href={SUPPLI_TOS_URL} rel="noreferrer" target="_blank">
          {t('termsOfService')}
        </a>
        {', '}
        <a className={'blue'} href={SUPPLI_PP_URL} rel="noreferrer" target="_blank">
          {t('privacyPolicy')}
        </a>{' '}
        {t('and')}{' '}
        <a className={'blue'} href={SUPPLI_CAA_URL} rel="noreferrer" target="_blank">
          {t('customerAccountAgreement')}
        </a>
        {'.'}
      </Text>
      {specialMessaging && <Text> {specialMessaging}</Text>}
    </>
  )
}

TermsAndConditions.propTypes = {
  color: PropTypes.string,
  className: PropTypes.string,
  disclaimerKey: PropTypes.string,
  specialMessaging: PropTypes.string,
}

export default TermsAndConditions
