import PropTypes from 'prop-types'
import React, { useCallback } from 'react'
import IssueRefundForm from './IssueRefundForm'
import { LoadingSpinner, Dialog } from '../../../ui-kit'
import { useTranslation } from 'react-i18next'
import { useDirtyFormAlert } from '../../../hooks/useDirtyFormAlert'

const IssueRefundModal = ({ isOpened, transaction, setIsOpened, refundTransaction, loading }) => {
  const { t } = useTranslation()
  const closeForm = useCallback(() => {
    setIsOpened(false)
  }, [setIsOpened])
  const { isFormDirty, setDirtyFormState, requestClose } = useDirtyFormAlert({
    closeForm,
  })

  return (
    <Dialog
      isOpened={isOpened}
      setIsOpened={requestClose}
      title={t('issueRefund')}
      titleClass="text-2xl font-semibold pl-2">
      {transaction ? (
        <IssueRefundForm
          closeForm={closeForm}
          data={transaction}
          isFormDirty={isFormDirty}
          loading={loading}
          refundTransaction={refundTransaction}
          requestClose={requestClose}
          setDirtyFormState={setDirtyFormState}
          setIsOpenedModal={setIsOpened}
        />
      ) : (
        <LoadingSpinner loading />
      )}
    </Dialog>
  )
}

IssueRefundModal.propTypes = {
  loading: PropTypes.bool,
  isOpened: PropTypes.bool.isRequired,
  transaction: PropTypes.object,
  setIsOpened: PropTypes.func.isRequired,
  refundTransaction: PropTypes.func.isRequired,
}

export default IssueRefundModal
