import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { PageDialog } from '../../../ui-kit'
import DataGridComponent from '../../../components/dataGrid/DataGridComponent'
import ReportFooter from '../reportFooter/ReportFooter'
import ReportTotal from '../reportTotal/ReportTotal'
import { getRefundsColumns, getRefundsFiltersColumns, getRefundsTotalItems } from './logic'
import { getReportsData, getReportsTotal } from '../logic/reportsUtils'
import ReportsFilters from '../reportsFilters/ReportsFilters'
import { getPaginationData } from '../../../utils/utils'
import { useCurrentVendor } from '../../../hooks/useCurrentVendor'

const Refunds = ({
  data,
  isOpened,
  closeReport,
  reportMetadata,
  userFilters,
  page,
  loading,
  sort,
}) => {
  const { t } = useTranslation()
  const columns = useMemo(() => getRefundsColumns(t), [t])
  const rows = useMemo(() => getReportsData(data), [data])
  const paginationData = useMemo(() => getPaginationData(data), [data])
  const total = useMemo(() => getReportsTotal(data), [data])
  const totalItems = useMemo(() => getRefundsTotalItems(total), [total, paginationData])
  const filterColumns = useMemo(() => getRefundsFiltersColumns(t), [t])
  const vendorData = useCurrentVendor()
  const locationsList = useMemo(
    () =>
      vendorData.locations?.map?.((item) => ({
        key: item.id,
        title: item.name,
      })) || [],
    [vendorData?.locations],
  )
  const availableFilters = [
    {
      key: 'date',
      type: 'dates_range',
    },
    {
      key: 'payment_method_type',
      options: [
        { key: 'ClearentPaymentMethodConfiguration', title: 'Card' },
        { key: 'ACHQPaymentMethodConfiguration', title: 'Bank Transfer' },
      ],
      type: 'select',
    },
    {
      key: 'status',
      options: [
        { title: 'pending', key: 'pending' },
        { title: 'refunded', key: 'refunded' },
        { title: 'failed', key: 'failed' },
      ],
      type: 'select',
    },
    {
      key: 'location',
      options: locationsList,
      type: 'select',
    },
  ]

  return (
    <PageDialog
      bottomPanel={
        <ReportFooter
          closeReport={closeReport}
          reportMetadata={reportMetadata}
          sort={sort}
          userFilters={userFilters}
        />
      }
      isOpened={isOpened}
      setIsOpened={closeReport}
      title={t('refunds')}>
      <div className="px-8 w-full">
        <ReportsFilters availableFilters={availableFilters} columns={filterColumns} />

        <div className="pt-4">
          <DataGridComponent
            columns={columns}
            loading={loading}
            page={page}
            pageSize={100}
            paginationData={paginationData}
            rows={rows}
          />
        </div>

        <ReportTotal
          itemSeparatorClassName="ml-4"
          items={totalItems}
          totalLabelKey="grandTotal"
          wrapperClassName="py-4 px-2"
        />
      </div>
    </PageDialog>
  )
}

Refunds.propTypes = {
  data: PropTypes.object,
  page: PropTypes.number,
  sort: PropTypes.string,
  loading: PropTypes.bool,
  isOpened: PropTypes.bool.isRequired,
  closeReport: PropTypes.func.isRequired,
  userFilters: PropTypes.arrayOf(PropTypes.any),
  reportMetadata: PropTypes.shape({
    csvUrl: PropTypes.string,
    xlsxUrl: PropTypes.string,
    jsonUrl: PropTypes.string,
    fileNameKey: PropTypes.string,
    defaultFilters: PropTypes.object,
  }).isRequired,
}

export default Refunds
