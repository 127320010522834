import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { Button, Flex, FormattedDate, Icon, LoadingSpinner, Money, Text } from '../../ui-kit'
import { useTranslation } from 'react-i18next'
import fontWeight from '../../ui-kit/fontWeight'
import sizes from '../../ui-kit/sizes'
import buttonsVariants from '../../ui-kit/buttonsVariants'
import { paymentResultMods } from '../../constants/paymentResults'

const PaymentResult = ({ data, mode, setMode, onDoneClick, failureReason }) => {
  const { t } = useTranslation()

  const actionCTA = useMemo(() => {
    switch (mode) {
      case paymentResultMods.SERVER_ERROR:
      case paymentResultMods.CREDIT_CARD_FAILURE:
      case paymentResultMods.ACH_FAILURE:
        return (
          <Button
            className="w-48"
            label={t('retryPayment')}
            onClick={() => setMode(null)}
            testData="retry_payment"
          />
        )
      default:
        return null
    }
  }, [t, setMode, mode])
  const doneCTA = useMemo(() => {
    if (mode === paymentResultMods.LOADING) {
      return null
    }

    const buttonVariant =
      mode === paymentResultMods.SERVER_ERROR ||
      mode === paymentResultMods.CREDIT_CARD_FAILURE ||
      mode === paymentResultMods.ACH_FAILURE
        ? buttonsVariants.SECONDARY
        : buttonsVariants.PRIMARY

    return (
      <Button
        className="w-48"
        label={t('done')}
        onClick={onDoneClick}
        testData="done"
        variant={buttonVariant}
      />
    )
  }, [t, mode, onDoneClick])
  const paymentDetailsBody = useMemo(
    () =>
      !!data && (
        <>
          <Text align="center" className="py-5" fontWeight={fontWeight.SEMIBOLD} size={sizes.XL}>
            {t('paymentDetails')}
          </Text>
          <Flex className="whitespace-pre-wrap text-left" column>
            <div>
              <Text fontWeight={fontWeight.BOLD} size={sizes.LG}>
                {`${t('id')}: `}
              </Text>
              <Text size={sizes.LG}>{data.paymentId}</Text>
            </div>
            <div>
              <Text fontWeight={fontWeight.BOLD} size={sizes.LG}>
                {`${t('accountNumber')}: `}
              </Text>
              <Text size={sizes.LG}>{data.accountNumber}</Text>
            </div>
            <div>
              <Text fontWeight={fontWeight.BOLD} size={sizes.LG}>
                {`${t('accountName')}: `}
              </Text>
              <Text size={sizes.LG}>{data.accountName}</Text>
            </div>
            <div>
              <Text fontWeight={fontWeight.BOLD} size={sizes.LG}>
                {`${t('createdBy')}: `}
              </Text>
              <Text size={sizes.LG}>{data.createdBy}</Text>
            </div>
            <div>
              <Text fontWeight={fontWeight.BOLD} size={sizes.LG}>
                {`${t('paymentDate')}: `}
              </Text>
              <Text size={sizes.LG}>
                <FormattedDate date={data.paymentDate} />
              </Text>
            </div>
            <div>
              <Text fontWeight={fontWeight.BOLD} size={sizes.LG}>
                {`${t('paymentAmount')}: `}
              </Text>
              <Text size={sizes.LG}>
                <Money value={data.paymentAmount} />
              </Text>
            </div>
            <div>
              <Text fontWeight={fontWeight.BOLD} size={sizes.LG}>
                {`${t('chargedAmount')}: `}
              </Text>
              <Text size={sizes.LG}>
                <Money value={data.chargedAmountCents} />
              </Text>
            </div>
            <div>
              <Text fontWeight={fontWeight.BOLD} size={sizes.LG}>
                {`${t('paymentMethod')}: `}
              </Text>
              <Text size={sizes.LG}>{data.paymentMethodTitle}</Text>
            </div>
          </Flex>
        </>
      ),
    [t, data],
  )
  const body = useMemo(() => {
    switch (mode) {
      case paymentResultMods.LOADING:
        return <LoadingSpinner className="w-full h-full" loading />
      case paymentResultMods.ACH_SUCCESS:
        return (
          <>
            <Icon
              backgroundColor="white"
              className="w-28 h-28 border border-green-450 stroke-0.3"
              color="text-green-450"
              name="fastForward"
              type="outline"
            />
            <Text align="center" className="mt-16" fontWeight={fontWeight.SEMIBOLD} size={sizes.XL}>
              {t('achPaymentPending')}
            </Text>
            <Text
              align="center"
              className="mt-10"
              fontWeight={fontWeight.SEMIBOLD}
              size={sizes.BASE}
              testData="payment-result-title">
              {t('achPaymentPendingDescription')}
            </Text>
            {paymentDetailsBody}
          </>
        )
      case paymentResultMods.SERVER_ERROR:
        return (
          <>
            <Icon
              backgroundColor="white"
              className="w-28 h-28 stroke-0.3"
              color="text-black-700"
              name="terminal"
              type="outline"
            />
            <Text
              align="center"
              className="mt-10"
              fontWeight={fontWeight.SEMIBOLD}
              size={sizes.XL}
              testData="payment-result-title">
              {t('paymentServerError')}
            </Text>
            <Text align="center" className="mt-10" size={sizes.LG}>
              {t('paymentServerErrorDescription')}
            </Text>
          </>
        )
      case paymentResultMods.CREDIT_CARD_SUCCESS:
        return (
          <>
            <Icon
              backgroundColor="green"
              className="w-28 h-28 stroke-0.3"
              color="text-white"
              name="currencyDollar"
              type="outline"
            />
            <Text
              align="center"
              className="mt-10"
              fontWeight={fontWeight.SEMIBOLD}
              size={sizes.XL}
              testData="payment-result-title">
              {t('paymentWasSuccessful')}
            </Text>
            <Text align="center" className="mt-5" size={sizes.LG}>
              {t('paymentWasSuccessfulHint')}
            </Text>
            {paymentDetailsBody}
          </>
        )
      case paymentResultMods.CREDIT_CARD_FAILURE:
        return (
          <>
            <Icon
              backgroundColor="red"
              className="w-28 h-28 stroke-0.3"
              color="text-white"
              name="x"
              type="outline"
            />
            <Text
              align="center"
              className="mt-10"
              fontWeight={fontWeight.SEMIBOLD}
              size={sizes.XL}
              testData="payment-result-title">
              {t('cardPaymentFailed')}
            </Text>
            {failureReason && (
              <Text align="center" className="mt-10" size={sizes.LG}>
                [{failureReason}]
              </Text>
            )}
            <Text align="center" className="mt-10" size={sizes.LG}>
              {t('cardPaymentFailedHint')}
            </Text>
          </>
        )
      case paymentResultMods.ACH_FAILURE:
        return (
          <>
            <Icon
              backgroundColor="red"
              className="w-28 h-28 stroke-0.3"
              color="text-white"
              name="x"
              type="outline"
            />
            <Text
              align="center"
              className="mt-10"
              fontWeight={fontWeight.SEMIBOLD}
              size={sizes.XL}
              testData="payment-result-title">
              {t('achPaymentFailure')}
            </Text>
            {failureReason && (
              <Text align="center" className="mt-10" size={sizes.LG}>
                [{failureReason}]
              </Text>
            )}
            <Text align="center" className="mt-10" size={sizes.LG}>
              {t('cardPaymentFailedHint')}
            </Text>
          </>
        )
      default:
        return null
    }
  }, [mode, paymentDetailsBody])

  return (
    <Flex className="w-146 h-full" column>
      <Flex alignItems="center" className="flex-1" column>
        {body}
      </Flex>
      <Flex
        className={cx('w-full mt-12', actionCTA && 'px-16 px-20')}
        justifyContent={actionCTA ? 'between' : 'center'}>
        {doneCTA}
        {actionCTA}
      </Flex>
    </Flex>
  )
}

PaymentResult.propTypes = {
  data: PropTypes.object,
  mode: PropTypes.string.isRequired,
  setMode: PropTypes.func.isRequired,
  onDoneClick: PropTypes.func.isRequired,
  failureReason: PropTypes.string.isRequired,
}

export default React.memo(PaymentResult)
