import PT from 'prop-types'
import React from 'react'
import { Field, Form } from 'react-final-form'
import { Button, Input } from '../../ui-kit'
import { validateEmail, validatePhoneNumber, validateRequiredField } from '../../utils/validators'
import { useTranslation } from 'react-i18next'
import buttonsVariants from '../../ui-kit/buttonsVariants'
import { denormalizePhoneNumber, normalizePhone } from '../../utils'
import { useBeforeUnload } from '../../hooks/useBeforeUnload'
import AlertModal from '../../ui-kit/components/alertModal/AlertModal'

const InviteUserForm = ({
  closeForm,
  isFormDirty,
  requestClose,
  setDirtyFormState,
  createVendorUser,
  loading,
}) => {
  const { t } = useTranslation()
  const initialValues = {
    email: '',
    lastName: '',
    firstName: '',
    phoneNumber: '',
    officePhoneNumber: '',
  }

  const handleSubmitForm = async (values) => {
    await createVendorUser({
      ...values,
      phoneNumber: denormalizePhoneNumber(values.phoneNumber),
      officePhoneNumber: values.officePhoneNumber
        ? denormalizePhoneNumber(values.officePhoneNumber)
        : null,
    })
  }

  return (
    <Form
      initialValues={initialValues}
      onSubmit={handleSubmitForm}
      render={({ handleSubmit, dirty }) => {
        useBeforeUnload({ when: dirty })
        dirty !== isFormDirty && setDirtyFormState(dirty)

        return (
          <form className="flex flex-col mt-6 py-0 px-2" onSubmit={handleSubmit}>
            <div className="w-full mr-4 flex flex-row">
              <div className="w-6/12">
                <Field name="firstName">
                  {({ input, meta }) => {
                    return (
                      <Input
                        errorMessage={
                          meta.error && meta.touched && meta.submitFailed ? meta.error : undefined
                        }
                        id={input.name}
                        label={t('firstName')}
                        placeholder={t('firstName')}
                        {...input}
                      />
                    )
                  }}
                </Field>
              </div>

              <div className="ml-4 w-6/12">
                <Field name="lastName">
                  {({ input, meta }) => {
                    return (
                      <Input
                        errorMessage={
                          meta.error && meta.touched && meta.submitFailed ? meta.error : undefined
                        }
                        id={input.name}
                        label={t('lastName')}
                        placeholder={t('lastName')}
                        {...input}
                      />
                    )
                  }}
                </Field>
              </div>
            </div>

            <div className="w-full mt-6">
              <Field name="phoneNumber" parse={normalizePhone}>
                {({ input, meta }) => {
                  return (
                    <Input
                      errorMessage={
                        meta.error && meta.touched && meta.submitFailed ? meta.error : undefined
                      }
                      id={input.name}
                      label={t('mobilePhone')}
                      placeholder={t('phone')}
                      {...input}
                    />
                  )
                }}
              </Field>
            </div>

            <div className="w-full mt-6">
              <Field name="officePhoneNumber" parse={normalizePhone}>
                {({ input, meta }) => {
                  return (
                    <Input
                      errorMessage={
                        meta.error && meta.touched && meta.submitFailed ? meta.error : undefined
                      }
                      id={input.name}
                      label={t('officePhone')}
                      placeholder={t('phone')}
                      {...input}
                    />
                  )
                }}
              </Field>
            </div>

            <div className="w-full mt-6">
              <Field name="email">
                {({ input, meta }) => {
                  return (
                    <Input
                      errorMessage={
                        meta.error && meta.touched && meta.submitFailed ? meta.error : undefined
                      }
                      id={input.name}
                      label={t('email')}
                      placeholder={t('email')}
                      type="email"
                      {...input}
                    />
                  )
                }}
              </Field>
            </div>

            <div className="w-full mt-6 flex flex-row justify-end">
              <Button
                className="mr-2"
                disabled={loading}
                label={t('cancel')}
                onClick={requestClose}
                testData="cancel-invite"
                variant={buttonsVariants.TERTIARY}
              />
              <Button label={t('add')} testData="add-user" type="submit" />
            </div>

            <AlertModal confirmClose={closeForm} />
          </form>
        )
      }}
      validate={(values) => ({
        email: validateRequiredField(values.email) || validateEmail(values.email),
        lastName: validateRequiredField(values.lastName),
        firstName: validateRequiredField(values.firstName),
        phoneNumber: validatePhoneNumber(values.phoneNumber),
        officePhoneNumber: values.officePhoneNumber
          ? validatePhoneNumber(values.officePhoneNumber)
          : undefined,
      })}
    />
  )
}

InviteUserForm.propTypes = {
  closeForm: PT.func.isRequired,
  isFormDirty: PT.bool.isRequired,
  requestClose: PT.func.isRequired,
  loading: PT.bool,
  setDirtyFormState: PT.func.isRequired,
  createVendorUser: PT.func.isRequired,
}

export default InviteUserForm
