import PropTypes from 'prop-types'
import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Flex, Text, Dialog } from '../../../ui-kit'
import buttonsVariants from '../../../ui-kit/buttonsVariants'
import fontWeight from '../../../ui-kit/fontWeight'
import sizes from '../../../ui-kit/sizes'
import { useDirtyFormAlert } from '../../../hooks/useDirtyFormAlert'
import { newPaymentModes } from './modes'
import ReceivePaymentForm from './ReceivePaymentForm'
import RequestPaymentForm from './RequestPaymentForm'

const NewPaymentModal = ({ isOpened, setIsOpened, refetchPayment }) => {
  const { t } = useTranslation()
  const [mode, setMode] = useState(newPaymentModes.SELECT)

  const closeForm = useCallback(() => {
    setIsOpened(false)
    setMode(newPaymentModes.SELECT)
    refetchPayment()
  }, [setIsOpened])
  const { isFormDirty, setDirtyFormState, requestClose } = useDirtyFormAlert({
    closeForm,
  })

  return (
    <Dialog
      isOpened={isOpened}
      setIsOpened={requestClose}
      title={mode === newPaymentModes.REQUEST_PAYMENT ? t('newPaymentRequest') : t('newPayment')}
      titleClass={'font-semibold text-2xl'}>
      {mode === newPaymentModes.SELECT && (
        <Flex className="px-12" column>
          <Button
            className="mt-6"
            contentClassName="!justify-start"
            label={
              <Flex onClick={() => setMode(newPaymentModes.RECEIVE_PAYMENT)} column>
                <Text fontWeight={fontWeight.MEDIUM} size={sizes.LG}>
                  {t('receivePayment')}
                </Text>
                <Text color="text-warmBlack-400">{t('processPaymentDirectly')}</Text>
              </Flex>
            }
            testData="add-payment-directly"
            variant={buttonsVariants.SECONDARY}
          />
          <Button
            className="mt-6"
            contentClassName="!justify-start"
            label={
              <Flex onClick={() => setMode(newPaymentModes.REQUEST_PAYMENT)} column>
                <Text fontWeight={fontWeight.MEDIUM} size={sizes.LG}>
                  {t('requestPayment')}
                </Text>
                <Text color="text-warmBlack-400">{t('sendPaymentPaylink')}</Text>
              </Flex>
            }
            testData="request-payment"
            variant={buttonsVariants.TERTIARY}
          />
        </Flex>
      )}
      {mode === newPaymentModes.RECEIVE_PAYMENT && (
        <ReceivePaymentForm
          closeForm={closeForm}
          isFormDirty={isFormDirty}
          isOpened={isOpened}
          requestClose={requestClose}
          setDirtyFormState={setDirtyFormState}
        />
      )}
      {mode === newPaymentModes.REQUEST_PAYMENT && (
        <RequestPaymentForm
          closeForm={closeForm}
          isFormDirty={isFormDirty}
          requestClose={requestClose}
          setDirtyFormState={setDirtyFormState}
        />
      )}
    </Dialog>
  )
}

NewPaymentModal.propTypes = {
  isOpened: PropTypes.bool.isRequired,
  setIsOpened: PropTypes.func.isRequired,
  refetchPayment: PropTypes.func,
}

export default NewPaymentModal
