import React from 'react'
import { Outlet } from 'react-router-dom'
import { getBrowserInfo } from '../../utils/getBrowserInfo'
import DesktopAnonymousLayout from './DesktopAnonymousLayout'
import MobileAnonymousLayout from './MobileAnonymousLayout'

const AnonymousLayout = () => {
  const { isMobile } = getBrowserInfo()

  return isMobile ? (
    <MobileAnonymousLayout>
      <Outlet />
    </MobileAnonymousLayout>
  ) : (
    <DesktopAnonymousLayout>
      <Outlet />
    </DesktopAnonymousLayout>
  )
}

export default AnonymousLayout
