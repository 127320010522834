import React, { useEffect } from 'react'
import { LoadingSpinner, Section, Tabs } from '../../ui-kit'
import { useTranslation } from 'react-i18next'
import { snakeCase, camelCase, isEqual } from 'lodash'
import { Route, useLocation, useNavigate, Routes, Navigate } from 'react-router-dom'
import { useBreadcrumbs } from '../../hooks/useBreadcrumbs'
import PageHeader from '../../components/headers/PageHeader'
import { useCurrentUser } from '../../hooks/useCurrentUser'
import { membershipRoles } from './teamManagement/membershipRoles'
import CompanyInformation from './CompanyInformation'
import TeamManagement from './TeamManagement'
import getVendorSlug from '../../utils/getVendorSlug'

const Settings = () => {
  const tabs = [
    { name: 'companyInformation', breadcrumbLabel: 'companyInformation' },
    { name: 'teamManagement', breadcrumbLabel: 'teamManagement' },
  ]
  const location = useLocation()
  const navigate = useNavigate()
  const vendorSlug = getVendorSlug()
  const currentUser = useCurrentUser()

  const { t } = useTranslation()
  const currentTabPage = location.pathname.split('/')[3]
  const page = currentTabPage || tabs[0].link

  const tabsMap = tabs.map((tab) => ({
    label: t(tab.name),
    active: camelCase(page) === tab.name,
    link: snakeCase(tab.name),
    breadcrumbLabel: t(tab.breadcrumbLabel),
  }))

  const { setBreadcrumbs } = useBreadcrumbs()

  useEffect(() => {
    const breadcrumbs = [{ label: t('settings'), link: `${vendorSlug}/settings` }]
    if (currentTabPage) {
      breadcrumbs.push({
        label: t(tabsMap.find((tab) => isEqual(tab.link, currentTabPage)).breadcrumbLabel),
      })
    } else {
      breadcrumbs.push({ label: t('allSettings') })
    }
    setBreadcrumbs(breadcrumbs)
  }, [page])

  useEffect(() => {
    if (!currentUser?.membershipRole) {
      return
    }

    if (currentUser.membershipRole !== membershipRoles.ADMIN) {
      navigate(`${vendorSlug}/payments`)
    }
  }, [currentUser?.membershipRole, vendorSlug])

  return (
    <div>
      <PageHeader headerTitle={t('settings')} />
      <Tabs tabs={tabsMap} />
      <Section className={'mt-6'} withTitle>
        {currentUser?.membershipRole !== membershipRoles.ADMIN && <LoadingSpinner loading />}
        {currentUser?.membershipRole === membershipRoles.ADMIN && (
          <Routes>
            <Route element={<Navigate to="company_information" replace />} path="/" />
            <Route element={<CompanyInformation />} path="/company_information" />
            <Route element={<TeamManagement />} path="/team_management" />
          </Routes>
        )}
      </Section>
    </div>
  )
}

export default Settings
