import { applyUserSignOut } from '../store/actions/auth'
import { captureCritical, captureInfo } from './rollbar'

export const parseRestError = ({
  key,
  error,
  rollbar,
  dispatch,
  onFailed,
  rollbarOptions,
  newNotification,
}) => {
  if (error.isAxiosError && error.response) {
    const { status, data, statusText } = error.response

    if (status === 440) {
      newNotification({
        error: data?.errors?.length ? data.errors.join(', ') : 'Current session has expired.',
      })
      dispatch(applyUserSignOut())
    }

    if (status === 404) {
      captureInfo(rollbar, error, {
        target: `${key} onError`,
        ...rollbarOptions,
      })

      if (data || statusText) {
        newNotification({ error: data || statusText })
      }
    }

    if (status === 422) {
      captureInfo(rollbar, error, {
        target: `${key} onError`,
        ...rollbarOptions,
      })

      if (data) {
        if (data.errors?.base) {
          newNotification({ error: data.errors.base?.map((item) => item.details)?.join(', ') })
        } else {
          newNotification({ error: 'Operation failed' })
        }
      }
    }

    if (status === 500) {
      captureCritical(rollbar, error, {
        target: `${key} onError`,
        ...rollbarOptions,
      })
      newNotification({ error: 'An unexpected error has occurred.' })
    }
  } else {
    captureCritical(rollbar, error, {
      target: `${key} onError`,
      ...rollbarOptions,
    })
    newNotification({ error: 'An unhandled error has occurred.' })
  }

  onFailed?.(error.response)
}
