export const SUPPLI_SUPPORT_EMAIL = 'support@gosuppli.com'
export const SUPPLI_SUPPORT_PHONE = '432 755 5506'
export const SUPPLI_SUPPORT_URL = 'https://support.gosuppli.com'
export const TERMS_OF_SERVICE = 'https://www.gosuppli.com/terms-of-service'
export const PRIVACY_POLICY = 'https://www.gosuppli.com/privacy-policy'

export const createPaymentMethodTabs = {
  CARD: 'card',
  ACH: 'ach',
}

export const GET = 'get'
export const POST = 'post'
