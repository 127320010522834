import { paymentStatus } from '../../../constants/operationResults'

export const paymentStatuses = {
  INITIAL: 'initial',
  IN_PROCESS: 'in_process',
  PENDING: 'pending',
  PAID: 'paid',
  VOIDED: 'voided',
  FAILED: 'failed',
}

export const statusPaymentMapping = {
  [paymentStatuses.INITIAL]: { label: 'pending', color: 'gray' },
  [paymentStatuses.IN_PROCESS]: { label: 'pending', color: 'gray' },
  [paymentStatuses.PAID]: { label: 'successful', color: 'green' },
  [paymentStatuses.FAILED]: { label: 'failed', color: 'red' },
  [paymentStatuses.VOIDED]: { label: 'voided', color: 'red' },
}

export const displayStatuses = {
  PENDING: 'pending',
  SUCCESSFUL: 'successful',
  FAILED: 'failed',
  VOIDED: 'voided',
  AUTHORIZED: 'authorized',
}

export const displayStatusesMapping = {
  [paymentStatus.PAID]: { label: 'successful', color: 'green' },
  [paymentStatus.PROCESSING]: { label: 'pending', color: 'gray' },
  [paymentStatus.SUBMITTED]: { label: 'pending', color: 'gray' },
  [paymentStatus.ENQUEUED]: { label: 'pending', color: 'gray' },
  [paymentStatus.FAILED]: { label: 'failed', color: 'red' },
  [paymentStatus.CHARGEBACK]: { label: 'chargeback', color: 'red' },
  [paymentStatus.VOIDED]: { label: 'voided', color: 'white' },
}

export const getPaymentMethodTitle = (payment, t) => payment?.paymentMethod?.title || t('cashCheck')
