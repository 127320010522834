import { Button, Flex, Input } from '../../../ui-kit'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Field, Form } from 'react-final-form'
import {
  validateEmail,
  validatePhoneNumber,
  validateRequiredField,
} from '../../../utils/validators'
import PT from 'prop-types'
import { useNotifications } from '../../../hooks/useNotifications'
import buttonsVariants from '../../../ui-kit/buttonsVariants'
import { denormalizePhoneNumber, normalizePhone } from '../../../utils'
import { useBeforeUnload } from '../../../hooks/useBeforeUnload'
import AlertModal from '../../../ui-kit/components/alertModal/AlertModal'
import { useDirtyFormAlert } from '../../../hooks/useDirtyFormAlert'
import { companyInformationModes } from './modes'
import { useCustomMutation } from '../../../hooks/useCustomMutation'
import { PUBLIC_VENDOR_INFO_KEY } from '../../../constants/queryKeys'
import axios from 'axios'
import getVendorSlug from '../../../utils/getVendorSlug'
import { setVendorData } from '../../../store/actions/auth'
import { useDispatch } from 'react-redux'
import Textarea from '../../../ui-kit/components/inputs/Textarea'

const ContactInformationEditForm = ({ vendor, setMode }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const vendorSlug = getVendorSlug()
  const { newNotification } = useNotifications()

  const initialValues = {
    email: vendor.email,
    phoneNumberExtension: vendor.phoneNumberExtension || '',
    phoneNumber: vendor.phoneNumber,
    specialMessaging: vendor.specialMessaging,
  }

  const [updateVendor, { loading }, updateQuery] = useCustomMutation({
    onCompleted: (vendorData) => {
      updateQuery({ queryKey: [PUBLIC_VENDOR_INFO_KEY], record: vendorData })
      dispatch(setVendorData(vendorData))
      newNotification({ success: t('informationWasUpdated') })
      setMode(companyInformationModes.VIEW)
    },
    rollbarOptions: { operationName: 'update_vendor', target: 'CompanyInformationEditForm' },
    mutationOptions: {
      mutationFn: (data) => axios.put(`${vendorSlug}/vendor`, { vendor: { ...data } }),
      mutationKey: ['update_vendor'],
    },
  })

  const onSubmit = async (values) => {
    const data = {
      email: values.email,
      phoneNumberExtension: values.phoneNumberExtension || '',
      phoneNumber: denormalizePhoneNumber(values.phoneNumber),
      specialMessaging: values.specialMessaging,
    }
    await updateVendor(data)
  }

  const closeForm = useCallback(() => {
    setMode(companyInformationModes.VIEW)
  }, [setMode])
  const { isFormDirty, setDirtyFormState, requestClose } = useDirtyFormAlert({
    closeForm,
  })

  return (
    <div className="w-full">
      <Form
        initialValues={initialValues}
        onSubmit={onSubmit}
        render={({ handleSubmit, dirty }) => {
          useBeforeUnload({ when: dirty })
          dirty !== isFormDirty && setDirtyFormState(dirty)

          return (
            <form className="flex flex-col" onSubmit={handleSubmit}>
              <div className="w-1/3 pr-4">
                <Field name="email">
                  {({ input, meta }) => {
                    return (
                      <Input
                        errorMessage={
                          meta.error && meta.touched && meta.submitFailed ? meta.error : undefined
                        }
                        id={input.name}
                        label={t('customerSupportEmail')}
                        placeholder={t('email')}
                        {...input}
                      />
                    )
                  }}
                </Field>
              </div>

              <Flex className="mt-6 w-1/3 pr-4">
                <div className="w-8/12 mr-4">
                  <Field name="phoneNumber" parse={normalizePhone}>
                    {({ input, meta }) => {
                      return (
                        <Input
                          errorMessage={
                            meta.error && meta.touched && meta.submitFailed ? meta.error : undefined
                          }
                          id={input.name}
                          label={t('customerSupportPhone')}
                          placeholder={t('phone')}
                          {...input}
                        />
                      )
                    }}
                  </Field>
                </div>
                <div className="w-4/12">
                  <Field name="phoneNumberExtension">
                    {({ input, meta }) => {
                      return (
                        <Input
                          errorMessage={
                            meta.error && meta.touched && meta.submitFailed ? meta.error : undefined
                          }
                          id={input.name}
                          label={t('extension')}
                          placeholder={t('extension')}
                          {...input}
                        />
                      )
                    }}
                  </Field>
                </div>
              </Flex>

              <div className="mt-6 pr-4">
                <Field name="specialMessaging">
                  {({ input }) => {
                    return (
                      <Textarea
                        id={input.name}
                        label={t('specialMessaging')}
                        {...input}
                        maxLength={250}
                      />
                    )
                  }}
                </Field>
              </div>

              <div className="w-full mt-4 flex flex-row justify-end">
                <Button
                  className="mr-4"
                  label={t('cancel')}
                  onClick={requestClose}
                  testData="cancel-edit"
                  variant={buttonsVariants.TERTIARY}
                />
                <Button disabled={loading} label={t('save')} testData="save-edit" type="submit" />
              </div>

              <AlertModal confirmClose={closeForm} />
            </form>
          )
        }}
        validate={(values) => ({
          email: validateRequiredField(values.email) || validateEmail(values.email),
          phoneNumber:
            validateRequiredField(values.phoneNumber) || validatePhoneNumber(values.phoneNumber),
        })}
      />
    </div>
  )
}

ContactInformationEditForm.propTypes = {
  setMode: PT.func,
  vendor: PT.shape({
    billingAddress: PT.shape({
      country: PT.string,
      state: PT.string,
      street: PT.string,
      city: PT.string,
      zipCode: PT.string,
    }),
    id: PT.oneOfType([PT.string, PT.number]),
    officeAddress: PT.shape({
      country: PT.string,
      state: PT.string,
      street: PT.string,
      city: PT.string,
      zipCode: PT.string,
    }),
    name: PT.string,
    website: PT.string,
    email: PT.string,
    phoneNumber: PT.string,
    phoneNumberExtension: PT.string,
    specialMessaging: PT.string,
  }).isRequired,
}

export default ContactInformationEditForm
