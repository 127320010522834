import React from 'react'
import PropTypes from 'prop-types'
import sizes from '../../ui-kit/sizes'
import { Button, LoadingSpinner, Tabs, Text } from '../../ui-kit'
import fontWeight from '../../ui-kit/fontWeight'
import { Flex } from '../../ui-kit'
import { useTranslation } from 'react-i18next'
import { Field } from 'react-final-form'
import colors from '../../ui-kit/colors'
import NDropdown from '../../ui-kit/components/dropdown/NDropdown'
import Textarea from '../../ui-kit/components/inputs/Textarea'
import Input from '../../ui-kit/components/inputs/Input'
import CurrencyInput from '../../ui-kit/components/inputs/CurrencyInput'
import { createPaymentMethodTabs } from '../../constants/paymentResults'
import CardIntegration from '../../components/paymentType/CardIntegration'
import BankTransferForm from '../../components/paymentType/BankTransferForm'
import { useCurrentVendor } from '../../hooks/useCurrentVendor'
import FileUpload from '../../ui-kit/components/inputs/FileUpload'
import { normalizePhone } from '../../utils'
import TermsAndConditions from '../../components/termsAndConditions/TermsAndConditions'

const CustomerPaymentFormMobile = ({
  handleSubmit,
  paymentInProgress,
  isPaymentRequest,
  locationsList,
  tabsMap,
  activeTab,
  onCreatePaymentMethod,
  formData,
  remittanceMemo,
}) => {
  const { t } = useTranslation()
  const vendorData = useCurrentVendor()

  const clearentConfiguration = vendorData?.creditCardPaymentMethodConfiguration?.paymentFormData

  return (
    <form className="w-full" onSubmit={handleSubmit}>
      <Flex className="h-full" justifyContent="center">
        <Flex className="bg-white w-full h-full" column>
          {paymentInProgress && <LoadingSpinner className="w-full h-full" loading />}
          {!paymentInProgress && (
            <Flex className=" h-full w-full" column>
              <Text fontWeight={fontWeight.SEMIBOLD} size={sizes.XL}>
                {t('enterContactInfo')}
              </Text>
              <Flex className="gap-3 mt-3" column>
                <Field name="accountNumber">
                  {({ input, meta }) => {
                    return (
                      <Input
                        {...input}
                        className="w-full"
                        errorMessage={
                          meta.error && meta.touched && meta.submitFailed ? meta.error : undefined
                        }
                        id={input.name}
                        label={`${vendorData.name} ${t('accountNumber')}`}
                        placeholder={t('accountNumberPlaceholder')}
                      />
                    )
                  }}
                </Field>
                <Field name="companyName">
                  {({ input, meta }) => {
                    return (
                      <Input
                        {...input}
                        className="w-full"
                        errorMessage={
                          meta.error && meta.touched && meta.submitFailed ? meta.error : undefined
                        }
                        id={input.name}
                        label={t('accountName')}
                        placeholder={t('accountNamePlaceholder')}
                      />
                    )
                  }}
                </Field>
                <Field name="payeeName">
                  {({ input, meta }) => {
                    return (
                      <Input
                        {...input}
                        className="w-full"
                        errorMessage={
                          meta.error && meta.touched && meta.submitFailed ? meta.error : undefined
                        }
                        id={input.name}
                        label={t('yourName')}
                        placeholder={t('enterFullName')}
                      />
                    )
                  }}
                </Field>
              </Flex>

              <Text className="mt-4" fontWeight={fontWeight.SEMIBOLD} size={sizes.XL}>
                {t('enterPaymentAmount')}
              </Text>
              <Flex className="gap-3 mt-3" column>
                <Field name="amountCents">
                  {({ input, meta }) => {
                    return (
                      <div className="w-full">
                        <Text
                          color={colors.GRAY_700}
                          fontWeight={fontWeight.MEDIUM}
                          size={sizes.SM}>
                          {t('paymentAmount')}
                        </Text>
                        <CurrencyInput
                          className="w-full mt-1"
                          disabled={isPaymentRequest}
                          error={
                            meta.error && meta.touched && meta.submitFailed ? meta.error : undefined
                          }
                          id={input.name}
                          name={input.name}
                          onChange={input.onChange}
                          value={input.value}
                        />
                        {meta.error && meta.touched && meta.submitFailed ? (
                          <p className="pt-2 text-sm text-error">{meta.error}</p>
                        ) : null}
                      </div>
                    )
                  }}
                </Field>
                <Field name="locationId">
                  {({ input, ...fieldProps }) => {
                    return (
                      <div className="w-full">
                        <NDropdown
                          errorMessage={
                            fieldProps.meta.error && fieldProps.meta.submitFailed
                              ? fieldProps.meta.error
                              : undefined
                          }
                          label={t('location')}
                          listClass="max-h-40"
                          onChange={input.onChange}
                          options={locationsList || []}
                          placeholder={t('location')}
                          testData="locations"
                          value={input.value}
                          withSingleOptionAutoSelection
                        />
                      </div>
                    )
                  }}
                </Field>
              </Flex>

              <Text className="mt-4 mb-3" fontWeight={fontWeight.SEMIBOLD} size={sizes.XL}>
                {t('enterRemittanceInstructions')}
              </Text>
              <Field name="memo">
                {({ input }) => {
                  return (
                    <Textarea
                      disabled={!!remittanceMemo}
                      id={input.name}
                      inputClassName={'h-20'}
                      name={input.name}
                      onChange={input.onChange}
                      placeholder={t('enterRemittanceInstructionsPlaceholder')}
                      value={remittanceMemo || input.value}
                    />
                  )
                }}
              </Field>

              <FileUpload fieldName="payment_transaction[attachment]" formData={formData} />
              <Flex className="gap-3 my-4" column>
                <Field name="payeeEmail">
                  {({ input, meta }) => {
                    return (
                      <Input
                        className="w-full"
                        errorMessage={
                          meta.error && meta.touched && meta.submitFailed ? meta.error : undefined
                        }
                        id={input.name}
                        label={t('email')}
                        placeholder="your_email@example.com"
                        {...input}
                      />
                    )
                  }}
                </Field>
                <Field name="payeePhoneNumber" parse={normalizePhone}>
                  {({ input, meta }) => {
                    return (
                      <Input
                        errorMessage={
                          meta.error && meta.touched && meta.submitFailed ? meta.error : undefined
                        }
                        id={input.name}
                        label={t('phoneNumber')}
                        placeholder={t('phoneNumber')}
                        {...input}
                      />
                    )
                  }}
                </Field>
              </Flex>
              <Text className="mt-4" fontWeight={fontWeight.SEMIBOLD} size={sizes.XL}>
                {t('addPaymentInfo')}
              </Text>
              <Tabs tabs={tabsMap} isMobile />
              {activeTab === createPaymentMethodTabs.CARD && clearentConfiguration && (
                <CardIntegration
                  classNames="mt-4"
                  clearentConfiguration={clearentConfiguration}
                  creditCardConvenienceFeePercentage={vendorData?.creditCardFeePercentage}
                  onCreatePaymentMethod={onCreatePaymentMethod}
                />
              )}
              {activeTab === createPaymentMethodTabs.ACH && <BankTransferForm classNames="pt-6" />}
              <div className="mt-6">
                <TermsAndConditions
                  disclaimerKey="paymentDisclaimer"
                  specialMessaging={vendorData?.specialMessaging}
                />
              </div>
              <Flex className="gap-3 my-4" column>
                <Button
                  className="self-baselinex w-full"
                  disabled={paymentInProgress}
                  label={t('pay')}
                  type="submit"
                  fullWidth
                />
              </Flex>
            </Flex>
          )}
        </Flex>
      </Flex>
    </form>
  )
}

CustomerPaymentFormMobile.propTypes = {
  tabsMap: PropTypes.arrayOf(PropTypes.any),
  formData: PropTypes.object,
  activeTab: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  locationsList: PropTypes.arrayOf(PropTypes.any),
  isPaymentRequest: PropTypes.bool,
  paymentInProgress: PropTypes.bool,
  onCreatePaymentMethod: PropTypes.func.isRequired,
  remittanceMemo: PropTypes.string,
}
export default CustomerPaymentFormMobile
