import React, { useCallback, useMemo } from 'react'
import cx from 'classnames'
import { Button, Flex, FormattedDate, Icon, LoadingSpinner, Money, Text } from '../../ui-kit'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import fontWeight from '../../ui-kit/fontWeight'
import sizes from '../../ui-kit/sizes'
import buttonsVariants from '../../ui-kit/buttonsVariants'
import { operationResultMods } from '../../constants/operationResults'
import PropTypes from 'prop-types'
import getVendorSlug from '../../utils/getVendorSlug'

const OperationResult = ({
  mode,
  setMode,
  onNavigationClick,
  onActionClick,
  data,
  isAnonymous,
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const vendorSlag = getVendorSlug()

  const actionCTA = useMemo(() => {
    if (
      (mode === operationResultMods.ACH_SUCCESS ||
        mode === operationResultMods.CREDIT_CARD_SUCCESS) &&
      isAnonymous
    ) {
      return (
        <Button
          className="mb-5"
          label={t('returnToPaymentPage')}
          onClick={() => {
            setMode(null)
            onActionClick?.()
          }}
        />
      )
    }

    switch (mode) {
      case operationResultMods.SERVER_ERROR:
      case operationResultMods.GENERAL_SERVER_ERROR:
        return (
          <Button
            className="mb-5"
            label={t('tryAgain')}
            onClick={() => {
              setMode(null)
              onActionClick?.()
            }}
          />
        )
      case operationResultMods.ACH_FAILURE:
      case operationResultMods.CREDIT_CARD_FAILURE:
        return (
          <Button
            className="mb-5"
            label={t('updatePaymentInfo')}
            onClick={() => {
              setMode(null)
              onActionClick?.()
            }}
          />
        )
      default:
        return null
    }
  }, [t, setMode, mode, onActionClick, isAnonymous])
  const navigateToDashboard = useCallback(() => {
    if (onNavigationClick) {
      onNavigationClick()
    }
    navigate(`${vendorSlag}/dashboard`)
  }, [onNavigationClick, navigate, vendorSlag])
  const navigationCTA = useMemo(() => {
    if (mode === operationResultMods.LOADING || isAnonymous) {
      return null
    }

    return (
      <Button
        label={t('goToDashboard')}
        onClick={() => navigateToDashboard()}
        variant={buttonsVariants.SECONDARY}
      />
    )
  }, [t, navigate, mode, onNavigationClick, vendorSlag, isAnonymous])
  const paymentDetailsBody = useMemo(
    () =>
      !!data && (
        <>
          <Text align="center" className="py-8" fontWeight={fontWeight.SEMIBOLD} size={sizes.XL}>
            {t('paymentDetails')}
          </Text>
          <Flex className="whitespace-pre-wrap text-left" column>
            <div>
              <Text fontWeight={fontWeight.BOLD} size={sizes.LG}>
                {`${t('id')}: `}
              </Text>
              <Text size={sizes.LG}>{data.paymentId}</Text>
            </div>
            <div>
              <Text fontWeight={fontWeight.BOLD} size={sizes.LG}>
                {`${t('accountNumber')}: `}
              </Text>
              <Text size={sizes.LG}>{data.accountNumber}</Text>
            </div>
            <div>
              <Text fontWeight={fontWeight.BOLD} size={sizes.LG}>
                {`${t('accountName')}: `}
              </Text>
              <Text size={sizes.LG}>{data.accountName}</Text>
            </div>
            <div>
              <Text fontWeight={fontWeight.BOLD} size={sizes.LG}>
                {`${t('createdBy')}: `}
              </Text>
              <Text size={sizes.LG}>{data.createdBy}</Text>
            </div>
            <div>
              <Text fontWeight={fontWeight.BOLD} size={sizes.LG}>
                {`${t('paymentDate')}: `}
              </Text>
              <Text size={sizes.LG}>
                <FormattedDate date={data.paymentDate} />
              </Text>
            </div>
            <div>
              <Text fontWeight={fontWeight.BOLD} size={sizes.LG}>
                {`${t('paymentAmount')}: `}
              </Text>
              <Text size={sizes.LG}>
                <Money value={data.paymentAmount} />
              </Text>
            </div>
            <div>
              <Text fontWeight={fontWeight.BOLD} size={sizes.LG}>
                {`${t('chargedAmount')}: `}
              </Text>
              <Text size={sizes.LG}>
                <Money value={data.chargedAmountCents} />
              </Text>
            </div>
            <div>
              <Text fontWeight={fontWeight.BOLD} size={sizes.LG}>
                {`${t('paymentMethod')}: `}
              </Text>
              <Text size={sizes.LG}>{data.paymentMethodTitle}</Text>
            </div>
          </Flex>
        </>
      ),
    [t, data],
  )
  const body = useMemo(() => {
    let iconName = ''
    let titleKey = ''
    let className = ''
    let iconColor = ''
    let descriptionKey = ''
    let descriptionKey2 = ''
    let iconBackgroundColor = ''
    let additionalContent = null

    switch (mode) {
      case operationResultMods.LOADING:
        return <LoadingSpinner className="w-full h-full" loading />
      case operationResultMods.ACH_SUCCESS:
        iconName = 'fastForward'
        titleKey = 'achPaymentPending'
        className = 'border border-green-450 stroke-0.3'
        iconColor = 'text-green-450'
        descriptionKey = 'achPaymentPendingDescription'
        iconBackgroundColor = 'white'
        additionalContent = paymentDetailsBody
        break
      case operationResultMods.SERVER_ERROR:
        iconName = 'terminal'
        titleKey = 'paymentServerError'
        className = 'stroke-0.3'
        iconColor = 'text-black-700'
        descriptionKey = 'paymentServerErrorDescription'
        iconBackgroundColor = 'white'
        break
      case operationResultMods.ALREADY_PAID:
        iconName = 'exclamation'
        titleKey = 'linkAlreadyPaid'
        iconColor = 'text-black-700'
        iconBackgroundColor = 'white'
        break
      case operationResultMods.LINK_NOT_AVAILABLE:
        iconName = 'exclamation'
        titleKey = 'linkAlreadyPaid'
        iconColor = 'text-black-700'
        iconBackgroundColor = 'white'
        break
      case operationResultMods.CREDIT_CARD_SUCCESS:
        iconName = 'currencyDollar'
        titleKey = 'creditCardPaymentSuccess'
        className = 'stroke-0.3'
        iconColor = 'text-white'
        descriptionKey = 'paymentWasSuccessfulHint'
        iconBackgroundColor = 'green'
        additionalContent = paymentDetailsBody
        break
      case operationResultMods.CREDIT_CARD_FAILURE:
        iconName = 'x'
        titleKey = 'creditCardPaymentFailure'
        className = 'stroke-0.3'
        iconColor = 'text-white'
        descriptionKey = 'creditCardPaymentFailureDescription'
        iconBackgroundColor = 'red'
        break
      case operationResultMods.ACH_FAILURE:
        iconName = 'x'
        titleKey = 'achPaymentFailure'
        className = 'stroke-0.3'
        iconColor = 'text-white'
        descriptionKey = 'achPaymentFailureDescription'
        iconBackgroundColor = 'red'
        break
      case operationResultMods.GENERAL_SERVER_ERROR:
        iconName = 'terminal'
        titleKey = 'paymentServerError'
        className = 'stroke-0.3'
        iconColor = 'text-black-700'
        descriptionKey = 'serverErrorDescription'
        iconBackgroundColor = 'white'
        break
      default:
        return null
    }

    return (
      <>
        <Icon
          backgroundColor={iconBackgroundColor}
          className={cx('w-28 h-28', className)}
          color={iconColor}
          name={iconName}
          type="outline"
        />
        <Text align="center" className="mt-16" fontWeight={fontWeight.SEMIBOLD} size={sizes.XL}>
          {t(titleKey)}
        </Text>
        {descriptionKey && (
          <Text align="center" className="mt-5" size={sizes.LG}>
            {t(descriptionKey)}
          </Text>
        )}
        {descriptionKey2 && (
          <Text align="center" className="mt-5" size={sizes.LG}>
            {t(descriptionKey2)}
          </Text>
        )}
        {additionalContent}
        {mode === operationResultMods.ALREADY_PAID && (
          <>
            <Text align="center" className="mt-16 mb-4" size={sizes.LG}>
              {t('allPaylinkItems')}
            </Text>
            <Flex alignItems="center" className="w-full pl-12" justifyContent="start">
              <Icon color="text-black-700" name="dot" />
              <Text align="center" className="ml-2" size={sizes.LG}>
                {t('haveBeenPaid')}
              </Text>
            </Flex>
            <Flex alignItems="center" className="w-full pl-12" justifyContent="start">
              <Icon color="text-black-700" name="dot" />
              <Text align="center" className="ml-2" size={sizes.LG}>
                {t('arePartOfPayment')}
              </Text>
            </Flex>
            <Text
              align="center"
              className="mt-8 uppercase"
              fontWeight={fontWeight.SEMIBOLD}
              size={sizes.LG}>
              {`- ${t('or')} -`}
            </Text>
            <Text align="center" className="mt-8" size={sizes.LG}>
              {t('linkWasCancelled')}
            </Text>
          </>
        )}
      </>
    )
  }, [mode, paymentDetailsBody])

  return (
    <Flex className="w-full h-full px-4 py-8 overflow-auto bg-white" column>
      <Flex alignItems="center" className="flex-1" column>
        {body}
      </Flex>
      <Flex className="w-full pt-5" column>
        {actionCTA}
        {navigationCTA}
      </Flex>
    </Flex>
  )
}

OperationResult.propTypes = {
  isAnonymous: PropTypes.bool,
  data: PropTypes.object,
  mode: PropTypes.string.isRequired,
  setMode: PropTypes.func.isRequired,
  onActionClick: PropTypes.func,
  onNavigationClick: PropTypes.func,
}

OperationResult.defaultProps = {}

export default React.memo(OperationResult)
