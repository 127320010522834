import React from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import cx from 'classnames'
import { Flex, Icon } from '../../ui-kit'
import colors from '../../ui-kit/colors'
import getNavigationMap from '../../navigationMap'
import { useTranslation } from 'react-i18next'
import { logo, logoIcon } from '../../ui-kit/assets'
import PT from 'prop-types'
import { useCurrentUser } from '../../hooks/useCurrentUser'
import getVendorSlug from '../../utils/getVendorSlug'

const Navigation = ({ isMenuCollapsed }) => {
  const location = useLocation()
  const currentUser = useCurrentUser()
  // TODO need valid membershipRole from buyerUser/vendorUser
  currentUser.membershipRole = 'admin'

  const isCurrent = (item) =>
    item.root
      ? location.pathname.includes(item.href) || location.pathname === '/'
      : location.pathname.includes(item.href)

  const activeLinkClasses = (item, isMenuCollapsed) =>
    cx(
      isCurrent(item)
        ? 'bg-primary-100 primary hover:text-primary-700'
        : 'text-gray-600 hover:text-gray-900',
      'group hover:bg-gray-100 hover:no-underline flex items-center px-2 py-2 text-sm font-medium rounded-md',
      isMenuCollapsed ? 'flex-col' : '',
    )

  const activeIconClasses = (item) =>
    cx(
      isCurrent(item) ? 'primary' : 'text-gray-400 group-hover:text-gray-500',
      'flex-shrink-0 h-6 w-6',
      isMenuCollapsed ? '' : 'mr-3',
    )
  const { t } = useTranslation()

  return (
    <div className={`flex flex-col ${isMenuCollapsed ? 'w-20' : 'w-44'}`}>
      <div className="flex-1 flex flex-col min-h-0 border-r border-gray-200 bg-white">
        <div className="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">
          <div className="flex items-center flex-shrink-0 px-4 justify-center">
            <img alt="Suppli" className="h-8" src={isMenuCollapsed ? logoIcon : logo} />
          </div>

          <Flex
            className={`mt-5 space-y-1 ${!isMenuCollapsed && 'px-2'}`}
            flex={false}
            grow={1}
            shrink={1}
            tag="nav"
            testData="navigation-items-container">
            {getNavigationMap(currentUser?.membershipRole).reduce((acc, item) => {
              if (item.hidden) {
                return acc
              }

              acc.push(
                <NavLink
                  className={activeLinkClasses(item, isMenuCollapsed)}
                  key={item.name}
                  to={`${getVendorSlug()}${item.href}`}>
                  <Icon
                    aria-hidden="true"
                    className={activeIconClasses(item, isMenuCollapsed)}
                    color={isCurrent(item) ? colors.PRIMARY : colors.GREY}
                    name={item.icon}
                    type="outline"
                  />
                  {!isMenuCollapsed && t(item.name)}
                  {isMenuCollapsed && <span className="text-xs">{t(item.name)}</span>}
                </NavLink>,
              )
              return acc
            }, [])}
          </Flex>
        </div>
      </div>
    </div>
  )
}

Navigation.propTypes = {
  isMenuCollapsed: PT.bool,
}
Navigation.defaultProps = {
  isMenuCollapsed: false,
}

export default Navigation
