export const downloadResponse = (response, fileName = 'file') => {
  if (!response) {
    return
  }

  const downloadUrl = window.URL.createObjectURL(new Blob([response]))
  const link = document.createElement('a')
  link.href = downloadUrl
  link.setAttribute('download', fileName)
  link.style.display = 'none'
  document.body.appendChild(link)
  link.click()
  link.remove()

  return link
}
