import React, { useEffect, useState } from 'react'
import PT from 'prop-types'
import cx from 'classnames'
import InputWrapper from './InputWrapper'

import classes from './Input.module.scss'

const Textarea = ({
  id,
  value,
  name,
  label,
  helpText,
  placeholder,
  error,
  errorMessage,
  onChange,
  onBlur,
  required,
  className,
  disabled,
  inputClassName,
  ...rest
}) => {
  const inputClassnames = cx(
    classes.input,
    classes.textarea,
    {
      [classes.inputError]: !!errorMessage || error,
    },
    inputClassName,
  )
  const wrapperClassNames = cx({ [classes.inputWrapper]: !className }, className)
  const [inputValue, setInputValue] = useState(value)

  useEffect(() => {
    setInputValue(value)
  }, [value])

  const handleChange = (e) => {
    if (onChange) {
      onChange(e)
    }
    setInputValue(e.target.value)
  }

  return (
    <InputWrapper
      className={wrapperClassNames}
      errorMessage={errorMessage}
      helpText={helpText}
      id={id}
      label={label}>
      <textarea
        className={inputClassnames}
        disabled={disabled}
        id={id}
        name={name}
        onBlur={onBlur}
        onChange={handleChange}
        placeholder={placeholder}
        required={required}
        value={onChange ? value : inputValue}
        {...rest}
      />
    </InputWrapper>
  )
}

Textarea.propTypes = {
  className: PT.string,
  disabled: PT.bool,
  error: PT.bool,
  errorMessage: PT.string,
  helpText: PT.string,
  id: PT.string.isRequired,
  inputClassName: PT.string,
  label: PT.string,
  name: PT.string.isRequired,
  onBlur: PT.func,
  onChange: PT.func,
  placeholder: PT.string,
  required: PT.bool,
  type: PT.string,
  value: PT.oneOfType([PT.string, PT.number]),
}

Textarea.defaultProps = {
  className: '',
  disabled: false,
  error: false,
  errorMessage: '',
  helpText: '',
  inputClassName: '',
  label: '',
  onBlur: undefined,
  onChange: undefined,
  placeholder: '',
  required: false,
  type: 'text',
  value: '',
}

export default Textarea
