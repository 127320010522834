import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryParams } from '../../hooks/useQueryParams'
import { camelCase, toInteger } from 'lodash'
import { useBreadcrumbs } from '../../hooks/useBreadcrumbs'
import { getPaginationData, parseFilterQuery } from '../../utils/utils'
import { FormattedDate, Money, StatusBadge, Tooltip } from '../../ui-kit'
import { MONEY } from '../../utils/dataTypes'
import DataGridComponent from '../../components/dataGrid/DataGridComponent'
import Sidebar from '../../ui-kit/components/sidebar/Sidebar'
import RefundContent from './refunds/RefundContent'
import getVendorSlug from '../../utils/getVendorSlug'
import { useCustomQuery } from '../../hooks/useCustomQuery'
import axios from 'axios'
import { mapFiltersForQuery, mapSelectedFilters } from '../../components/filters/logic/logic'
import { useCurrentVendor } from '../../hooks/useCurrentVendor'
import { REFUND_TRANSACTIONS_KEY } from '../../constants/queryKeys'
import { statusRefundMapping } from './refunds/util'

const RefundsTab = () => {
  const { t } = useTranslation()
  const { queryParams } = useQueryParams()
  const sort = queryParams.sort || 'created_at.desc'
  const page = toInteger(queryParams.page) || 1
  const search = queryParams.search
  const { setBreadcrumbs } = useBreadcrumbs()

  const filtersQuery = queryParams.filters || null
  const userFilters = parseFilterQuery(filtersQuery)
  const filters = useMemo(() => mapFiltersForQuery(userFilters), [userFilters])

  const [isSidebarOpened, setIsSidebarOpened] = useState(false)
  const [selectedRefund, setSelectedRefund] = useState(null)

  const vendorData = useCurrentVendor()
  const locationsList = useMemo(
    () =>
      vendorData.locations?.map?.((item) => ({
        key: item.id,
        title: item.name,
      })) || [],
    [vendorData?.locations],
  )

  const availableFilters = [
    {
      key: 'status',
      options: [
        { title: 'pending', key: 'pending' },
        { title: 'failed', key: 'failed' },
        { title: 'refunded', key: 'refunded' },
      ],
      type: 'select',
    },
    {
      key: 'date',
      type: 'dates_range',
    },
    {
      key: 'payment_method_type',
      options: [
        { key: 'ClearentPaymentMethodConfiguration', title: 'Card' },
        { key: 'ACHQPaymentMethodConfiguration', title: 'Bank Transfer' },
      ],
      type: 'select',
    },
    {
      key: 'location',
      options: locationsList,
      type: 'select',
    },
  ]

  useEffect(() => {
    setBreadcrumbs([{ label: t('payments'), link: '/payments' }, { label: t('refunds') }])
  }, [])

  const handleRowClick = (data) => {
    setSelectedRefund(data.row)
    setIsSidebarOpened(true)
  }

  const columns = useMemo(
    () => [
      {
        field: 'createdAt',
        filterId: 'date',
        filterTitle: 'refundDate',
        headerName: t('date'),
        renderCell: (values) => (
          <FormattedDate date={values?.row?.createdAt} format={'MM/dd/yyyy hh:mm:ss a'} />
        ),
        flex: 12,
      },
      {
        field: 'id',
        headerName: t('refundId'),
        flex: 8,
      },
      {
        field: 'paymentId',
        headerName: t('paymentId'),
        renderCell: (values) => values?.row?.paymentTransaction?.id,
        flex: 10,
      },
      {
        field: 'accountNumber',
        filterId: 'accountNumber',
        headerName: t('accountNo'),
        renderCell: (values) => values?.row?.paymentTransaction?.accountNumber,
        flex: 12,
      },
      {
        field: 'companyName',
        headerName: t('accountName'),
        renderCell: (values) => (
          <Tooltip content={values?.row?.paymentTransaction?.companyName}>
            {values?.row?.paymentTransaction?.companyName || ''}
          </Tooltip>
        ),
        flex: 12,
      },
      {
        field: 'location',
        filterId: 'location',
        headerName: t('location'),
        renderCell: (values) => values?.row?.paymentTransaction?.location?.name,
        flex: 12,
      },
      {
        field: 'amountCents',
        filterId: 'amountCents',
        filterDataType: MONEY,
        headerName: t('amount'),
        flex: 8,
        renderCell: (values) => <Money value={values.row?.amountCents} />,
        align: 'right',
        headerAlign: 'right',
      },
      {
        field: 'blank',
        headerName: '',
        sortable: false,
        flex: 3,
      },
      {
        field: 'paymentMethod',
        filterId: 'paymentMethodType',
        headerName: t('paymentMethod'),
        flex: 12,
        renderCell: (values) => values?.row?.paymentTransaction?.paymentMethod?.title,
      },
      {
        field: 'status',
        filterId: 'status',
        headerName: t('status'),
        filterTitle: t('status'),
        flex: 10,
        renderCell: (values) => (
          <StatusBadge
            color={statusRefundMapping[values?.row?.clientFacingStatus]?.color}
            value={t(statusRefundMapping[values?.row?.clientFacingStatus]?.label)}
          />
        ),
      },
      {
        field: 'memo',
        headerName: t('memo'),
        flex: 10,
        sortable: false,
        renderCell: (values) => (
          <span className="text-ellipsis overflow-hidden">{values?.row?.memo}</span>
        ),
      },
    ],
    [],
  )
  const vendorSlug = getVendorSlug()
  const pageSize = useMemo(() => 100, [])
  const transactionsQueryKey = [REFUND_TRANSACTIONS_KEY, sort, search, filters, page]
  const { data: refunds, loading } = useCustomQuery({
    queryOptions: {
      queryKey: transactionsQueryKey,
      enabled: !!vendorSlug,
      queryFn: () =>
        axios.get(`${vendorSlug}/refund_transactions`, {
          params: { sort, search, filters, page },
        }),
    },
    rollbarOptions: { operationName: 'refund_transactions', target: 'RefundsTab' },
  })
  const rows = refunds?.data || []
  const paginationData = getPaginationData(refunds)

  const defaultSortModel = useMemo(() => {
    const [field, direction] = sort.split('.')

    return [{ field: camelCase(field), sort: direction }]
  }, [])

  return (
    <>
      <div className="pt-4">
        <Sidebar isSidebarOpened={isSidebarOpened} setIsSidebarOpened={setIsSidebarOpened}>
          <RefundContent data={selectedRefund || {}} />
        </Sidebar>

        <DataGridComponent
          availableFilters={availableFilters}
          columns={columns}
          loading={loading}
          page={page}
          pageSize={pageSize}
          paginationData={paginationData}
          rowClassName="cursor-pointer"
          rowClick={handleRowClick}
          rows={rows}
          searchLabel={t('refunds')}
          sortModel={defaultSortModel}
          userFilters={mapSelectedFilters(userFilters)}
        />
      </div>
    </>
  )
}

RefundsTab.propTypes = {}

RefundsTab.defaultProps = {}

export default RefundsTab
