import React, { useState, useEffect, useCallback } from 'react'
import cx from 'classnames'
import sizes from '../../ui-kit/sizes'
import { Button, Flex, Input } from '../../ui-kit'
import { useTranslation } from 'react-i18next'
import { useFilters } from '../../hooks/useFilters'
import Icon from '../../ui-kit/components/icons/Icon'
import { useQueryParams } from '../../hooks/useQueryParams'
import PropTypes from 'prop-types'

const FiltersControlButton = ({ className, hideFiltersButton }) => {
  const { t } = useTranslation()
  const { isShowFilters, showFilters, hideFilters } = useFilters()
  const [searchContent, setSearchContent] = useState('')
  const search = (value) => {
    setSearchContent(value)
  }
  const { setQueryParams, removeQueryParam, queryParams } = useQueryParams()
  const applySearch = () => {
    if (searchContent) {
      setQueryParams({ search: searchContent }, ['page'])
    } else {
      removeQueryParam('search')
    }
  }
  const handleKeyPress = useCallback(
    (event) => {
      if (event?.key === 'Enter') {
        applySearch()
      }
    },
    [applySearch],
  )

  useEffect(() => {
    if (queryParams.search === '') {
      setSearchContent('')
    } else {
      setSearchContent(queryParams.search)
    }
  }, [queryParams.search])

  return (
    <Flex alignItems="center" className={className}>
      <Input
        className={cx(!hideFiltersButton && 'mr-[-5px]', 'relative')}
        icon={<Icon name={'search'} type={'solid'} />}
        iconPosition={hideFiltersButton ? 'start' : void 0}
        id="searchInput"
        name="searchInput"
        onBlur={applySearch}
        onChange={({ target }) => search(target.value)}
        onKeyPress={handleKeyPress}
        placeholder={t('search')}
        testData="apply-search"
        value={searchContent}
        required
      />
      {!hideFiltersButton &&
        (!isShowFilters ? (
          <Button
            className="z-10"
            iconName="sortDescending"
            label={t('filters')}
            onClick={() => showFilters()}
            size={sizes.SM}
            testData="show_filters"
            variant="secondary"
          />
        ) : (
          <Button
            className="z-10"
            iconClass="text-gray-500"
            iconName="sortAscending"
            label={t('filters')}
            onClick={() => hideFilters()}
            size={sizes.SM}
            testData="hide_filters"
            variant="primary"
            addBorder
          />
        ))}
    </Flex>
  )
}

FiltersControlButton.propTypes = {
  className: PropTypes.string,
  hideFiltersButton: PropTypes.bool,
}

FiltersControlButton.defaultProps = {
  className: '',
  hideFiltersButton: false,
}

export default FiltersControlButton
