import React from 'react'
import { Box, Button, Flex, Heading, LoadingSpinner } from '../../index'
import { useTranslation } from 'react-i18next'
import PT from 'prop-types'

const PreviewBlock = ({ title, viewMoreLink, children, loading, className }) => {
  const { t } = useTranslation()
  return (
    <Flex className={className} column>
      <Flex alignItems={'center'} justifyContent={'between'}>
        <Heading className={'font-medium'} isBold={false} size={'xl'}>
          {title}
        </Heading>
        {viewMoreLink && (
          <Button
            label={t('viewMore')}
            linkTo={viewMoreLink}
            testData="view-more"
            variant={'tertiary'}
          />
        )}
      </Flex>
      <div className={'pt-9'}>
        {loading ? (
          <Box className={'w-full h-78'}>
            <Flex alignItems={'center'} justifyContent={'center'}>
              <LoadingSpinner />
            </Flex>
          </Box>
        ) : (
          children
        )}
      </div>
    </Flex>
  )
}

export default PreviewBlock

PreviewBlock.propTypes = {
  children: PT.node.isRequired,
  className: PT.string,
  loading: PT.bool,
  title: PT.string.isRequired,
  viewMoreLink: PT.string,
}

PreviewBlock.defaultProps = {
  className: '',
  loading: false,
  viewMoreLink: undefined,
}
