import { Button, NarrowList, Text } from '../../../ui-kit'
import sizes from '../../../ui-kit/sizes'
import React from 'react'
import { useTranslation } from 'react-i18next'
import cx from 'classnames'
import PT from 'prop-types'
import { get } from 'lodash'
import buttonsVariants from '../../../ui-kit/buttonsVariants'
import { companyInformationModes } from './modes'

const CompanyInformationOverview = ({ vendor, setMode }) => {
  const infoAttrsMapping = [
    {
      key: 'name',
      label: 'accountName',
    },
    {
      key: 'officeAddress.toHuman',
      label: 'officeAddress',
    },
    {
      key: 'billingAddress.toHuman',
      label: 'billingAddress',
    },
    {
      key: 'website',
      label: 'website',
    },
  ]

  const contactAttrsMapping = [
    {
      key: 'email',
      label: 'customerSupportEmail',
    },
    {
      key: 'phoneNumber',
      label: 'customerSupportPhone',
    },
    {
      key: 'phoneNumberExtension',
      label: 'extension',
    },
    {
      key: 'specialMessaging',
      label: 'specialMessaging',
    },
  ]

  const { t } = useTranslation()
  const colClasses = 'w-6/12 flex flex-col justify-center'
  const leftClasses = cx(colClasses, 'w-3/12')
  const rightClasses = cx(colClasses, 'w-9/12')

  const renderItems = (attrsMapping) =>
    attrsMapping.map(({ key, label }) => (
      <>
        <Text className={leftClasses} color="text-gray-500" size={sizes.SM}>
          {t(label)}
        </Text>
        <Text className={rightClasses} size={sizes.SM}>
          {get(vendor, key, '--') || '--'}
        </Text>
      </>
    ))

  return (
    <>
      <NarrowList
        extraTitleElement={
          <Button
            className="ml-1"
            iconName="pencil"
            label={t('edit')}
            onClick={() => setMode(companyInformationModes.EDIT_COMPANY_INFORMATION)}
            size={sizes.SM}
            testData="open-edit-company-first"
            variant={buttonsVariants.FOURTH}
          />
        }
        listItems={renderItems(infoAttrsMapping)}
        title={t('companyInformation')}
      />
      <NarrowList
        className="mt-6"
        extraTitleElement={
          <Button
            className="ml-1"
            iconName="pencil"
            label={t('edit')}
            onClick={() => setMode(companyInformationModes.EDIT_CONTACT_INFORMATION)}
            size={sizes.SM}
            testData="open-edit-company-second"
            variant={buttonsVariants.FOURTH}
          />
        }
        listItems={renderItems(contactAttrsMapping)}
        title={t('contactInformation')}
      />
      <NarrowList
        className="mt-6"
        listItems={[
          <>
            <Text className={leftClasses} color="text-gray-500" size={sizes.SM}>
              {t('cardConvenienceFee')}
            </Text>
            <Text className={rightClasses} size={sizes.SM}>
              {vendor.creditCardFeePercentage}%
            </Text>
          </>,
        ]}
        title={t('paymentSettings')}
      />
    </>
  )
}
CompanyInformationOverview.propTypes = {
  vendor: PT.shape({
    billingAddress: PT.shape({
      toHuman: PT.string,
    }),
    officeAddress: PT.shape({
      toHuman: PT.string,
    }),
    name: PT.string,
    supportEmail: PT.string,
    supportPhone: PT.string,
    website: PT.string,
    creditCardFeePercentage: PT.number,
  }).isRequired,
  setMode: PT.func.isRequired,
}

export default CompanyInformationOverview
