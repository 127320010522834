import React, { useCallback, useMemo, useState } from 'react'
import { Button, Flex, Input, Text } from '../../ui-kit'
import sizes from '../../ui-kit/sizes'
import { Field, Form } from 'react-final-form'
import { upperCase } from 'lodash'
import { validateEmail, validateRequiredField } from '../../utils/validators'
import { useTranslation } from 'react-i18next'
import BackButton from './BackButton'
import LoginWrapTemplate from './LoginWrapTemplate'
import fontWeight from '../../ui-kit/fontWeight'
import { useCustomMutation } from '../../hooks/useCustomMutation'
import axios from 'axios'
import { FORM_ERROR } from 'final-form'
import getVendorSlug from '../../utils/getVendorSlug'

const ResetPassword = () => {
  const { t } = useTranslation()
  const vendorSlug = getVendorSlug()
  const [isLinkSent, setIsLinkSent] = useState(false)

  const initialValues = useMemo(
    () => ({
      email: '',
    }),
    [],
  )

  const [resetPassword, { loading }] = useCustomMutation({
    rollbarOptions: { operationName: 'request_password_reset', target: 'ResetPassword' },
    mutationOptions: {
      mutationFn: (values) =>
        axios.post(`${vendorSlug}/auth/password`, {
          vendorUser: {
            ...values,
          },
        }),
      mutationKey: ['request_password_reset'],
    },
  })

  const handleSubmit = useCallback(async (values) => {
    try {
      await resetPassword(values)
      setIsLinkSent(true)
    } catch (error) {
      if (error?.response?.status === 422 || error?.response?.status === 401) {
        return { [FORM_ERROR]: t('invalidCreds') }
      } else {
        return { [FORM_ERROR]: error?.response?.data?.errors }
      }
    }
  }, [])

  return (
    <Form
      initialValues={initialValues}
      onSubmit={handleSubmit}
      render={({ handleSubmit, submitError }) => (
        <LoginWrapTemplate>
          <div>
            <div>
              <Text fontWeight={fontWeight.MEDIUM} size={sizes.XL2}>
                {t('forgotPassword')}?
              </Text>
            </div>
            {!isLinkSent && (
              <>
                <form autoComplete="off" className="w-full mt-9 space-y-6" onSubmit={handleSubmit}>
                  <Text className={'mt-3'} color={'text-black-500'} size={sizes.BASE}>
                    {t('enterTheEmailAddressTiedToYourAccountToReset')}
                  </Text>
                  <Field name="email">
                    {({ input, meta }) => {
                      return (
                        <Input
                          className={'mt-1'}
                          errorMessage={meta.error && meta.touched ? meta.error : undefined}
                          id={input.name}
                          label={t('emailAddress')}
                          placeholder="your_email@example.com"
                          type="email"
                          {...input}
                        />
                      )
                    }}
                  </Field>
                  <Flex alignItems="end" className="mt-8">
                    <Button
                      className="mr-2"
                      disabled={loading}
                      label={upperCase(t('resetPassword'))}
                      testData="reset-password-submit"
                      type="submit"
                      fullWidth
                    />
                    <BackButton />
                  </Flex>
                  {submitError && <span className="error leading-8">{submitError}</span>}
                </form>
              </>
            )}
            {isLinkSent && (
              <>
                <div className="mt-6">
                  <Text color="text-black-500" size={sizes.BASE}>
                    {t('checkYourEmailToResetPassword')}
                  </Text>
                </div>
                <Flex className="mt-3">
                  <Button
                    className="mt-4"
                    label={t('login')}
                    linkTo={`${vendorSlug}/auth/login`}
                    testData="reset-password-login"
                    fullWidth
                  />
                </Flex>
              </>
            )}
          </div>
        </LoginWrapTemplate>
      )}
      validate={(values) => ({
        email: validateRequiredField(values.email) || validateEmail(values.email),
      })}
      validateOnBlur
    />
  )
}

export default ResetPassword
