import React, { useCallback } from 'react'
import { Icon } from '../../../ui-kit'
import Menu from '@mui/material/Menu'
import PT from 'prop-types'
import MenuItem from '../../../ui-kit/components/menuDropdown/MenuItem'

const MenuButton = ({ values, options }) => {
  const ITEM_HEIGHT = 48

  const [anchorEl, setAnchorEl] = React.useState(null)
  const [open, setOpen] = React.useState(false)

  const handleClick = useCallback((event) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
    setTimeout(() => setOpen(true), 1)
  }, [])
  const handleClose = () => {
    setAnchorEl(null)
    setOpen(false)
  }
  const handleOptionClick = (option) => {
    handleClose()
    option.action(values)
  }
  return (
    <>
      <button
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        aria-label="more"
        className="focus:outline-none"
        id="long-button"
        onClick={handleClick}
        type={'button'}>
        <Icon className="vertical-dot-icon" name="dotsVertical" />
      </button>
      <Menu
        MenuListProps={{
          'aria-labelledby': 'long-button',
          style: {
            padding: 0,
          },
        }}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * options.length,
            width: 'fit-content',
            borderRadius: '6px',
            boxShadow:
              '0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05), ' +
              '0px 0px 0px 1px rgba(0, 0, 0, 0.05)',
          },
        }}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        id="long-menu"
        onClose={handleClose}
        open={open}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}>
        {options.map((option) => (
          <MenuItem key={option.label} onClick={() => handleOptionClick(option)} option={option} />
        ))}
      </Menu>
    </>
  )
}

MenuButton.propTypes = {
  values: PT.shape({
    api: PT.any.isRequired,
    id: PT.string.isRequired,
    field: PT.string.isRequired,
    value: PT.any,
    formattedValue: PT.any,
    row: PT.any,
    rowNode: PT.shape({
      id: PT.string.isRequired,
      children: PT.arrayOf(PT.oneOf([PT.string, PT.number])),
      parent: PT.oneOf([PT.string, PT.number, null]).isRequired,
      expanded: PT.bool,
      depth: PT.number.isRequired,
      groupingValue: PT.string.isRequired,
      isAutoGenerated: PT.bool,
    }),

    colDef: PT.any,
    isEditable: PT.bool,
    cellMode: PT.oneOf(['edit', 'view']).isRequired,
    hasFocus: PT.bool.isRequired,
    tabIndex: PT.oneOf([0, -1]).isRequired,
    getValue: PT.func.isRequired,
  }),
  options: PT.arrayOf(
    PT.shape({
      label: PT.string.isRequired,
      action: PT.func.isRequired,
    }),
  ).isRequired,
}

export default MenuButton
