import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useQueryParams } from '../../hooks/useQueryParams'
import { Button, Heading, Input, Text } from '../../ui-kit'
import sizes from '../../ui-kit/sizes'
import { useTranslation } from 'react-i18next'
import { useNotifications } from '../../hooks/useNotifications'
import { useDispatch, useSelector } from 'react-redux'
import { Field, Form } from 'react-final-form'
import LoginWrapTemplate from './LoginWrapTemplate'
import colors from '../../ui-kit/colors'
import { PRIVACY_POLICY, TERMS_OF_SERVICE } from '../../constants/contacts'
import { validateRequiredField } from '../../utils/validators'
import { useRollbar } from '@rollbar/react'
import { useCustomMutation } from '../../hooks/useCustomMutation'
import { applyUserSignIn } from '../../store/actions/auth'
import axios from 'axios'
import getVendorSlug from '../../utils/getVendorSlug'
import { INVALID_TOKEN_ROUTE } from '../../constants/routes'
import { logoutUser } from '../../utils/utils'
import { selectAuthToken } from '../../store/selectors/auth'

const Invitation = () => {
  const { t } = useTranslation()
  const rollbar = useRollbar()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const authToken = useSelector(selectAuthToken)
  const vendorSlug = getVendorSlug()
  const { invitationToken } = useParams()
  const { queryParams } = useQueryParams()
  const { newNotification } = useNotifications()
  const [passwordConfirmationError, setPasswordConfirmationError] = useState('')

  useEffect(() => {
    logoutUser({
      dispatch,
      navigate,
      authToken,
      vendorSlug,
    })
  }, [])

  const handleConfirmationCheck = useCallback(
    (values) => {
      if (!values.password || values.password === values.passwordConfirmation) {
        setPasswordConfirmationError('')
      } else {
        setPasswordConfirmationError(t('passwordMatchErrorMessage'))
      }
    },
    [t],
  )

  const [acceptInvitation, { loading }] = useCustomMutation({
    onCompleted: (user) => {
      newNotification({ success: t('userUpdatedSuccessfully') })
      dispatch(applyUserSignIn(null, user))
      navigate(`${vendorSlug}/payments`)
    },
    rollbarOptions: { operationName: 'accept_invitation', target: 'Invitation' },
    mutationOptions: {
      enabled: !!queryParams.invitation_token,
      mutationFn: (values) =>
        axios.patch(`${vendorSlug}/auth/invitation`, {
          vendorUser: {
            ...values,
            invitationToken,
          },
        }),
      mutationKey: ['accept_invitation'],
    },
    onFailed: () => {
      navigate(`${vendorSlug}${INVALID_TOKEN_ROUTE}`)
    },
  })

  const handleSubmit = useCallback(
    async (values) => await acceptInvitation(values),
    [queryParams, t, newNotification, dispatch, navigate, rollbar],
  )

  return (
    <Form
      onSubmit={handleSubmit}
      render={({ handleSubmit, submitError, values }) => {
        return (
          <form onSubmit={handleSubmit}>
            <LoginWrapTemplate>
              <Heading>{t('welcomeToSuppli')}</Heading>
              <Heading
                className="font-medium"
                color="text-black-500"
                isBold={false}
                size={sizes.BASE}>
                {t('createMerchantPasswordHint')}
              </Heading>
              <Field name="password">
                {({ input, meta }) => {
                  return (
                    <Input
                      errorMessage={
                        meta.error && meta.touched && meta.submitFailed ? meta.error : undefined
                      }
                      id={input.name}
                      label={t(input.name)}
                      placeholder={t('yourPassword')}
                      type="password"
                      {...input}
                    />
                  )
                }}
              </Field>
              <Field name="passwordConfirmation">
                {({ input, meta }) => {
                  return (
                    <Input
                      {...input}
                      errorMessage={
                        passwordConfirmationError ||
                        (meta.error && meta.touched && meta.submitFailed ? meta.error : undefined)
                      }
                      id={input.name}
                      label={t(input.name)}
                      onBlur={() => handleConfirmationCheck(values)}
                      placeholder={t('confirmYourPassword')}
                      type="password"
                    />
                  )
                }}
              </Field>
              {submitError && <span className="error leading-8">{submitError}</span>}
              <Button
                color={colors.PRIMARY}
                disabled={!!passwordConfirmationError || loading}
                label={t('submit')}
                testData="submit-account-details"
                type="submit"
                fullWidth
              />
              <div>
                <Text color="text-warmBlack-400 mt-2" size={sizes.XS}>
                  {t('loginWarning')}
                </Text>
                <Text className={'ml-1'} color="blue mt-2" size={sizes.XS}>
                  <a href={TERMS_OF_SERVICE}>{t('termsOfService')}</a>
                </Text>
                <Text className={'ml-1'} color="text-warmBlack-400 mt-2" size={sizes.XS}>
                  {t('and')}
                </Text>
                <Text className={'ml-1'} color="blue mt-2" size={sizes.XS}>
                  <a href={PRIVACY_POLICY}>{t('privacyPolicy')}</a>.
                </Text>
              </div>
            </LoginWrapTemplate>
          </form>
        )
      }}
      validate={(values) => ({
        password: validateRequiredField(values.password),
        passwordConfirmation: validateRequiredField(values.passwordConfirmation),
      })}
    />
  )
}

export default Invitation
