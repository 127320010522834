import React, { useCallback, useMemo, useState } from 'react'
import PT from 'prop-types'
import { useTranslation } from 'react-i18next'
import cx from 'classnames'
import Accordion from '../../../ui-kit/components/accordion/Accordion'
import { paymentsReportsListMap, paymentsReports } from './paymentsReportsList'
import CustomerPayments from './CustomerPayments'
import { useQueryParams } from '../../../hooks/useQueryParams'
import { parseFilterQuery } from '../../../utils/utils'
import { useCustomQuery } from '../../../hooks/useCustomQuery'
import axios from 'axios'
import getVendorSlug from '../../../utils/getVendorSlug'
import { toInteger } from 'lodash'
import { mapFiltersForQuery } from '../../../components/filters/logic/logic'
import Refunds from './Refunds'

const PaymentsReports = ({ createDetails, createSummary }) => {
  const { t } = useTranslation()
  const vendorSlug = getVendorSlug()
  const { queryParams, removeQueryParam } = useQueryParams()
  const page = toInteger(queryParams.page) || 1
  const sort = useMemo(() => queryParams.sort || '', [queryParams])
  const filtersQuery = useMemo(() => queryParams.filters || null, [queryParams])
  const userFilters = useMemo(() => parseFilterQuery(filtersQuery), [filtersQuery])
  const filters = useMemo(() => mapFiltersForQuery(userFilters), [userFilters])

  const [isExpanded, setIsExpanded] = useState(false)
  const [openedReport, setOpenedReport] = useState('')
  const summaryProps = useMemo(
    () => ({
      label: t('paymentsReports'),
      iconName: 'currencyDollar',
      isExpanded: isExpanded,
    }),
    [isExpanded, t],
  )
  const {
    data: reportData,
    remove,
    loading,
  } = useCustomQuery({
    queryOptions: {
      enabled: !!openedReport,
      queryKey: ['report', sort, filters, page],
      queryFn: () =>
        axios.get(`${vendorSlug}${paymentsReports[openedReport]?.jsonUrl}`, {
          params: { sort, filters, page },
        }),
    },
    rollbarOptions: { operationName: 'report', target: 'PaymentsReports' },
  })
  const detailsProps = useMemo(
    () => ({
      reportsMetadata: paymentsReports,
      setOpenedReport,
    }),
    [setOpenedReport],
  )
  const closeReport = useCallback(() => {
    setOpenedReport('')
    remove()
    removeQueryParam(['filters', 'sort', 'page'])
  }, [remove])

  return (
    <>
      <Accordion
        createDetails={createDetails}
        createSummary={createSummary}
        detailsClassName="divide-y ml-24 mr-8"
        detailsProps={detailsProps}
        id="payments-reports"
        isExpanded={isExpanded}
        setIsExpanded={setIsExpanded}
        summaryProps={summaryProps}
        wrapperClassName={cx('mt-8', {
          'border border-orange-700': isExpanded,
        })}
      />
      <CustomerPayments
        closeReport={closeReport}
        data={reportData}
        isOpened={openedReport === paymentsReportsListMap.CUSTOMER_PAYMENTS}
        loading={loading}
        page={page}
        reportMetadata={paymentsReports[paymentsReportsListMap.CUSTOMER_PAYMENTS]}
        sort={sort}
        userFilters={userFilters}
      />
      <Refunds
        closeReport={closeReport}
        data={reportData}
        isOpened={openedReport === paymentsReportsListMap.REFUNDS}
        loading={loading}
        page={page}
        reportMetadata={paymentsReports[paymentsReportsListMap.REFUNDS]}
        sort={sort}
        userFilters={userFilters}
      />
    </>
  )
}

PaymentsReports.propTypes = {
  createDetails: PT.func.isRequired,
  createSummary: PT.func.isRequired,
}

export default PaymentsReports
