import React, { useState } from 'react'
import { Button, Flex, Dialog, Text } from '../../ui-kit'
import buttonsVariants from '../../ui-kit/buttonsVariants'
import { useTranslation } from 'react-i18next'
import PT from 'prop-types'
import fontWeight from '../../ui-kit/fontWeight'
import sizes from '../../ui-kit/sizes'
import { idType } from '../../types/defaultTypes'
import FileUpload from '../../ui-kit/components/inputs/FileUpload'
import { useCustomMutation } from '../../hooks/useCustomMutation'
import { PUBLIC_VENDOR_INFO_KEY } from '../../constants/queryKeys'
import axios from 'axios'
import getVendorSlug from '../../utils/getVendorSlug'
import { useNotifications } from '../../hooks/useNotifications'
import { setVendorData } from '../../store/actions/auth'
import { useDispatch } from 'react-redux'

const LogoEditSection = ({ logo }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const vendorSlug = getVendorSlug()
  const { newNotification } = useNotifications()
  const [modalIsOpened, setModalIsOpened] = useState(false)
  const [formData, setFormData] = useState(new FormData())

  const [updateVendor, { loading }, updateQuery] = useCustomMutation({
    onCompleted: (vendorData) => {
      updateQuery({ queryKey: [PUBLIC_VENDOR_INFO_KEY], record: vendorData })
      dispatch(setVendorData(vendorData))
      newNotification({ success: t('informationWasUpdated') })
      setFormData(new FormData())
      setModalIsOpened(false)
    },
    rollbarOptions: { operationName: 'update_vendor', target: 'CompanyInformationEditForm' },
    mutationOptions: {
      mutationFn: () =>
        axios.put(`${vendorSlug}/vendor`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          isFromData: true,
        }),
      mutationKey: ['update_vendor'],
    },
  })

  const handleUploadLogo = async () => {
    await updateVendor()
  }

  return (
    <>
      <Flex column>
        <Text fontWeight={fontWeight.MEDIUM} size={sizes.BASE}>
          {t('logo')}
        </Text>
        <Flex className={'p-4'} column>
          {logo && <img alt={'logo'} className={'mb-4 w-12'} src={logo} />}
          <Button
            disabled={loading}
            label={logo ? t('change') : t('upload')}
            onClick={() => setModalIsOpened(true)}
            testData="change-logo"
            variant={buttonsVariants.LINK}
          />
        </Flex>
      </Flex>
      <Dialog
        isOpened={modalIsOpened}
        setIsOpened={() => {
          setModalIsOpened(false)
          setFormData(new FormData())
        }}
        title={t('uploadLogo')}
        shouldCloseOnBackdropClick>
        <Flex column>
          <FileUpload fieldName="vendor[logo]" formData={formData} />
          <Button
            className="mt-8"
            disabled={loading}
            label={t('upload')}
            onClick={handleUploadLogo}
            testData="upload-logo"
          />
        </Flex>
      </Dialog>
    </>
  )
}

export default LogoEditSection

LogoEditSection.propTypes = {
  logo: PT.string,
  vendorId: idType,
}
