import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import fontWeight from '../../ui-kit/fontWeight'
import sizes from '../../ui-kit/sizes'
import PT from 'prop-types'
import NDropdown from '../../ui-kit/components/dropdown/NDropdown'
import FilterDatePicker from '../../ui-kit/components/datePicker/FilterDatePicker'
import { getDateToDatePicker } from '../../utils/utils'
import {
  autocompleteValuesChange,
  convertToCents,
  convertToDollar,
  datesFilterChange,
  filterValueChange,
  getAvailableFilterOptions,
  parseCurrentUserFilters,
} from './logic/logic'
import { filterChange } from './logic/filterChange'
import { applyFilters } from './logic/applyFilters'
import { useQueryParams } from '../../hooks/useQueryParams'
import { Flex, Input, Text } from '../../ui-kit'
import FormSelect from '../../ui-kit/components/Select/FormSelect'
import Toggle from '../../ui-kit/components/inputs/Toggle'
import { useFilters } from '../../hooks/useFilters'

const ReportsFilters = ({ availableFilters, columns, refs, isGridFilter, userFilters }) => {
  const { t } = useTranslation()
  const { setQueryParams, removeQueryParam } = useQueryParams()
  const [filtersList, updateFilterList] = useState([])
  const [selectedFilter, setSelectedFilters] = useState(userFilters || {})
  const [selectedOptions, setSelectedOptions] = useState({})

  useEffect(() => {
    !isGridFilter && removeQueryParam('filters')
  }, [])

  useEffect(() => {
    const availableFilterOptions = getAvailableFilterOptions(availableFilters, columns)
    parseCurrentUserFilters(availableFilterOptions, t, updateFilterList)
  }, [columns, availableFilters, t])

  const getFilterInput = useCallback(
    (filter) => {
      switch (filter.type) {
        case 'autocomplete_select':
          return (
            <div className="w-1/5 pb-4 pr-4">
              <FormSelect
                label={filter.label}
                loadOptions={filter.loadOptions}
                onChange={(values) =>
                  autocompleteValuesChange(
                    values,
                    filter.key,
                    selectedFilter,
                    setSelectedFilters,
                    setQueryParams,
                    refs,
                    selectedOptions,
                    setSelectedOptions,
                  )
                }
                options={selectedOptions[filter.key] || []}
                placeholder={t('choose') + ' ' + filter.label}
                testData={filter.key}
                value={selectedFilter[filter.key]}
                isMulti
                withCheckmarks
              />
            </div>
          )
        case 'select':
          return (
            <div className="w-1/5 pb-4">
              <NDropdown
                className="pr-4"
                inputClassName={'w-full'}
                key={filter.key}
                label={filter.label}
                listClass={'max-h-40'}
                name={filter.key}
                onChange={(e) => {
                  filterChange(
                    e.target.value,
                    filter.key,
                    false,
                    selectedFilter,
                    setSelectedFilters,
                    setQueryParams,
                    refs,
                  )
                }}
                options={filter.options}
                placeholder={t('choose') + ' ' + filter.label}
                testData={filter.key}
                value={selectedFilter[filter.key] || []}
                isMultipleSelect
              />
            </div>
          )
        case 'value':
          return (
            <div className="w-1/5 pb-4">
              <Input
                className="pr-4"
                id={filter.key}
                label={filter.label}
                onBlur={() => applyFilters(selectedFilter, setQueryParams, refs)}
                onChange={({ target }) =>
                  filterValueChange(
                    target.value,
                    filter.key,
                    true,
                    selectedFilter,
                    setSelectedFilters,
                    setQueryParams,
                    refs,
                  )
                }
                placeholder={t('choose') + ' ' + filter.label}
                testData={`filter-value-${filter.key}`}
                value={selectedFilter[filter.key]}
              />
            </div>
          )
        case 'values_range': {
          return (
            <Flex alignItems="end" className="flex w-1/5 pr-4 pb-4">
              <Input
                id={filter.key + '/0'}
                label={filter.label}
                onBlur={() => applyFilters(selectedFilter, setQueryParams, refs)}
                onChange={({ target }) =>
                  filterChange(
                    convertToCents(target.value, filter),
                    filter.key + '/0',
                    true,
                    selectedFilter,
                    setSelectedFilters,
                    setQueryParams,
                    refs,
                  )
                }
                placeholder={t('min')}
                testData={`filter-values-range-${filter.key}-min`}
                value={convertToDollar(selectedFilter[filter.key]?.[0], filter)}
              />
              <Input
                id={filter.key + '/1'}
                onBlur={() => applyFilters(selectedFilter, setQueryParams, refs)}
                onChange={({ target }) =>
                  filterChange(
                    convertToCents(target.value, filter),
                    filter.key + '/1',
                    true,
                    selectedFilter,
                    setSelectedFilters,
                    setQueryParams,
                    refs,
                  )
                }
                placeholder={t('max')}
                testData={`filter-values-range-${filter.key}-max`}
                value={convertToDollar(selectedFilter[filter.key]?.[1], filter)}
              />
            </Flex>
          )
        }
        case 'dates_range':
          return (
            <div className="w-1/5 pr-4 pb-4">
              <label className="mb-[0.3rem] block text-sm font-medium text-gray-700">
                {filter.label}
              </label>
              <FilterDatePicker
                endDate={getDateToDatePicker(selectedFilter[filter.key]?.[1])}
                onChange={(dateValue) =>
                  datesFilterChange(
                    dateValue,
                    filter.key,
                    selectedFilter,
                    setSelectedFilters,
                    setQueryParams,
                    refs,
                  )
                }
                placeholder={t('chooseDates')}
                startDate={getDateToDatePicker(selectedFilter[filter.key]?.[0])}
                testData={`filter-date-picker-${filter.key}`}
              />
            </div>
          )
        case 'toggle':
          return (
            <div className="w-1/5 pb-4">
              <label className="mb-[0.3rem] block text-sm font-medium text-gray-700">
                {filter.label}
              </label>
              <Toggle
                className="p-4 align-bottom h-8"
                handleChange={(val) => {
                  filterChange(
                    String(val),
                    filter.key,
                    false,
                    selectedFilter,
                    setSelectedFilters,
                    setQueryParams,
                    refs,
                  )
                }}
                id={filter.key}
                key={filter.key}
                name={filter.key}
                testData={`filter-toggle-${filter.key}`}
                value={selectedFilter[filter.key] === 'true'}
              />
            </div>
          )
        default:
          return null
      }
    },
    [t, selectedFilter, setQueryParams, refs],
  )

  const removeAllFilters = useCallback(() => {
    setSelectedFilters({})
    removeQueryParam('filters')
  }, [removeQueryParam])

  const { isShowFilters } = useFilters()

  return (
    isShowFilters && (
      <div className={isGridFilter ? '' : 'mt-8'}>
        <Flex alignItems="center">
          {!isGridFilter && (
            <Text fontWeight={fontWeight.MEDIUM} size={sizes.XL}>
              {t('filters')}
            </Text>
          )}
          {!!Object.keys(selectedFilter).length && (
            <Text className="cursor-pointer" color="text-black-500" onClick={removeAllFilters}>
              {t('clearAll')}
            </Text>
          )}
        </Flex>
        <Flex>
          <Flex className="w-full mt-4" wrap>
            {filtersList.map((filter) => getFilterInput(filter))}
          </Flex>
        </Flex>
      </div>
    )
  )
}

ReportsFilters.propTypes = {
  refs: PT.object,
  columns: PT.arrayOf(
    PT.shape({
      field: PT.string,
    }),
  ),
  availableFilters: PT.arrayOf(
    PT.shape({
      key: PT.string,
      type: PT.string,
    }),
  ),
  isGridFilter: PT.bool,
  userFilters: PT.shape({}),
}

ReportsFilters.defaultProps = {
  refs: [],
  columns: [],
  availableFilters: null,
  userFilters: {},
}

export default ReportsFilters
