import React from 'react'
import { createRoot } from 'react-dom/client'
import App from './App'
import { Provider } from 'react-redux'
import store from './store'
import enableAxiosInterceptors from './configs/axiosInterceptors'
import initLocales from './i18n-instance'
import '../src/ui-kit/styles/core.scss'
import { Provider as RollbarProvider, ErrorBoundary } from '@rollbar/react'
import { getRollbarConfig } from './utils/rollbar'
import { LicenseInfo } from '@mui/x-license-pro'
import appConfig from './configs/appConfig'

enableAxiosInterceptors()
initLocales()
LicenseInfo.setLicenseKey(appConfig.MUI_LICENSE_KEY)

const container = document.getElementById('root')
const root = createRoot(container)
root.render(
  <React.StrictMode>
    <RollbarProvider config={getRollbarConfig(store.getState().auth.currentUser)}>
      <ErrorBoundary>
        <Provider store={store}>
          <App />
        </Provider>
      </ErrorBoundary>
    </RollbarProvider>
  </React.StrictMode>,
)
