import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Flex, Icon, Text, Dialog } from '../../../ui-kit'
import { Field, Form } from 'react-final-form'
import buttonsVariants from '../../../ui-kit/buttonsVariants'
import { validateRequiredField } from '../../../utils/validators'
import colors from '../../../ui-kit/colors'
import fontWeight from '../../../ui-kit/fontWeight'
import sizes from '../../../ui-kit/sizes'
import CurrencyInput from '../../../ui-kit/components/inputs/CurrencyInput'
import AlertModal from '../../../ui-kit/components/alertModal/AlertModal'
import { useBeforeUnload } from '../../../hooks/useBeforeUnload'
import EmailForm from './EmailForm'
import TextForm from './TextForm'
import VALIDATION_ERRORS from '../../../constants/validationErrors'
import { useCustomMutation } from '../../../hooks/useCustomMutation'
import axios from 'axios'
import { useCurrentVendor } from '../../../hooks/useCurrentVendor'
import { useNotifications } from '../../../hooks/useNotifications'
import { denormalizePhoneNumber } from '../../../utils'
import Textarea from '../../../ui-kit/components/inputs/Textarea'
import Toggle from '../../../ui-kit/components/inputs/Toggle'

const RequestPaymentForm = ({ isFormDirty, setDirtyFormState, closeForm, requestClose }) => {
  const { t } = useTranslation()

  const initialValues = useMemo(
    () => ({
      amountCents: '',
      noticeSuccessPayment: false,
    }),
    [],
  )

  const [listOfRecipients, setListOfRecipients] = useState([])
  const [guestEmails, setGuestEmails] = useState([])
  const [guestPhones, setGuestPhones] = useState([])
  const [recipientsFullListValues, setRecipientsFullListValues] = useState([])
  const [guestEmailModalIsOpened, setGuestEmailModalIsOpened] = useState(false)
  const [guestPhoneModalIsOpened, setGuestPhoneModalIsOpened] = useState(false)

  const onEmailClick = useCallback(() => {
    setGuestEmailModalIsOpened(true)
  }, [])

  const onPhoneClick = useCallback(() => {
    setGuestPhoneModalIsOpened(true)
  }, [])

  const handleAddNewEmail = useCallback(
    (values) => {
      const copyGuestEmails = [...guestEmails]
      copyGuestEmails.push(values)
      setGuestEmails(copyGuestEmails)
      setGuestEmailModalIsOpened(false)
    },
    [guestEmails],
  )

  const handleAddNewPhone = useCallback(
    (values) => {
      const copyGuestPhones = [...guestPhones]
      copyGuestPhones.push(values)
      setGuestPhones(copyGuestPhones)
      setGuestPhoneModalIsOpened(false)
    },
    [guestPhones],
  )

  const handleDelete = useCallback(
    (item) => {
      const copyRecipients = listOfRecipients.filter((recipient) => recipient !== item.id)
      setListOfRecipients(copyRecipients)
      const copyGuestEmails = guestEmails.filter((recipient) => recipient.email !== item.id)
      setGuestEmails(copyGuestEmails)
      const copyGuestPhones = guestPhones.filter((recipient) => recipient.phone !== item.id)
      setGuestPhones(copyGuestPhones)
    },
    [listOfRecipients, guestEmails, guestPhones],
  )

  useEffect(() => {
    const presentationValuesEmails = guestEmails.map((user) => ({
      presentedName: t('guestUser'),
      sourceEmail: user.email,
      id: user.email,
    }))

    const presentationValuesPhones = guestPhones.map((user) => ({
      presentedName: t('guestUser'),
      sourcePhone: user.phone,
      id: user.phone,
    }))
    setRecipientsFullListValues([...presentationValuesEmails, ...presentationValuesPhones])
  }, [listOfRecipients, guestEmails, guestPhones])

  const { newNotification } = useNotifications()
  const vendorData = useCurrentVendor()

  const [submit, { loading }] = useCustomMutation({
    onCompleted: () => {
      newNotification({ success: t('requestSuccessfullySent') })
      closeForm()
    },
    rollbarOptions: { operationName: 'create_payment_requests', target: 'PaymentsTab' },
    mutationOptions: {
      mutationFn: (values) => {
        return axios.post(`${vendorData?.slug}/payment_requests`, values)
      },
      mutationKey: ['create_payment_request'],
    },
  })

  const onSubmit = useCallback(
    (values) => {
      if (!recipientsFullListValues.length) {
        return
      }

      const recipientsViaEmail = recipientsFullListValues
        .filter((item) => !!item.sourceEmail)
        .map((item) => ({
          ...(item.id && item.id !== item.sourceEmail
            ? { buyerUserId: item.id.split('/')[0] }
            : {}),
          email: item.sourceEmail,
        }))
      const recipientsViaText = recipientsFullListValues
        .filter((item) => !!item.sourcePhone)
        .map((item) => ({
          ...(item.id && item.id !== item.sourcePhone
            ? { buyerUserId: item.id.split('/')[0] }
            : {}),
          phone_number: denormalizePhoneNumber(item.sourcePhone),
        }))
      const amountCentsValue = Number(values.amountCents.replaceAll(',', ''))

      const variables = {
        paymentRequest: {
          ...values,
          amountCents: Math.round(amountCentsValue * 100),
          recipients: recipientsViaEmail.concat(recipientsViaText),
        },
      }

      submit(variables)
    },
    [recipientsFullListValues],
  )

  return (
    <Form
      initialValues={initialValues}
      onSubmit={onSubmit}
      render={({ handleSubmit, dirty, submitSucceeded, submitFailed }) => {
        useBeforeUnload({ when: dirty })
        dirty !== isFormDirty && setDirtyFormState(dirty)

        return (
          <form className="overflow-hidden" onSubmit={handleSubmit}>
            <div className="w-full mt-6">
              <Field name="amountCents">
                {({ input, meta }) => {
                  return (
                    <Flex column>
                      <Text
                        className="pb-1"
                        color={colors.GRAY_700}
                        fontWeight={fontWeight.MEDIUM}
                        size={sizes.SM}>
                        {t('amount')}
                      </Text>
                      <CurrencyInput
                        error={
                          meta.error && meta.touched && meta.submitFailed ? meta.error : undefined
                        }
                        id={input.name}
                        name={input.name}
                        onChange={input.onChange}
                      />
                      {meta.error && meta.touched && meta.submitFailed ? (
                        <p className="pt-2 text-sm text-error">{meta.error}</p>
                      ) : null}
                    </Flex>
                  )
                }}
              </Field>
            </div>
            <div className="w-full mt-6">
              <Field name="remittanceMemo">
                {({ input }) => {
                  return (
                    <Textarea
                      id={input.name}
                      inputClassName={'h-20'}
                      label={t('memo')}
                      name={input.name}
                      onChange={input.onChange}
                      placeholder={t('useThisFieldFillRemittanceInformation')}
                      value={input.value}
                    />
                  )
                }}
              </Field>
            </div>

            <div className="w-full mt-6">
              <Text>{t('addRecipients')}</Text>
              <Flex className="mt-2">
                <Button
                  className="mr-2 w-6/12"
                  label={t('addEmail')}
                  onClick={onEmailClick}
                  variant={buttonsVariants.SECONDARY}
                />
                <Button
                  className="ml-2 w-6/12"
                  label={t('addMobilePhone')}
                  onClick={onPhoneClick}
                  variant={buttonsVariants.SECONDARY}
                />
              </Flex>
              {(submitSucceeded || submitFailed) && !recipientsFullListValues.length && (
                <p className="mt-2 text-sm text-error">{VALIDATION_ERRORS.RECIPIENT_REQUIRED}</p>
              )}
            </div>

            <div className="w-full mt-6">
              <Text>{t('sendTo')}</Text>
              <div className="w-full border mt-1 rounded-md min-h-[8rem] py-2 px-2">
                {!recipientsFullListValues.length && (
                  <Text color="text-black-500">{t('addRecipients')}</Text>
                )}
                {!!recipientsFullListValues.length &&
                  recipientsFullListValues.map((item) => (
                    <Flex justifyContent="between mb-1" key={item.email}>
                      <Text className="pr-1">{item.presentedName}</Text>
                      <span>
                        {!!item.sourceEmail && (
                          <a
                            className={'text-blue-500 hover:text-blue-500'}
                            href={`mailto:${item.sourceEmail}`}>
                            {item.sourceEmail}
                          </a>
                        )}
                        {!!item.sourcePhone && (
                          <a
                            className={'text-blue-500 hover:text-blue-500'}
                            href={`tel:${item.sourcePhone}`}>
                            {item.sourcePhone}
                          </a>
                        )}
                        <Icon
                          className="w-8 inline"
                          color="text-warmBlack-400"
                          name="trash"
                          onClick={() => handleDelete(item)}
                          type="outline"
                        />
                      </span>
                    </Flex>
                  ))}
              </div>
            </div>

            <div className="w-full mt-6">
              <Field name="noticeSuccessPayment">
                {({ input }) => (
                  <Flex row>
                    <div>
                      <Toggle handleChange={input.onChange} value={input.value} />
                    </div>
                    <Flex className="pl-2" column>
                      <Text className="pb-1" fontWeight={fontWeight.MEDIUM}>
                        {t('paymentConfirmation')}
                      </Text>
                      <Text className="pb-1">{t('paymentConfirmationWarn')}</Text>
                    </Flex>
                  </Flex>
                )}
              </Field>
            </div>

            <div className="w-full mt-6 flex flex-row justify-end">
              <Button
                className="mr-2"
                label={t('cancel')}
                onClick={requestClose}
                testData="cancel-request-payment"
                variant={buttonsVariants.TERTIARY}
              />
              <Button
                disabled={loading}
                label={t('requestPayment')}
                testData="submit-request-payment"
                type="submit"
              />
            </div>

            <AlertModal confirmClose={closeForm} />

            <Dialog
              isOpened={guestEmailModalIsOpened}
              setIsOpened={setGuestEmailModalIsOpened}
              shouldCloseOnBackdropClick>
              <EmailForm handleSubmitForm={handleAddNewEmail} />
            </Dialog>

            <Dialog
              isOpened={guestPhoneModalIsOpened}
              setIsOpened={setGuestPhoneModalIsOpened}
              shouldCloseOnBackdropClick>
              <TextForm handleSubmitForm={handleAddNewPhone} />
            </Dialog>
          </form>
        )
      }}
      validate={(values) => ({
        amountCents: validateRequiredField(values.amountCents),
      })}
    />
  )
}

RequestPaymentForm.propTypes = {
  closeForm: PropTypes.func.isRequired,
  isFormDirty: PropTypes.bool.isRequired,
  requestClose: PropTypes.func.isRequired,
  setDirtyFormState: PropTypes.func.isRequired,
}

export default RequestPaymentForm
