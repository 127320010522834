import React, { useState } from 'react'
import PT from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Form } from 'react-final-form'
import { Button, Dialog, Flex } from '../../ui-kit'
import { validateRequiredField } from '../../utils/validators'
import { validateEqualFields } from '../../utils/validators'
import PasswordFields from '../../components/security/PasswordFields'
import { useNotifications } from '../../hooks/useNotifications'
import { useCurrentUser } from '../../hooks/useCurrentUser'
import { useCustomMutation } from '../../hooks/useCustomMutation'
import axios from 'axios'
import getVendorSlug from '../../utils/getVendorSlug'

const ChangePasswordModal = ({ isOpened, setIsOpened }) => {
  const { t } = useTranslation()
  const vendorSlug = getVendorSlug()
  const currentUser = useCurrentUser()
  const { newNotification } = useNotifications()

  const [updateVendorPassword, { loading }] = useCustomMutation({
    onCompleted: () => {
      newNotification({ success: t('passwordSuccessfullyUpdated') })
      setIsOpened(false)
    },
    rollbarOptions: { operationName: 'update_vendor_user_password', target: 'ChangePasswordModal' },
    mutationOptions: {
      enabled: !!currentUser?.id,
      mutationFn: (data) =>
        axios.patch(`${vendorSlug}/vendor_users/update_password`, {
          vendorUser: { ...data },
        }),
      mutationKey: ['update_vendor_user_password'],
    },
  })

  const onSubmit = async (values) => {
    await updateVendorPassword(values)
  }

  const initialValues = {
    password: '',
    currentPassword: '',
    passwordConfirmation: '',
  }
  const [isPasswordVisible, setIsPasswordVisible] = useState(false)
  const [isPasswordConfirmationVisible, setIsPasswordConfirmationVisible] = useState(false)
  const [isCurrentPasswordVisible, setIsCurrentPasswordVisible] = useState(false)

  const renderForm = () => (
    <Form
      initialValues={initialValues}
      onSubmit={onSubmit}
      render={({ handleSubmit }) => {
        return (
          <form className={'flex flex-col flex-1 px-4 text-left'} onSubmit={handleSubmit}>
            <Flex className="flex-1" column>
              <PasswordFields
                isCurrentPasswordVisible={isCurrentPasswordVisible}
                isPasswordConfirmationVisible={isPasswordConfirmationVisible}
                isPasswordVisible={isPasswordVisible}
                setIsCurrentPasswordVisible={setIsCurrentPasswordVisible}
                setIsPasswordConfirmationVisible={setIsPasswordConfirmationVisible}
                setIsPasswordVisible={setIsPasswordVisible}
                isShowCurrentPasswordField
              />
            </Flex>
            <Flex className="py-4">
              <Button
                disabled={loading}
                label={t('createPassword')}
                testData="create-password"
                type="submit"
                fullWidth
              />
            </Flex>
          </form>
        )
      }}
      validate={(values) => ({
        password: validateRequiredField(values.password),
        currentPassword: validateRequiredField(values.currentPassword),
        passwordConfirmation:
          validateRequiredField(values.passwordConfirmation) ||
          validateEqualFields(
            values.password,
            values.passwordConfirmation,
            t('passwordMatchErrorMessage'),
          ),
      })}
    />
  )

  return (
    <Dialog
      isOpened={isOpened}
      setIsOpened={setIsOpened}
      title={t('changePassword')}
      titleClass="text-2xl font-semibold">
      {renderForm()}
    </Dialog>
  )
}

ChangePasswordModal.propTypes = {
  isOpened: PT.bool.isRequired,
  setIsOpened: PT.func.isRequired,
}

export default ChangePasswordModal
