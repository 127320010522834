import PT from 'prop-types'
import { Text } from '../../index'
import sizes from '../../sizes'
import React from 'react'
import cx from 'classnames'
import Tooltip from '../tooltip/Tooltip'

const DividedList = ({ content, title, skipDivide, skipTopMargin }) => {
  return (
    <div className={cx('w-full', !skipTopMargin && 'mt-3')}>
      {title && (
        <Text fontWeight={'medium'} size={'base'}>
          {title}
        </Text>
      )}
      <div className={cx('flex flex-col', !skipDivide && 'divide-y')}>
        {content.reduce((acc, item) => {
          if (!item.hidden) {
            acc.push(
              <div className="flex flex-row min-h-8 justify-between" key={`${item.label}`}>
                <Text
                  className="flex flex-col justify-start w-fit py-2"
                  color="text-warmBlack-400"
                  size={sizes.SM}>
                  {item.label}
                </Text>
                {item.node ||
                  (item.address ? (
                    <Tooltip
                      className="flex items-center justify-end max-w-4/5 relative"
                      content={item.value}>
                      <Text
                        align="right"
                        className="ml-1 whitespace-nowrap text-ellipsis overflow-hidden block"
                        color={item?.color}
                        size={sizes.SM}>
                        {item.value}
                      </Text>
                    </Tooltip>
                  ) : (
                    <Text
                      className="flex flex-col justify-center text-right items-end py-2"
                      color={item?.color}
                      size={sizes.SM}>
                      {item.email ? <a href={`mailto:${item.email}`}>{item.value}</a> : item.value}
                    </Text>
                  ))}
              </div>,
            )
          }

          return acc
        }, [])}
      </div>
    </div>
  )
}

DividedList.propTypes = {
  content: PT.arrayOf(
    PT.shape({
      label: PT.string,
      value: PT.oneOfType([PT.string, PT.number, PT.node]),
      hidden: PT.bool,
    }).isRequired,
  ),
  title: PT.string,
  skipDivide: PT.bool,
  skipTopMargin: PT.bool,
}
DividedList.defaultProps = {
  title: undefined,
  skipDivide: false,
}

export default DividedList
