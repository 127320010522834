import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Flex } from '../../ui-kit'
import { useCurrentVendor } from '../../hooks/useCurrentVendor'
import Header from '../../components/header/Header'
import SupplierMobileInfo from './SupplierMobileInfo'

const MobileAnonymousLayout = ({ children }) => {
  const vendor = useCurrentVendor()

  useEffect(() => {
    document.body.classList.add('mobile')
  }, [])

  const zoom = document.documentElement.clientWidth / window.innerWidth
  useEffect(() => {
    const appHeight = () => {
      const doc = document.documentElement
      doc.style.setProperty('--app-height', `${window.innerHeight}px`)
    }
    window.addEventListener('resize', appHeight, false)
    appHeight()

    return () => {
      window.removeEventListener('resize', appHeight, false)
    }
  }, [window.innerHeight, zoom])

  return (
    <Flex className="bg-white w-full h-full" column>
      <Header
        centerElement={
          vendor?.logoUrl ? (
            <img
              alt={`${vendor?.name}-logo`}
              className={'max-h-12 h-12 max-w-full'}
              src={vendor?.logoUrl}
            />
          ) : null
        }
      />
      <Flex className="flex-1 px-4 pt-6 pb-2 overflow-x-hidden overflow-y-auto" column>
        <SupplierMobileInfo />
        {children}
      </Flex>
    </Flex>
  )
}

MobileAnonymousLayout.propTypes = {
  children: PropTypes.node,
}

export default MobileAnonymousLayout
