import React, { useCallback, useMemo, useState } from 'react'
import { Button, Flex, Text } from '../../ui-kit'
import { Form } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import fontWeight from '../../ui-kit/fontWeight'
import sizes from '../../ui-kit/sizes'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import PasswordFields from '../../components/security/PasswordFields'
import { validateEqualFields, validateRequiredField } from '../../utils/validators'
import { useCustomMutation } from '../../hooks/useCustomMutation'
import axios from 'axios'
import getVendorSlug from '../../utils/getVendorSlug'
import { INVALID_TOKEN_ROUTE } from '../../constants/routes'
import { applyUserSignIn } from '../../store/actions/auth'
import { useNotifications } from '../../hooks/useNotifications'
import LoginWrapTemplate from './LoginWrapTemplate'

const SetNewPassword = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const vendorSlug = getVendorSlug()
  const { newNotification } = useNotifications()
  const { resetPasswordToken } = useParams()

  const initialValues = useMemo(
    () => ({
      password: '',
      passwordConfirmation: '',
    }),
    [],
  )
  const [isPasswordVisible, setIsPasswordVisible] = useState(false)
  const [isPasswordConfirmationVisible, setIsPasswordConfirmationVisible] = useState(false)

  const [applyResetPassword, { loading }] = useCustomMutation({
    onCompleted: (user) => {
      dispatch(applyUserSignIn(null, user))
      navigate(`${vendorSlug}/payments`)
      newNotification({ success: t('passwordSuccessfullyUpdated') })
    },
    rollbarOptions: { operationName: 'apply_password_reset', target: 'SetNewPassword' },
    mutationOptions: {
      mutationFn: (values) =>
        axios.patch(`${vendorSlug}/auth/password`, {
          vendorUser: {
            ...values,
            resetPasswordToken,
          },
        }),
      mutationKey: ['apply_password_reset'],
    },
    onFailed: () => {
      navigate(`${vendorSlug}${INVALID_TOKEN_ROUTE}`)
    },
  })

  const handleSubmit = useCallback(
    async (values) => {
      if (!resetPasswordToken) {
        navigate(`${vendorSlug}${INVALID_TOKEN_ROUTE}`)
        return
      }

      await applyResetPassword(values)
    },
    [resetPasswordToken],
  )

  return (
    <LoginWrapTemplate>
      <Form
        initialValues={initialValues}
        onSubmit={handleSubmit}
        render={({ handleSubmit }) => {
          return (
            <form className={'flex flex-col flex-1 px-4 pt-6 text-left'} onSubmit={handleSubmit}>
              <Flex className="flex-1" column>
                <Text fontWeight={fontWeight.SEMIBOLD} size={sizes.XL}>
                  {t('createPassword')}
                </Text>
                <PasswordFields
                  isPasswordConfirmationVisible={isPasswordConfirmationVisible}
                  isPasswordVisible={isPasswordVisible}
                  setIsPasswordConfirmationVisible={setIsPasswordConfirmationVisible}
                  setIsPasswordVisible={setIsPasswordVisible}
                />
              </Flex>
              <Flex className="py-4">
                <Button
                  disabled={loading}
                  label={t('createPassword')}
                  testData="create-password"
                  type="submit"
                  fullWidth
                />
              </Flex>
            </form>
          )
        }}
        validate={(values) => ({
          password: validateRequiredField(values.password),
          passwordConfirmation:
            validateRequiredField(values.passwordConfirmation) ||
            validateEqualFields(
              values.password,
              values.passwordConfirmation,
              t('passwordMatchErrorMessage'),
            ),
        })}
      />
    </LoginWrapTemplate>
  )
}

export default SetNewPassword
