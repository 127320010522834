import React, { useMemo } from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { useCurrentUser } from '../hooks/useCurrentUser'
import getVendorSlug from '../utils/getVendorSlug'
import { LoadingSpinner } from '../ui-kit'
import { getRollbarPerson, ROLLBAR_NO_AUTH_PERSON } from '../utils/rollbar'
import { useRollbarPerson } from '@rollbar/react'

const PrivateRoute = () => {
  const currentUser = useCurrentUser()
  const vendorSlug = getVendorSlug()
  const isCurrentUser = currentUser ? !!Object.values(currentUser)?.length : false
  const rollbarPerson = useMemo(
    () => (currentUser ? getRollbarPerson(currentUser) : ROLLBAR_NO_AUTH_PERSON),
    [currentUser],
  )
  useRollbarPerson(rollbarPerson)

  if (!isCurrentUser && !vendorSlug) {
    return <LoadingSpinner loading={true} />
  }
  if (!isCurrentUser && vendorSlug) {
    return <Navigate to={`${vendorSlug}/auth/login`} />
  }
  return <Outlet />
}

export default PrivateRoute
