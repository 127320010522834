import React, { useEffect, useState } from 'react'
import { Flex, Input, LoadingSpinner, Icon, Text } from '../../ui-kit'
import { useTranslation } from 'react-i18next'
import styles from './CardIntegrationStyles'
import colors from '../../ui-kit/colors'
import inputStyles from './InputStyles.module.scss'
import cs from 'classnames'
import { Field } from 'react-final-form'
import { validateRequiredField, validateZipCode } from '../../utils/validators'
import PT from 'prop-types'
import fontWeight from '../../ui-kit/fontWeight'
import sizes from '../../ui-kit/sizes'

export const getCardPaymentToken = async () => {
  try {
    // eslint-disable-next-line no-undef
    const res = await ClearentSDK.getPaymentToken()

    if (res.code === '200') {
      return JSON.stringify(res)
    }
  } catch (err) {
    return err
  }
}

export const validateCreditCardFields = (values) => ({
  zipCode: validateRequiredField(values?.zipCode) || validateZipCode(values?.zipCode),
})

const CardIntegration = ({
  clearentConfiguration,
  isPageView,
  creditCardConvenienceFeePercentage,
}) => {
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(true)
  const baseUrl = clearentConfiguration?.gatewayUrl
  const key = clearentConfiguration?.publicKey

  useEffect(() => {
    if (!key || !baseUrl) {
      return
    }

    const script = document.createElement('script')
    script.src = `${baseUrl}/js-sdk/js/clearent-host.js`
    script.charset = 'utf-8'
    script.async = true
    script.onload = () => {
      if (key && baseUrl) {
        setIsLoading(false)
      }
    }

    script.addEventListener(
      'load',
      function () {
        if (window) {
          try {
            // eslint-disable-next-line no-undef
            ClearentSDK.init({
              baseUrl,
              styles: styles(isPageView),
              pk: key,
            })
          } catch (err) {
            console.error(err)
          }
        }
      },
      { once: true },
    )
    document.head.appendChild(script)
  }, [])
  const zipCodeFormat = (event) => {
    if (!/[0-9]/.test(event.key) || event.target.value.length > 4) {
      event.preventDefault()
    }
  }

  return (
    <Flex className={cs('mt-4', inputStyles.container)} justifyContent={'end'} column>
      {isLoading && <LoadingSpinner className={'h-[210px]'} />}
      {!isLoading && !!creditCardConvenienceFeePercentage && (
        <Text
          className="italic pb-4"
          color="text-black-500"
          fontWeight={fontWeight.MEDIUM}
          size={sizes.XS}>
          {t('cardConvWarn', { cardFee: creditCardConvenienceFeePercentage })}
        </Text>
      )}
      <div className={isPageView ? 'page-view' : ''} id="payment-form" />
      {!isLoading && (
        <Field name="cardPaymentMethod.zipCode">
          {({ input, meta }) => {
            return (
              <Input
                {...input}
                className={inputStyles.zip}
                errorMessage={
                  meta.error && meta.touched && meta.submitFailed ? meta.error : undefined
                }
                icon={<Icon color={colors.CLEARENT_ICON_COLOR} name={'mail'} />}
                iconPosition={'start'}
                id={'zipCode'}
                name={'zipCode'}
                onKeyPress={zipCodeFormat}
                placeholder={t('zipCode')}
              />
            )
          }}
        </Field>
      )}
    </Flex>
  )
}

CardIntegration.propTypes = {
  clearentConfiguration: {
    baseUrl: PT.string,
    publicKey: PT.string,
  },
  onCreatePaymentMethod: PT.func,
  isPageView: PT.bool,
  creditCardConvenienceFeePercentage: PT.number,
}

export default CardIntegration
