import React from 'react'
import PT from 'prop-types'
import cx from 'classnames'
import Text from '../text/Text'
import fontWeight from '../../fontWeight'
import sizes from '../../sizes'
import styles from './NarrowList.module.scss'
import Flex from '../spacing/Flex'

const NarrowList = ({
  className,
  listItems,
  title,
  paddingsClasses,
  withHoverEffect,
  extraTitleElement,
  isBorderHidden,
  withColumns,
  columns,
}) => {
  const rowClasses = cx(
    paddingsClasses
      ? paddingsClasses
      : `lg:py-5 lg:px-6 md:px-4 md:py-3 flex ${
          withHoverEffect ? 'hover:bg-orange-100 cursor-pointer' : ''
        }`,
    styles.listItem,
  )

  return (
    <div className={className}>
      {!!title && (
        <Flex alignItems="center">
          <Text fontWeight={fontWeight.MEDIUM} size={sizes.BASE}>
            {title}
          </Text>
          {extraTitleElement}
        </Flex>
      )}

      {withColumns && (
        <Flex className={cx(rowClasses, !isBorderHidden && 'border-b border-b-gray-200')}>
          {columns.map((column) => (
            <div className={cx(column.className, 'font-semibold')} key={column.title}>
              {column.title}
            </div>
          ))}
        </Flex>
      )}

      <ul className={`${isBorderHidden ? '' : 'divide-y divide-gray-200'}`}>
        {listItems?.map((item, index) => {
          const itemIndex = `list-item-${index}`
          return (
            <li className={rowClasses} key={itemIndex}>
              {item}
            </li>
          )
        })}
      </ul>
    </div>
  )
}

NarrowList.propTypes = {
  className: PT.string,
  listItems: PT.arrayOf(PT.node).isRequired,
  title: PT.string,
  paddingsClasses: PT.string,
  withHoverEffect: PT.bool,
  isBorderHidden: PT.bool,
  extraTitleElement: PT.node,
  withColumns: PT.bool,
  columns: PT.arrayOf(
    PT.shape({
      title: PT.string,
      className: PT.string,
    }),
  ),
}

NarrowList.defaultProps = {
  className: '',
  title: null,
  withHoverEffect: false,
  extraTitleElement: null,
  withColumns: false,
  columns: [],
}

export default NarrowList
