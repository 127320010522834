import React from 'react'
import sizes from '../../ui-kit/sizes'
import { useTranslation } from 'react-i18next'
import fontWeight from '../../ui-kit/fontWeight'
import PropTypes from 'prop-types'
import { logo } from '../../ui-kit/assets'
import { Flex, Text } from '../../ui-kit'
import { useCurrentVendor } from '../../hooks/useCurrentVendor'
import { normalizePhone } from '../../utils'

const SupplierInfo = ({ isWide }) => {
  const { t } = useTranslation()
  const vendor = useCurrentVendor()
  return (
    <Flex alignItems="center" className={'max-h-screen  h-full'} column>
      <Text color="text-black-500" fontWeight={fontWeight.MEDIUM} size={sizes.XL2}>
        {t('welcomeToThe')}
      </Text>
      <Text align="center" fontWeight={fontWeight.SEMIBOLD} size={sizes.XL3}>
        {vendor?.name}
      </Text>
      <Text color="text-black-500" fontWeight={fontWeight.MEDIUM} size={sizes.XL2}>
        {t('paymentPage')}
      </Text>
      <Text className="text-center mt-10" color="text-warmBlack-400" size={sizes.BASE}>
        {t('24/7AccessToManageYourAccount')}
      </Text>
      {vendor?.logoUrl ? (
        <img alt={`${vendor?.name}-logo`} className="w-1/2 h-auto mt-12" src={vendor?.logoUrl} />
      ) : null}
      {isWide && (
        <Flex alignItems="end" className="w-full flex-1" justifyContent="between">
          <Flex className="w-full" justifyContent="center">
            <Text color="text-black-500" size={sizes.XL}>
              {`${t('contactUs')}: `}
              {normalizePhone(vendor?.phoneNumber)}
              {vendor?.phoneNumberExtension &&
                ` ${t('extensionShort')}: ${vendor?.phoneNumberExtension}`}
            </Text>
            <Text color="text-black-500 ml-2 mr-2" size={sizes.XL}>
              |
            </Text>
            <a className="underline hover:no-underline" href={`mailto:${vendor?.email}`}>
              <Text color="text-black-500" size={sizes.XL}>
                {vendor?.email}
              </Text>
            </a>
          </Flex>
        </Flex>
      )}
      {!isWide && (
        <>
          <Flex className="w-full mt-16" column>
            <Flex className="w-full" justifyContent="center" column>
              {vendor?.phoneNumber && (
                <>
                  <Text color="text-black-500" size={sizes.XL}>
                    {`${t('contactUs')}:`}
                  </Text>
                  <Text className="mt-4" color="text-black-500" size={sizes.XL}>
                    {normalizePhone(vendor?.phoneNumber)}
                    {vendor?.phoneNumberExtension &&
                      ` ${t('extensionShort')}: ${vendor?.phoneNumberExtension}`}
                  </Text>
                </>
              )}
              {vendor?.email && (
                <a className="underline hover:no-underline mt-4" href={`mailto:${vendor?.email}`}>
                  <Text color="text-black-500" size={sizes.XL}>
                    {vendor?.email}
                  </Text>
                </a>
              )}
            </Flex>
          </Flex>
          <Flex alignItems="start" className="w-full flex-1 mt-16" justifyContent="end" column>
            <Text color="text-gray-500" size={sizes.XS}>
              {t('poweredBy')}
            </Text>
            <img alt="Suppli" className="h-8 mb-2" src={logo} />
          </Flex>
        </>
      )}
    </Flex>
  )
}

SupplierInfo.propTypes = {
  isWide: PropTypes.bool,
}

SupplierInfo.defaultProps = {
  isWide: false,
}

export default SupplierInfo
