import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { Flex } from '../../ui-kit'

const Header = ({ leftElement, centerElement, rightElement, className }) => {
  return (
    <Flex
      alignItems="center"
      className={cx('min-h-16 w-full px-5 bg-white shadow-1-3-01-1-2-006 z-10', className)}
      justifyContent="between">
      <Flex className={'w-1/6'} justifyContent={'start'}>
        {leftElement}
      </Flex>
      <Flex justifyContent={'center'}>{centerElement}</Flex>
      <Flex className={'w-1/6'} justifyContent={'end'}>
        {rightElement}
      </Flex>
    </Flex>
  )
}

Header.propTypes = {
  className: PropTypes.string,
  leftElement: PropTypes.node,
  rightElement: PropTypes.node,
  centerElement: PropTypes.node,
}

Header.defaultProps = {
  className: '',
  leftElement: null,
  rightElement: null,
  centerElement: null,
}

export default React.memo(Header)
