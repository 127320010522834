import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { applyUserSignIn } from '../../store/actions/auth'
import { Button, Input, Text } from '../../ui-kit'
import colors from '../../ui-kit/colors'
import sizes from '../../ui-kit/sizes'
import { useTranslation } from 'react-i18next'
import { Form, Field } from 'react-final-form'
import { validateEmail, validateRequiredField } from '../../utils/validators'
import buttonsVariants from '../../ui-kit/buttonsVariants'
import LoginWrapTemplate from './LoginWrapTemplate'
import fontWeight from '../../ui-kit/fontWeight'
import { useRollbarPerson } from '@rollbar/react'
import { ROLLBAR_NO_AUTH_PERSON } from '../../utils/rollbar'
import { PRIVACY_POLICY, TERMS_OF_SERVICE } from '../../constants/contacts'
import { useCustomMutation } from '../../hooks/useCustomMutation'
import axios from 'axios'
import { FORM_ERROR } from 'final-form'
import getVendorSlug from '../../utils/getVendorSlug'
import { logoutUser } from '../../utils/utils'
import { selectAuthToken } from '../../store/selectors/auth'

const Login = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const authToken = useSelector(selectAuthToken)
  const vendorSlug = getVendorSlug()
  useRollbarPerson(ROLLBAR_NO_AUTH_PERSON)

  useEffect(() => {
    logoutUser({
      dispatch,
      navigate,
      authToken,
      vendorSlug,
    })
  }, [])

  const [signIn, { loading }] = useCustomMutation({
    onCompleted: (user) => {
      dispatch(applyUserSignIn(null, user))
      navigate(`${vendorSlug}/payments`)
    },
    rollbarOptions: { operationName: 'sign_in', target: 'Login' },
    mutationOptions: {
      mutationFn: (values) =>
        axios.post(`${vendorSlug}/auth/sign_in`, {
          vendorUser: { ...values },
        }),
      mutationKey: ['sign_in'],
    },
  })

  const handleSubmit = useCallback(async (values) => {
    try {
      await signIn(values)
    } catch (error) {
      if (error?.response?.status === 422 || error?.response?.status === 401) {
        return { [FORM_ERROR]: t('invalidCreds') }
      } else {
        return { [FORM_ERROR]: error?.response?.data?.errors }
      }
    }
  }, [])

  return (
    <Form
      onSubmit={handleSubmit}
      render={({ handleSubmit, submitError }) => {
        return (
          <form onSubmit={handleSubmit}>
            <LoginWrapTemplate>
              <Text fontWeight={fontWeight.MEDIUM} size={sizes.XL2}>
                {t('loginToYourAccount')}
              </Text>
              <Field name="email">
                {({ input, meta }) => {
                  return (
                    <Input
                      errorMessage={
                        meta.error && meta.touched && meta.submitFailed ? meta.error : undefined
                      }
                      id={input.name}
                      label={t(input.name)}
                      placeholder="your_email@example.com"
                      type="email"
                      {...input}
                    />
                  )
                }}
              </Field>
              <Field name="password">
                {({ input, meta }) => {
                  return (
                    <Input
                      errorMessage={
                        meta.error && meta.touched && meta.submitFailed ? meta.error : undefined
                      }
                      id={input.name}
                      label={t(input.name)}
                      placeholder={t('yourPassword')}
                      type="password"
                      {...input}
                    />
                  )
                }}
              </Field>
              {submitError && <span className="error leading-8">{submitError}</span>}
              <div className="text-left">
                <Button
                  label={t('forgotPassword')}
                  linkTo={`${vendorSlug}/auth/login/reset_password`}
                  testData="forgot-password"
                  variant={buttonsVariants.PRIMARY_LINK}
                />
              </div>
              <Button
                color={colors.PRIMARY}
                disabled={loading}
                label={t('login')}
                testData="login"
                type="submit"
                fullWidth
              />
              <div>
                <Text color="text-warmBlack-400 mt-2" size={sizes.XS}>
                  {t('loginWarning')}
                </Text>
                <Text className={'ml-1'} color="blue mt-2" size={sizes.XS}>
                  <a href={TERMS_OF_SERVICE}>{t('termsOfService')}</a>
                </Text>
                <Text className={'ml-1'} color="text-warmBlack-400 mt-2" size={sizes.XS}>
                  {t('and')}
                </Text>
                <Text className={'ml-1'} color="blue mt-2" size={sizes.XS}>
                  <a href={PRIVACY_POLICY}>{t('privacyPolicy')}</a>.
                </Text>
              </div>
            </LoginWrapTemplate>
          </form>
        )
      }}
      validate={(values) => ({
        email: validateRequiredField(values.email) || validateEmail(values.email),
        password: validateRequiredField(values.password),
      })}
    />
  )
}

export default Login
