const styles = (isPageView) =>
  `
.input-group {
              border: 1px solid #D1D5DB;
              margin-bottom: 1rem;
              line-height: 1.1876rem;
              font-size: 0.875rem;
              border-radius: 6px
            }
input {
            font-size:0.875rem !important;
            border-radius: 6px !important;
            }
            
.row span {
            border-radius:6px !important;
            }
            
.row:nth-of-type(2) .col-12:nth-of-type(1) input {
            border-width: 1px;
            }
            
.input-group>.input-group-prepend>.input-group-text {
            border-radius:6px;
            }
            
.form-control{
             border: 0; 
             height: 37px;
            }
@media (max-width: 576px) {
.form-control{
             height: 37px !important
            }
}
            
.input-group-text, .input-group .input-group-prepend .input-group-text{
             border-width: 0; 
            }
            
#card-group #card-image {
            width: 36px;
            }
            
#card-group #card-icon {
            margin-left:-1px;
            }
            
#card-group.has-error, #exp-date-group.has-error, #cvc-group.has-error {
            border-color:#E32437;
            } 
            
#card-group.has-error #card-icon, #exp-date-group.has-error #date-icon, #cvc-group.has-error #cvc-icon{
            color:#E32437;
            }
            
#clearent-payment-errors.clearent-payment-errors {
            padding-top: 0;
            color:#E32437 !important;
            }
.clearent-payment-errors {
            padding-top: 0;
            }
` +
  (isPageView
    ? `.col-md-6+.col-md-6 {
            padding-left: 5px;
 }`
    : '')
export default styles
