import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react'
import PT from 'prop-types'
import cx from 'classnames'
import { SortedAscendingIcon, SortedDescendingIcon } from '../../ui-kit/components/icons/sortIcons'
import { DataGridPro as DataGrid } from '@mui/x-data-grid-pro'
import { Flex, Pagination, Text } from '../../ui-kit'
import { makeSortString } from '../../utils/sorting'
import { useQueryParams } from '../../hooks/useQueryParams'
import classes from './DataGridComponent.scss'
import { getLocalPaginationData } from './dataGridUtils'
import { useTranslation } from 'react-i18next'
import fontWeight from '../../ui-kit/fontWeight'
import ReportFilters from '../filters/ReportFilters'

// eslint-disable-next-line react/display-name
const DataGridComponent = forwardRef(
  (
    {
      availableFilters,
      checkboxSelection,
      rows,
      columns,
      loading,
      page,
      paginationData,
      rowClassName,
      rowClick,
      rowHeight,
      selectionModel,
      sortModel,
      handleSortModelChange,
      handlePageChange,
      handleSelectionModelChange,
      hideFooter,
      headerHeight,
      searchLabel,
      getRowId,
      localPagination,
      pageSize,
      wrapperClassNames,
      withSorting,
      userFilters,
    },
    ref,
  ) => {
    const [localPage, setLocalPage] = useState(1)
    const handleLocalPageChange = useCallback((newPage) => setLocalPage(newPage), [setLocalPage])
    const currentPage = localPagination ? localPage : page
    const { from, to, totalCount, totalPages } = localPagination
      ? getLocalPaginationData(rows, currentPage, pageSize)
      : paginationData

    const disableSelectionOnClick = !!rowClick
    const { queryParams, setQueryParams, removeQueryParam } = useQueryParams()

    const sort = queryParams.sort || ''
    const search = queryParams.search || null

    const defaultHandlePageChange = (newPage) => {
      setQueryParams({ page: newPage })
    }
    const [defaultSortModel, setSortModel] = useState(sortModel || [])
    useEffect(() => setSortModel(sortModel || []), [sortModel])

    const defaultHandleSortModelChange = (newModel) => {
      setSortModel(newModel)
      const model = newModel[0]
      if (model?.field) {
        const sortString = makeSortString(sort, model?.field)
        setQueryParams({ sort: sortString })
      } else {
        removeQueryParam('sort')
      }
    }
    const isPaginated = Object.keys(paginationData).length > 0
    const displayRows = useMemo(() => {
      if (!localPagination) {
        return rows
      }

      const start = from - 1

      return rows.slice(start, to)
    }, [rows, localPagination, from, to])

    const paginatedProps =
      (isPaginated && !localPagination) || withSorting
        ? {
            sortModel: defaultSortModel,
            onSortModelChange: handleSortModelChange || defaultHandleSortModelChange,
            sortingMode: 'server',
          }
        : {
            sortingMode: 'client',
          }
    const { t } = useTranslation()

    useImperativeHandle(
      ref,
      () => ({
        resetLocalPage: () => {
          setLocalPage(1)
        },
      }),
      [setLocalPage],
    )

    const hidden = useMemo(() => {
      return columns.reduce((acc, item) => {
        if (item.hide) {
          acc[item.field] = false
        }
        return acc
      }, {})
    }, [columns])

    const filterColumns = useMemo(
      () =>
        columns?.map?.((item) => ({
          filterId: item.filterId,
          filterTitle: t(item.filterTitle) || t(item.field),
        })) || [],
      [columns],
    )

    return (
      <div className={cx('w-full', wrapperClassNames)}>
        <ReportFilters
          availableFilters={availableFilters}
          columns={filterColumns}
          userFilters={userFilters}
          isGridFilter
        />
        {!!search && searchLabel && (
          <div className="mb-2 px-1">
            <Text className="pr-1" fontWeight={fontWeight.MEDIUM}>
              {rows.length} {searchLabel}
            </Text>
            <Text color="text-black-500 pr-1" fontWeight={fontWeight.LIGHT}>
              {t('foundWith')}
            </Text>
            <Text fontWeight={fontWeight.MEDIUM}>{`'${search}'`}</Text>
          </div>
        )}
        <Flex className="w-full">
          <DataGrid
            checkboxSelection={checkboxSelection}
            classes={classes.dataGrid}
            columnVisibilityModel={hidden}
            columns={columns}
            disableRowSelectionOnClick={disableSelectionOnClick}
            getRowClassName={() => rowClassName}
            getRowId={getRowId}
            headerHeight={headerHeight}
            hideFooter={hideFooter || ((isPaginated || localPagination) && totalPages <= 1)}
            loading={loading}
            onRowClick={rowClick}
            onRowSelectionModelChange={handleSelectionModelChange}
            pageSize={pageSize}
            rowHeight={rowHeight}
            rowSelectionModel={selectionModel}
            rows={displayRows}
            rowsPerPageOptions={[pageSize]}
            slotProps={{
              pagination: {
                currentPage,
                totalPages,
                onPageChange: localPagination
                  ? handleLocalPageChange
                  : handlePageChange || defaultHandlePageChange,
                className: 'mt-1',
                fromResult: from,
                toResult: to,
                totalResults: totalCount,
                withResults: true,
                loading,
              },
            }}
            slots={{
              columnSortedDescendingIcon: SortedDescendingIcon,
              columnSortedAscendingIcon: SortedAscendingIcon,
              pagination: Pagination,
            }}
            autoHeight
            disableColumnMenu
            disableColumnReorder
            disableMultipleColumnsSorting
            hideFooterRowCount
            pagination
            {...paginatedProps}
          />
        </Flex>
      </div>
    )
  },
)

DataGridComponent.propTypes = {
  availableFilters: PT.arrayOf(
    PT.shape({
      key: PT.string,
      type: PT.string,
    }),
  ),
  checkboxSelection: PT.bool,
  columns: PT.arrayOf(
    PT.shape({
      field: PT.string,
    }),
  ),
  handlePageChange: PT.func,
  handleSelectionModelChange: PT.func,
  handleSortModelChange: PT.func,
  hideFooter: PT.bool,
  headerHeight: PT.number,
  loading: PT.bool,
  page: PT.number,
  paginationData: PT.shape({
    from: PT.number,
    to: PT.number,
    totalCount: PT.number,
    totalPages: PT.number,
  }),
  rowClassName: PT.string,
  rowClick: PT.func,
  rowHeight: PT.number,
  rows: PT.arrayOf(
    PT.shape({
      id: PT.string,
    }),
  ),
  selectionModel: PT.arrayOf(PT.string),
  searchLabel: PT.string,
  sortModel: PT.arrayOf(
    PT.shape({
      field: PT.string,
      sort: PT.string,
    }),
  ),
  getRowId: PT.func,
  localPagination: PT.bool,
  pageSize: PT.number,
  wrapperClassNames: PT.string,
  withSorting: PT.bool,
  userFilters: PT.shape({}),
}

DataGridComponent.defaultProps = {
  availableFilters: null,
  checkboxSelection: false,
  columns: [],
  handlePageChange: null,
  headerHeight: 52,
  handleSelectionModelChange: () => {},
  handleSortModelChange: null,
  hideFooter: false,
  loading: false,
  page: 1,
  paginationData: {},
  rowClassName: '',
  rowClick: () => {},
  rowHeight: 52,
  rows: [],
  selectionModel: [],
  sortModel: null,
  searchLabel: '',
  getRowId: null,
  localPagination: false,
  pageSize: 10,
  wrapperClassNames: '',
  withSorting: false,
}

export default React.memo(DataGridComponent)
