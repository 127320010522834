import React from 'react'
import { Button, Flex, Text } from '../../ui-kit'
import { useTranslation } from 'react-i18next'
import fontWeight from '../../ui-kit/fontWeight'
import sizes from '../../ui-kit/sizes'
import getVendorSlug from '../../utils/getVendorSlug'
import LoginWrapTemplate from './LoginWrapTemplate'

const InvalidToken = () => {
  const { t } = useTranslation()
  const vendorSlug = getVendorSlug()

  return (
    <LoginWrapTemplate>
      <Flex className="px-4 pt-6 text-left" column>
        <Flex className="flex-1" column>
          <Text fontWeight={fontWeight.SEMIBOLD} size={sizes.XL2}>
            {t('invalidLink')}
          </Text>
          <Text
            className="pt-3"
            color="text-black-500"
            fontWeight={fontWeight.MEDIUM}
            size={sizes.BASE}>
            {t('invalidPasswordLink')}
          </Text>
        </Flex>
        <Flex className="py-4">
          <Button className="mt-4" label={t('login')} linkTo={`${vendorSlug}/auth/login`} />
        </Flex>
      </Flex>
    </LoginWrapTemplate>
  )
}

export default InvalidToken
