import React, { useEffect, useState } from 'react'
import { Outlet, useNavigate, useParams } from 'react-router-dom'
import Navigation from './Navigation'
import MainHeader from './MainHeader'
import useWindowDimensions from '../../hooks/useWindowDimensions'
import { LG } from '../../constants/screenSizes'
import { useCurrentUser } from '../../hooks/useCurrentUser'
import { useDispatch, useSelector } from 'react-redux'
import { selectAuthToken } from '../../store/selectors/auth'
import { logoutUser } from '../../utils/utils'

const MainLayout = () => {
  const { vendorName } = useParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { width } = useWindowDimensions()
  const [isMenuCollapsed, setIsMenuCollapsed] = useState(width < LG)
  const currentUser = useCurrentUser()
  const authToken = useSelector(selectAuthToken)

  useEffect(() => {
    setIsMenuCollapsed(width < LG)
  }, [width])

  useEffect(() => {
    if (!currentUser) {
      return
    }

    if (currentUser.status !== 'active') {
      logoutUser({
        dispatch,
        navigate,
        authToken,
        vendorSlug: `/${vendorName}`,
        navigationUrl: `/${vendorName}/auth/login`,
      })
    }
  }, [currentUser])

  return (
    <div className="flex overflow-hidden bg-gray-100">
      <div className="h-screen md:flex md:flex-shrink-0 z-30 fixed">
        <Navigation isMenuCollapsed={isMenuCollapsed} />
      </div>

      <div className={`flex flex-col w-full z-10 ${width < LG ? 'pl-20' : 'pl-44'}`}>
        <MainHeader isMenuCollapsed={isMenuCollapsed} />

        <div className="pt-20 w-full p-6 min-h-screen">
          <Outlet />
        </div>
      </div>
    </div>
  )
}

export default MainLayout
