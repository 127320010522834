import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Button } from '../../../ui-kit'
import colors from '../../../ui-kit/colors'
import buttonsVariants from '../../../ui-kit/buttonsVariants'
import sizes from '../../../ui-kit/sizes'
import { useCustomMutation } from '../../../hooks/useCustomMutation'
import axios from 'axios'
import { downloadResponse } from '../logic/downloadResponse'
import getVendorSlug from '../../../utils/getVendorSlug'
import { mapFiltersForQuery } from '../../../components/filters/logic/logic'

const ReportFooter = ({ closeReport, reportMetadata, userFilters, sort }) => {
  const { t } = useTranslation()
  const vendorSlug = getVendorSlug()

  const [downloadFile, { loading }] = useCustomMutation({
    onCompleted: (response, values) => {
      downloadResponse(response, values.fileName)
    },
    rollbarOptions: { operationName: 'export_report_file', target: 'ReportFooter' },
    mutationOptions: {
      mutationFn: (values) =>
        axios.get(`${vendorSlug}${values.url}`, {
          params: values.params,
          responseType: 'blob',
        }),
      mutationKey: ['export_report_file'],
    },
  })

  const handleDownloadCsv = useCallback(async () => {
    let params = {}
    if (userFilters.length) {
      params.filters = mapFiltersForQuery(userFilters)
    }

    if (reportMetadata?.defaultFilters) {
      params.filters = { ...params.filters, ...reportMetadata.defaultFilters }
    }

    if (sort) {
      params.sort = sort
    }

    await downloadFile({
      url: reportMetadata?.csvUrl,
      params,
      fileName: `${t(reportMetadata?.fileNameKey)}.csv`,
    })
  }, [userFilters, reportMetadata, sort])

  const handleDownloadXlsx = useCallback(async () => {
    let params = {}

    if (userFilters.length) {
      params.filters = mapFiltersForQuery(userFilters)
    }

    if (reportMetadata?.defaultFilters) {
      params.filters = { ...params.filters, ...reportMetadata.defaultFilters }
    }

    if (sort) {
      params.sort = sort
    }

    await downloadFile({
      url: reportMetadata?.xlsxUrl,
      params,
      fileName: `${t(reportMetadata?.fileNameKey)}.xlsx`,
    })
  }, [userFilters, reportMetadata, sort])

  return (
    <>
      {reportMetadata?.xlsxUrl && (
        <Button
          className="ml-4"
          color={colors.PRIMARY}
          disabled={loading}
          label={t('exportExcel')}
          onClick={handleDownloadXlsx}
          size={sizes.SM}
          testData="export-xlsx"
        />
      )}
      {reportMetadata?.csvUrl && (
        <Button
          className="ml-4"
          color={colors.PRIMARY}
          disabled={loading}
          label={t('exportCSV')}
          onClick={handleDownloadCsv}
          size={sizes.SM}
          testData="export-csv"
        />
      )}
      <Button
        color={colors.PRIMARY}
        label={t('cancel')}
        onClick={closeReport}
        size={sizes.SM}
        testData="close-report"
        variant={buttonsVariants.TERTIARY}
      />
    </>
  )
}

ReportFooter.propTypes = {
  sort: PropTypes.string,
  closeReport: PropTypes.func.isRequired,
  userFilters: PropTypes.arrayOf(PropTypes.any),
  reportMetadata: PropTypes.shape({
    csvUrl: PropTypes.string,
    xlsxUrl: PropTypes.string,
    jsonUrl: PropTypes.string,
    fileNameKey: PropTypes.string,
    defaultFilters: PropTypes.object,
  }).isRequired,
}

ReportFooter.defaultProps = {
  userFilters: [],
}

export default React.memo(ReportFooter)
