import React, { useEffect, useState } from 'react'
import PageHeader from '../../components/headers/PageHeader'
import { Button, Flex, Tabs } from '../../ui-kit'
import { useTranslation } from 'react-i18next'
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import { getActiveTab, getTabsMap } from '../../utils/utils'
import PT from 'prop-types'
import FiltersControlButton from '../../components/filters/FiltersControlButton'
import PaymentsTab from './PaymentsTab'
import RefundsTab from './RefundsTab'
import buttonsVariants from '../../ui-kit/buttonsVariants'

const Payments = ({ contractData }) => {
  const { t } = useTranslation()
  const tabs = ['payments', 'refunds']
  const location = useLocation()
  const navigate = useNavigate()
  const [activeTab, setActiveTab] = useState('')
  const [isNewPaymentModalOpened, setIsNewPaymentModalOpened] = useState(false)

  const handleTabClick = (tab) => {
    navigate(`./${tab}`)
    setActiveTab(tab)
  }

  const tabsMap = getTabsMap(tabs, activeTab, handleTabClick, t)

  const currentActiveTab = getActiveTab(location, 'payments') || 'payments'

  useEffect(() => {
    setActiveTab(currentActiveTab)
  }, [location])

  return (
    <>
      <PageHeader headerTitle={t('payments')} />

      <Flex className="justify-between items-center">
        <Tabs tabs={tabsMap} />
        <Flex>
          {activeTab === 'payments' ? (
            <Flex className="pr-5" justifyContent="end">
              <Button
                iconName="plusCircle"
                iconType="outline"
                label={t('newPayment')}
                onClick={() => setIsNewPaymentModalOpened(true)}
                testData="new-payment"
                variant={buttonsVariants.SECONDARY}
              />
            </Flex>
          ) : null}
          <FiltersControlButton />
        </Flex>
      </Flex>

      <Routes>
        <Route
          element={
            <PaymentsTab
              isNewPaymentModalOpened={isNewPaymentModalOpened}
              setIsNewPaymentModalOpened={setIsNewPaymentModalOpened}
            />
          }
          path="/payments"
        />
        <Route element={<RefundsTab contractData={contractData} />} path="/refunds" />
        <Route
          element={
            <PaymentsTab
              isNewPaymentModalOpened={isNewPaymentModalOpened}
              setIsNewPaymentModalOpened={setIsNewPaymentModalOpened}
            />
          }
          path="/"
        />
      </Routes>
    </>
  )
}

Payments.propTypes = {
  contractData: PT.shape({
    aging: PT.number,
    id: PT.string,
    customerName: PT.string,
    outstandingAmountCents: PT.number,
    availableCreditAmountCents: PT.number,
    availableDepositAmountCents: PT.number,
    buyer: PT.shape({
      name: PT.string,
      paymentMethods: PT.arrayOf(
        PT.shape({
          id: PT.string,
          title: PT.string,
          type: PT.string,
        }),
      ),
    }),
  }),
}

Payments.defaultProps = {
  contractData: {},
}

export default Payments
