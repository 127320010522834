export const createPaymentMethodTabs = {
  CARD: 'card',
  ACH: 'ach',
}

export const operationResultMods = {
  LOADING: 'LOADING',
  ACH_SUCCESS: 'ACH_SUCCESS',
  ACH_FAILURE: 'ACH_FAILURE',
  ALREADY_PAID: 'ALREADY_PAID',
  SERVER_ERROR: 'SERVER_ERROR',
  LINK_NOT_AVAILABLE: 'LINK_NOT_AVAILABLE',
  CREDIT_CARD_SUCCESS: 'CREDIT_CARD_SUCCESS',
  CREDIT_CARD_FAILURE: 'CREDIT_CARD_FAILURE',
  GENERAL_SERVER_ERROR: 'GENERAL_SERVER_ERROR',
  PAYMENT_PLAN_APPROVED: 'PAYMENT_PLAN_APPROVED',
}

export const paymentMethodTypes = {
  ACH_PAYMENT_METHOD: 'AchPaymentMethod',
  PAYCHECK_PAYMENT_METHOD: 'PaycheckPaymentMethod',
  CREDIT_CARD_PAYMENT_METHOD: 'CreditCardPaymentMethod',
}

export const paymentStatus = {
  ENQUEUED: 'enqueued',
  PROCESSING: 'processing',
  SUBMITTED: 'submitted',
  PAID: 'paid',
  FAILED: 'failed',
  VOIDED: 'voided',
  CHARGEBACK: 'chargeback',
}
