import { Button, Input, Text } from '../../../ui-kit'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Field, Form } from 'react-final-form'
import {
  validateRequiredField,
  validateWebsiteURL,
  validateZipCode,
} from '../../../utils/validators'
import fontWeight from '../../../ui-kit/fontWeight'
import sizes from '../../../ui-kit/sizes'
import { countriesList } from '../../../utils/countriesList'
import NDropdown from '../../../ui-kit/components/dropdown/NDropdown'
import PT from 'prop-types'
import { getOnlyNumbers } from '../../../utils/utils'
import { useNotifications } from '../../../hooks/useNotifications'
import buttonsVariants from '../../../ui-kit/buttonsVariants'
import { useBeforeUnload } from '../../../hooks/useBeforeUnload'
import AlertModal from '../../../ui-kit/components/alertModal/AlertModal'
import { useDirtyFormAlert } from '../../../hooks/useDirtyFormAlert'
import { companyInformationModes } from './modes'
import Checkbox from '../../../ui-kit/components/inputs/Checkbox'
import { statesList } from '../../../utils/statesList'
import { useCustomMutation } from '../../../hooks/useCustomMutation'
import axios from 'axios'
import { PUBLIC_VENDOR_INFO_KEY } from '../../../constants/queryKeys'
import getVendorSlug from '../../../utils/getVendorSlug'
import { setVendorData } from '../../../store/actions/auth'
import { useDispatch } from 'react-redux'
import { isEqual } from 'lodash'

const CompanyInformationEditForm = ({ vendor, setMode }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const vendorSlug = getVendorSlug()
  const { newNotification } = useNotifications()

  const initialValues = {
    name: vendor.name,
    officeCountry: vendor.officeAddress?.country,
    officeStreet: vendor.officeAddress?.street,
    officeStreet2: vendor.officeAddress?.street2,
    officeCity: vendor.officeAddress?.city,
    officeState: vendor.officeAddress?.state,
    officeZipCode: vendor.officeAddress?.zipCode,
    billingCountry: vendor.billingAddress?.country,
    billingStreet: vendor.billingAddress?.street,
    billingStreet2: vendor.billingAddress?.street2,
    billingCity: vendor.billingAddress?.city,
    billingState: vendor.billingAddress?.state,
    billingZipCode: vendor.billingAddress?.zipCode,
    website: vendor.website,
    isBillingAddressDifferentFromOfficeAddress: !isEqual(
      vendor.billingAddress,
      vendor.officeAddress,
    ),
  }

  const [updateVendor, { loading }, updateQuery] = useCustomMutation({
    onCompleted: (vendorData) => {
      updateQuery({ queryKey: [PUBLIC_VENDOR_INFO_KEY], record: vendorData })
      dispatch(setVendorData(vendorData))
      newNotification({ success: t('informationWasUpdated') })
      setMode(companyInformationModes.VIEW)
    },
    rollbarOptions: { operationName: 'update_vendor', target: 'CompanyInformationEditForm' },
    mutationOptions: {
      mutationFn: (data) => axios.put(`${vendorSlug}/vendor`, { vendor: { ...data } }),
      mutationKey: ['update_vendor'],
    },
  })

  const onSubmit = async (values) => {
    const data = {
      name: values.name,
      website: values.website,
      officeAddress: {
        country: values.officeCountry,
        street: values.officeStreet,
        street2: values.officeStreet2,
        street3: null,
        city: values.officeCity,
        state: values.officeState,
        zipCode: values.officeZipCode,
      },
      billingAddress: values.isBillingAddressDifferentFromOfficeAddress
        ? {
            country: values.billingCountry,
            street: values.billingStreet,
            street2: values.billingStreet2,
            street3: null,
            city: values.billingCity,
            state: values.billingState,
            zipCode: values.billingZipCode,
          }
        : {
            country: values.officeCountry,
            street: values.officeStreet,
            street2: values.officeStreet2,
            street3: null,
            city: values.officeCity,
            state: values.officeState,
            zipCode: values.officeZipCode,
          },
    }
    await updateVendor(data)
  }

  const closeForm = useCallback(() => {
    setMode(companyInformationModes.VIEW)
  }, [setMode])
  const { isFormDirty, setDirtyFormState, requestClose } = useDirtyFormAlert({
    closeForm,
  })

  return (
    <div className="w-full">
      <Form
        initialValues={initialValues}
        mutators={{
          clearOfficeState: (args, state, utils) => {
            utils.changeValue(state, 'officeState', () => '')
          },
          clearBillingState: (args, state, utils) => {
            utils.changeValue(state, 'billingState', () => '')
          },
        }}
        onSubmit={onSubmit}
        render={({ handleSubmit, dirty, values, form }) => {
          useBeforeUnload({ when: dirty })
          dirty !== isFormDirty && setDirtyFormState(dirty)

          return (
            <form className="flex flex-col" onSubmit={handleSubmit}>
              <div className="w-2/3 pr-5">
                <Field name="name">
                  {({ input, meta }) => {
                    return (
                      <Input
                        errorMessage={
                          meta.error && meta.touched && meta.submitFailed ? meta.error : undefined
                        }
                        id={input.name}
                        label={t('accountName')}
                        placeholder={t('name')}
                        tabIndex="1"
                        {...input}
                      />
                    )
                  }}
                </Field>
              </div>

              <div className="mt-6">
                <Text fontWeight={fontWeight.NORMAL} size={sizes.LG}>
                  {t('officeAddress')}
                </Text>
              </div>

              <div className="mt-4 w-1/3 pr-4">
                <Field name="officeCountry">
                  {({ input, meta }) => {
                    return (
                      <NDropdown
                        defaultValue={countriesList[0].value}
                        errorMessage={meta.error && meta.submitFailed ? meta.error : undefined}
                        id={input.name}
                        inputClassName="w-full"
                        label={t('countryRegion')}
                        name={input.name}
                        onChange={(e) => {
                          const nextCountry = e.target.value

                          if (
                            nextCountry !== values.country &&
                            nextCountry === countriesList[0].value
                          ) {
                            form.mutators.clearOfficeState()
                          }

                          input.onChange(nextCountry)
                        }}
                        options={countriesList}
                        placeholder={t('countryRegion')}
                        value={input.value}
                      />
                    )
                  }}
                </Field>
              </div>

              <div className="w-full mt-4 pr-8">
                <Field name="officeStreet">
                  {({ input, meta }) => {
                    return (
                      <Input
                        errorMessage={
                          meta.error && meta.touched && meta.submitFailed ? meta.error : undefined
                        }
                        id={input.name}
                        label={t('streetAddress')}
                        placeholder={t('address')}
                        tabIndex="1"
                        {...input}
                      />
                    )
                  }}
                </Field>
              </div>

              <div className="w-full mt-4 pr-8">
                <Field name="officeStreet2">
                  {({ input, meta }) => {
                    return (
                      <Input
                        errorMessage={
                          meta.error && meta.touched && meta.submitFailed ? meta.error : undefined
                        }
                        id={input.name}
                        label={t('streetAddress2')}
                        placeholder={t('address2')}
                        tabIndex="1"
                        {...input}
                      />
                    )
                  }}
                </Field>
              </div>

              <div className="w-full mt-6 flex flex-row space-x-2 pr-8">
                <div className="w-4/12">
                  <Field name="officeCity">
                    {({ input, meta }) => {
                      return (
                        <Input
                          errorMessage={
                            meta.error && meta.touched && meta.submitFailed ? meta.error : undefined
                          }
                          id={input.name}
                          label={t('city')}
                          placeholder={t('city')}
                          tabIndex="1"
                          {...input}
                        />
                      )
                    }}
                  </Field>
                </div>

                <div className="w-4/12">
                  <Field name="officeState">
                    {({ input, meta }) => {
                      return values.officeCountry === countriesList[0].value ? (
                        <NDropdown
                          errorMessage={meta.error && meta.submitFailed ? meta.error : undefined}
                          id={input.name}
                          inputClassName="w-full"
                          label={t('stateProvince')}
                          name={input.name}
                          onChange={(e) => {
                            input.onChange(e.target.value)
                          }}
                          options={statesList}
                          placeholder={t('state')}
                          value={input.value}
                        />
                      ) : (
                        <Input
                          errorMessage={
                            meta.error && meta.touched && meta.submitFailed ? meta.error : undefined
                          }
                          id={input.name}
                          label={t('stateProvince')}
                          placeholder={t('state')}
                          tabIndex="1"
                          {...input}
                        />
                      )
                    }}
                  </Field>
                </div>

                <div className="w-4/12">
                  <Field name="officeZipCode" parse={getOnlyNumbers}>
                    {({ input, meta }) => {
                      return (
                        <Input
                          errorMessage={
                            meta.error && meta.touched && meta.submitFailed ? meta.error : undefined
                          }
                          id={input.name}
                          label={t('zipPostal')}
                          maxLength={5}
                          placeholder={t('zip')}
                          tabIndex="1"
                          {...input}
                        />
                      )
                    }}
                  </Field>
                </div>
              </div>

              <div className="mt-4">
                <Field name="isBillingAddressDifferentFromOfficeAddress">
                  {({ input }) => (
                    <Checkbox
                      id={input.name}
                      name={input.name}
                      onChange={input.onChange}
                      value={input.value}
                    />
                  )}
                </Field>

                <label
                  className="ml-2 text-black-500 font-medium"
                  htmlFor="isBillingAddressDifferentFromOfficeAddress">
                  {t('billingAddressDifferentThanAbove')}
                </label>
              </div>

              {values.isBillingAddressDifferentFromOfficeAddress && (
                <>
                  <div className="mt-6">
                    <Text fontWeight={fontWeight.NORMAL} size={sizes.LG}>
                      {t('billingAddress')}
                    </Text>
                  </div>

                  <div className="mt-4 w-1/3 pr-4">
                    <Field name="billingCountry">
                      {({ input, meta }) => {
                        return (
                          <NDropdown
                            errorMessage={meta.error && meta.submitFailed ? meta.error : undefined}
                            id={input.name}
                            inputClassName="w-full"
                            label={t('countryRegion')}
                            name={input.name}
                            onChange={(e) => {
                              const nextCountry = e.target.value

                              if (
                                nextCountry !== values.country &&
                                nextCountry === countriesList[0].value
                              ) {
                                form.mutators.clearBillingState()
                              }

                              input.onChange(nextCountry)
                            }}
                            options={countriesList}
                            placeholder={t('countryRegion')}
                            value={input.value}
                          />
                        )
                      }}
                    </Field>
                  </div>

                  <div className="w-full mt-4 pr-8">
                    <Field name="billingStreet">
                      {({ input, meta }) => {
                        return (
                          <Input
                            errorMessage={
                              meta.error && meta.touched && meta.submitFailed
                                ? meta.error
                                : undefined
                            }
                            id={input.name}
                            label={t('streetAddress')}
                            placeholder={t('address')}
                            tabIndex="1"
                            {...input}
                          />
                        )
                      }}
                    </Field>
                  </div>

                  <div className="w-full mt-4 pr-8">
                    <Field name="billingStreet2">
                      {({ input, meta }) => {
                        return (
                          <Input
                            errorMessage={
                              meta.error && meta.touched && meta.submitFailed
                                ? meta.error
                                : undefined
                            }
                            id={input.name}
                            label={t('streetAddress2')}
                            placeholder={t('address2')}
                            tabIndex="1"
                            {...input}
                          />
                        )
                      }}
                    </Field>
                  </div>

                  <div className="w-full mt-6 flex flex-row space-x-2 pr-8">
                    <div className="w-4/12">
                      <Field name="billingCity">
                        {({ input, meta }) => {
                          return (
                            <Input
                              errorMessage={
                                meta.error && meta.touched && meta.submitFailed
                                  ? meta.error
                                  : undefined
                              }
                              id={input.name}
                              label={t('city')}
                              placeholder={t('city')}
                              tabIndex="1"
                              {...input}
                            />
                          )
                        }}
                      </Field>
                    </div>

                    <div className="w-4/12">
                      <Field name="billingState">
                        {({ input, meta }) => {
                          return values.billingCountry === countriesList[0].value ? (
                            <NDropdown
                              errorMessage={
                                meta.error && meta.submitFailed ? meta.error : undefined
                              }
                              id={input.name}
                              inputClassName="w-full"
                              label={t('stateProvince')}
                              name={input.name}
                              onChange={(e) => {
                                input.onChange(e.target.value)
                              }}
                              options={statesList}
                              placeholder={t('state')}
                              value={input.value}
                            />
                          ) : (
                            <Input
                              errorMessage={
                                meta.error && meta.touched && meta.submitFailed
                                  ? meta.error
                                  : undefined
                              }
                              id={input.name}
                              label={t('stateProvince')}
                              placeholder={t('state')}
                              tabIndex="1"
                              {...input}
                            />
                          )
                        }}
                      </Field>
                    </div>

                    <div className="w-4/12">
                      <Field name="billingZipCode" parse={getOnlyNumbers}>
                        {({ input, meta }) => {
                          return (
                            <Input
                              errorMessage={
                                meta.error && meta.touched && meta.submitFailed
                                  ? meta.error
                                  : undefined
                              }
                              id={input.name}
                              label={t('zipPostal')}
                              maxLength={5}
                              placeholder={t('zip')}
                              tabIndex="1"
                              {...input}
                            />
                          )
                        }}
                      </Field>
                    </div>
                  </div>
                </>
              )}

              <div className="mt-8">
                <Text fontWeight={fontWeight.NORMAL} size={sizes.LG}>
                  {t('website')}
                </Text>
              </div>

              <div className="mt-6 w-1/3 pr-4">
                <Field name="website">
                  {({ input, meta }) => {
                    return (
                      <Input
                        errorMessage={
                          meta.error && meta.touched && meta.submitFailed ? meta.error : undefined
                        }
                        id={input.name}
                        label={t('website')}
                        placeholder={t('website')}
                        tabIndex="1"
                        {...input}
                      />
                    )
                  }}
                </Field>
              </div>

              <div className="w-full mt-4 flex flex-row justify-end">
                <Button
                  className="mr-4"
                  label={t('cancel')}
                  onClick={requestClose}
                  testData="close-edit-company"
                  variant={buttonsVariants.TERTIARY}
                />
                <Button
                  disabled={loading}
                  label={t('save')}
                  testData="save-company-info"
                  type="submit"
                />
              </div>

              <AlertModal confirmClose={closeForm} />
            </form>
          )
        }}
        validate={(values) => {
          const commonFields = {
            name: validateRequiredField(values.name),
            officeCountry: validateRequiredField(values.officeCountry),
            officeStreet: validateRequiredField(values.officeStreet),
            officeStreet2: values.officeStreet2
              ? validateRequiredField(values.officeStreet)
              : undefined,
            officeCity: validateRequiredField(values.officeCity),
            officeState: validateRequiredField(values.officeState),
            officeZipCode:
              values.officeCountry === 'US'
                ? validateRequiredField(values.officeZipCode) ||
                  validateZipCode(values.officeZipCode)
                : validateRequiredField(values.officeZipCode),
            website: validateRequiredField(values.website) || validateWebsiteURL(values.website),
          }
          const billingAddressFields = values.isBillingAddressDifferentFromOfficeAddress
            ? {
                billingCountry: validateRequiredField(values.billingCountry),
                billingStreet: validateRequiredField(values.billingStreet),
                billingStreet2: values.billingStreet2
                  ? validateRequiredField(values.billingStreet)
                  : undefined,
                billingCity: validateRequiredField(values.billingCity),
                billingState: validateRequiredField(values.billingState),
                billingZipCode:
                  values.billingCountry === 'US'
                    ? validateRequiredField(values.billingZipCode) ||
                      validateZipCode(values.billingZipCode)
                    : validateRequiredField(values.billingZipCode),
              }
            : {}

          return {
            ...commonFields,
            ...billingAddressFields,
          }
        }}
      />
    </div>
  )
}

CompanyInformationEditForm.propTypes = {
  setMode: PT.func,
  vendor: PT.shape({
    billingAddress: PT.shape({
      country: PT.string,
      state: PT.string,
      street: PT.string,
      street2: PT.string,
      city: PT.string,
      zipCode: PT.string,
    }),
    id: PT.oneOfType([PT.string, PT.number]),
    officeAddress: PT.shape({
      country: PT.string,
      state: PT.string,
      street: PT.string,
      street2: PT.string,
      city: PT.string,
      zipCode: PT.string,
    }),
    name: PT.string,
    website: PT.string,
    supportEmail: PT.string,
    supportPhoneNumber: PT.string,
  }).isRequired,
}

export default CompanyInformationEditForm
