import { DateTime } from 'luxon'

export const DEFAULT_DATE_FORMAT = 'MM/dd/yyyy'
export const SERVER_DATE_FORMAT = 'yyyy-MM-dd'

export const getFormattedDate = (date, format = DEFAULT_DATE_FORMAT) => {
  if (!date) {
    return null
  }

  if (DateTime.fromISO(date).isValid) {
    return DateTime.fromISO(date).toFormat(format)
  }
  if (DateTime.fromJSDate(date).isValid) {
    return DateTime.fromJSDate(date).toFormat(format)
  }
  if (DateTime.fromFormat(date, SERVER_DATE_FORMAT).isValid) {
    return DateTime.fromFormat(date, SERVER_DATE_FORMAT).toFormat(format)
  }
  return date
}

export const getCurrentDate = (format = DEFAULT_DATE_FORMAT) => DateTime.now().toFormat(format)
