import { Fragment, useEffect } from 'react'
import { Transition } from '@headlessui/react'
import { XIcon } from '@heroicons/react/solid'
import { useNotifications } from '../../../hooks/useNotifications'

const Notification = () => {
  const { notifications, hideNotification } = useNotifications()
  const successNotification = notifications.success
  const errorNotification = notifications.error
  const backgroundMap = {
    successNotification: 'bg-green-50',
    errorNotification: 'bg-red-100',
  }
  const background = successNotification
    ? backgroundMap.successNotification
    : backgroundMap.errorNotification

  useEffect(() => {
    const timerId = setTimeout(() => {
      hideNotification()
    }, 4000)
    return () => {
      clearTimeout(timerId)
    }
  }, [successNotification, errorNotification])
  return (
    <>
      <div
        aria-live="assertive"
        className="fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start z-[1500]">
        <div className="w-full flex items-center space-y-4 sm:items-end justify-center">
          <Transition
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            show={!!successNotification || !!errorNotification}>
            <div
              className={`max-w-2xl w-full shadow-lg rounded-md pointer-events-auto overflow-hidden ${background}`}>
              <div className="p-4">
                <div className="flex items-center">
                  <div className="w-0 flex-1 flex justify-between">
                    {!!successNotification && (
                      <p className="w-0 flex-1 text-sm font-medium text-gray-900" key="1">
                        {successNotification}
                      </p>
                    )}
                    {!!errorNotification && (
                      <p className="w-0 flex-1 text-sm font-medium text-gray-900" key="2">
                        {errorNotification}
                      </p>
                    )}
                  </div>
                  <div className="ml-4 flex-shrink-0 flex">
                    <button
                      className="rounded-md inline-flex text-gray-400 hover:text-gray-500
                      focus:outline-none"
                      onClick={hideNotification}
                      testData="x-hide-notification">
                      <XIcon aria-hidden="true" className="h-5 w-5" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </div>
    </>
  )
}

export default Notification
