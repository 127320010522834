export const normalizePhone = (value) => {
  if (!value) return value
  const updatedValue = value.startsWith('+1') ? value.replace('+1', '') : value
  const onlyNums = updatedValue.replace(/[^\d]/g, '')
  if (onlyNums.length <= 3) return onlyNums
  if (onlyNums.length <= 7) return `(${onlyNums.slice(0, 3)}) ${onlyNums.slice(3, 7)}`
  return `(${onlyNums.slice(0, 3)}) ${onlyNums.slice(3, 6)}-${onlyNums.slice(6, 10)}`
}

export const PHONE_MASK = '(000) 000-0000'
