import React, { useState } from 'react'
import PT from 'prop-types'
import './FileUpload.scss'
import { Text } from '../../../ui-kit'
import { useTranslation } from 'react-i18next'

const FileUpload = ({ formData, fieldName }) => {
  const [selectedFile, setSelectedFile] = useState()
  const { t } = useTranslation()
  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0]
    formData.append(fieldName, event.target.files[0])
    setSelectedFile(selectedFile?.name)
  }

  return (
    <div class="file-input" className="mt-4">
      <input className="file" onChange={handleFileChange} type="file" />
      <Text>{selectedFile ? selectedFile : t('noFileSelected')}</Text>
    </div>
  )
}

FileUpload.propTypes = {
  formData: {
    append: PT.func,
  },
  fieldName: PT.string,
}
export default FileUpload
