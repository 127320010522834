import { useTranslation } from 'react-i18next'
import PT from 'prop-types'
import { InfoNote, Money, Text, Tooltip } from '../../../ui-kit'
import fontWeight from '../../../ui-kit/fontWeight'
import sizes from '../../../ui-kit/sizes'
import DividedList from '../../../ui-kit/components/sidebar/DividedList'
import FormattedDate from '../../../ui-kit/components/text/FormattedDate'
import React, { useState } from 'react'
import SidebarLabel from '../../../ui-kit/components/sidebar/SidebarLabel'
import StatusBadge from '../../../ui-kit/components/badges/StatusBadge'
import { getRefundTitle } from '../issueRefund/reasonList'
import { statusRefundMapping } from './util'
import MemoField from '../../../ui-kit/components/inputs/MemoField'

const RefundContent = ({ data }) => {
  const { t } = useTranslation()

  const descriptionData = [
    {
      label: t('status'),
      value: (
        <StatusBadge
          color={statusRefundMapping[data.clientFacingStatus]?.color}
          value={t(statusRefundMapping[data.clientFacingStatus]?.label)}
        />
      ),
    },
    {
      label: t('details'),
      value: (
        <Tooltip
          className="text-ellipsis overflow-hidden max-w-72 whitespace-nowrap text-sm"
          content={data.failureReason}>
          {data.failureReason}
        </Tooltip>
      ),
      hidden: !data.failureReason,
    },
    {
      label: t('createdOn'),
      value: <FormattedDate date={data.createdAt} format={'MM/dd/yyyy hh:mm:ss a'} />,
    },
    {
      label: t('createdBy'),
      value: `${data.createdBy?.firstName} ${data.createdBy?.lastName}${
        data.createdBy?.status === 'archived' ? ' (deleted)' : ''
      }`,
    },
    { label: t('reason'), value: getRefundTitle(data.reason) },
  ]
  const [memoFormIsDirty, setMemoFormIsDirty] = useState(false)
  const loading = false

  return (
    <div className="flex flex-col mt-[-1.5rem]">
      <div className="flex flex-col">
        <div className="flex flex-row items-center">
          <Text fontWeight={fontWeight.MEDIUM} size={sizes.XL2}>
            {t('refund')}
          </Text>
        </div>

        <div className="flex flex-row">
          <div className="mr-2">
            <InfoNote label={t('id')}>
              <Text fontWeight={fontWeight.MEDIUM} size={sizes.SM}>
                {data.id}
              </Text>
            </InfoNote>
          </div>
        </div>
      </div>

      <div className="flex flex-row mt-3">
        <div className="w-6/12 flex flex-col">
          <SidebarLabel text="refundAmount" />
          <Money className="text-2xl mt-1 font-medium" value={data.amountCents} />
        </div>
      </div>

      <DividedList content={descriptionData} />
      <MemoField
        closeForm={() => null}
        entityId={data.id}
        initialValue={data.memo}
        isFormDirty={memoFormIsDirty}
        isLoading={loading}
        responseEntityName={'updateInvoiceRefund'}
        setDirtyFormState={setMemoFormIsDirty}
        // updateEntity={updateInvoiceRefund}
        isDisabled
      />
    </div>
  )
}

RefundContent.propTypes = {
  data: PT.shape({
    id: PT.number,
    amountCents: PT.number,
    createdAt: PT.string,
    memo: PT.string,
    createdBy: PT.shape({
      firstName: PT.number,
      lastName: PT.number,
      status: PT.string,
    }),
    invoice: PT.object,
    project: PT.shape({
      name: PT.string,
    }),
    reason: PT.string,
    refundPayableInvoiceAssignments: PT.arrayOf(
      PT.shape({
        amountCents: PT.number,
        paymentMethod: PT.shape({
          title: PT.string,
        }),
        status: PT.string,
      }),
    ),
    clientFacingStatus: PT.string,
    failureReason: PT.string,
  }),
}

RefundContent.defaultProps = {
  data: {},
}

export default RefundContent
