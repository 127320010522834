import React, { useState } from 'react'
import { LoadingSpinner, Text } from '../../ui-kit'
import sizes from '../../ui-kit/sizes'
import { useTranslation } from 'react-i18next'
import CompanyInformationOverview from './companyInformation/CompanyInformationOverview'
import CompanyInformationEditForm from './companyInformation/CompanyInformationEditForm'
import AsideContainer from '../../components/containers/AsideContainer'
import { companyInformationModes } from './companyInformation/modes'
import ContactInformationEditForm from './companyInformation/ContactInformationEditForm'
import LogoEditSection from './LogoEditSection'
import { useCurrentVendor } from '../../hooks/useCurrentVendor'
import { normalizePhone } from '../../utils'

const CompanyInformation = () => {
  const { t } = useTranslation()
  const [mode, setMode] = useState(companyInformationModes.VIEW)
  const vendorData = useCurrentVendor()
  const displayVendorData = {
    ...vendorData,
    phoneNumber: normalizePhone(vendorData.phoneNumber),
  }

  const renderTitleChild = () => (
    <Text color="text-gray-500" size={sizes.SM}>
      {t('companyInformationDescription')}
    </Text>
  )
  return (
    <AsideContainer title={t('companyInformation')} titleChild={renderTitleChild()}>
      {!vendorData && <LoadingSpinner />}
      {vendorData && mode === companyInformationModes.VIEW && (
        <CompanyInformationOverview setMode={setMode} vendor={displayVendorData} />
      )}
      {vendorData && mode === companyInformationModes.EDIT_COMPANY_INFORMATION && (
        <CompanyInformationEditForm setMode={setMode} vendor={vendorData} />
      )}
      {vendorData && mode === companyInformationModes.EDIT_CONTACT_INFORMATION && (
        <ContactInformationEditForm setMode={setMode} vendor={displayVendorData} />
      )}
      {vendorData && <LogoEditSection logo={vendorData.logoUrl} vendorId={vendorData.id} />}
    </AsideContainer>
  )
}

export default CompanyInformation
