import React from 'react'
import PropTypes from 'prop-types'
import { Flex } from '../../ui-kit'
import SupplierInfo from './SupplierInfo'

const DesktopAnonymousLayout = ({ children }) => {
  return (
    <Flex className="min-h-screen max-h-screen w-screen">
      <Flex alignItems="center" className="w-2/5 px-[8%] pt-20 pb-20 bg-black-200" column>
        <SupplierInfo />
      </Flex>
      <Flex
        alignItems="center"
        className="w-3/5 bg-white px-10 py-10 overflow-auto min-w-[920px]"
        column>
        {children}
      </Flex>
    </Flex>
  )
}

DesktopAnonymousLayout.propTypes = {
  children: PropTypes.node,
}

export default DesktopAnonymousLayout
