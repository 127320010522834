import PropTypes from 'prop-types'
import React from 'react'
import Icon from '../icons/Icon'
import PT from 'prop-types'
import cx from 'classnames'
import colors from '../../colors'
import align from '../../align'
import sizes from '../../sizes'
import buttonsVariants from '../../buttonsVariants'
import { Link } from 'react-router-dom'

/**
 * @param {string} label - Label of button.
 * @param {string} size - Select size of button from "sizes.js" in ui-kit.
 * @param {string} iconName - Name of icon used in button.
 * @param {string} className - Specific classes for the button.
 * @param {string} iconType - Icon type "Solid" or "Outline".
 * @param {string} variant - (PRIMARY, SECONDARY, TERTIARY, FOURTH, LINK)
 *                            Chose one of variants of button from "buttonsVariants.js" in ui-kit.
 * @param {func} onClick - OnClick func.
 * @param {string} iconAlign - Align of Icon from "align.js" in ui-kit.
 * @param {boolean} fullWidth - The button will take up all the space of the parent component.
 * @param {boolean} disabled - Disable button.
 * @param {string} type - reset | submit.
 */

const Button = ({
  label,
  size,
  iconName,
  className,
  iconType,
  variant,
  onClick,
  iconAlign,
  fullWidth,
  disabled,
  testData,
  type,
  linkTo,
  contentClassName,
}) => {
  const sizeSelector = (size) => {
    if (size === sizes.XS) {
      return 'text-sm py-1.5 px-3'
    } else if (size === sizes.SM) {
      return 'text-sm py-2 px-3'
    } else if (size === sizes.BASE) {
      return 'text-sm px-5 py-2.5'
    } else if (size === sizes.LG) {
      return 'text-base py-2.5 px-5'
    } else if (size === sizes.XL) {
      return 'text-base px-6 py-3'
    }
  }

  const defaultClasses = 'font-medium rounded-md text-center focus:outline-none hover:no-underline'

  const buttonClassesSelector = (variant) => {
    if (disabled && variant !== 'link')
      return `${fullWidth && 'w-full'} bg-gray-300 
       text-gray-500
       cursor-not-allowed ${defaultClasses} ${sizeSelector(size)}`
    else if (variant === 'primary')
      return `${fullWidth && 'w-full'} text-white bg-orange-700 hover:bg-orange-800
      border border-white hover:text-white ${defaultClasses} ${sizeSelector(size)}`
    else if (variant === 'secondary')
      return `${fullWidth && 'w-full'} bg-white hover:bg-orange-200 
      text-gray-500 border border-orange-700 ${defaultClasses} ${sizeSelector(size)}`
    else if (variant === 'tertiary')
      return `${fullWidth && 'w-full'} bg-white hover:bg-gray-200 
      text-gray-500 hover:text-gray-500 border border-gray-400 ${defaultClasses} ${sizeSelector(
        size,
      )}`
    else if (variant === 'fourth')
      return `${fullWidth && 'w-full'} hover:bg-blue-50 
      text-blue-500 ${defaultClasses} ${sizeSelector(size)}`
    else if (variant === 'link')
      return `w-max ${
        disabled ? 'text-gray-500' : 'text-blue-500'
      } font-medium !p-0 outline-none ${sizeSelector(size)} ${disabled ? 'cursor-not-allowed' : ''}`
    else if (variant === 'primary_link')
      return `w-max text-primary-700 font-medium !p-0 outline-none ${sizeSelector(size)}`
  }

  const iconColorSelector = (variant) => {
    if (variant === 'primary') return colors.WHITE
    else if (variant === 'secondary') return colors.PRIMARY
    else if (variant === 'tertiary') return colors.GREY_700
    else if (variant === 'fourth') return colors.BLUE
    else if (variant === 'link') return colors.BLUE
  }
  const Element = linkTo ? Link : 'button'
  const handleClick = (e) => {
    onClick?.(e)
  }

  return (
    <Element
      className={`${buttonClassesSelector(variant)} ${className}`}
      disabled={disabled}
      onClick={handleClick}
      testData={testData}
      to={linkTo}
      type={type}>
      <div
        className={cx(
          'flex items-center justify-center',
          iconAlign === align.RIGHT ? 'flex-row-reverse' : 'flex-row',
          contentClassName,
        )}>
        {iconName && (
          <Icon
            className={iconAlign === align.RIGHT ? 'ml-[0.625rem]' : 'mr-[0.625rem]'}
            color={iconColorSelector(variant)}
            name={iconName}
            size={size}
            type={iconType}
          />
        )}

        {label}
      </div>
    </Element>
  )
}

Button.propTypes = {
  iconName: PropTypes.string,
  type: PropTypes.string,
  className: PropTypes.string,
  onClick: PT.func,
  fullWidth: PT.bool,
  disabled: PT.bool,
  iconType: PropTypes.string,
  iconAlign: PropTypes.string,
  label: PropTypes.string.isRequired,
  size: PropTypes.string,
  variant: PropTypes.string.isRequired,
  linkTo: PropTypes.string,
  testData: PropTypes.string,
  contentClassName: PropTypes.string,
}

Button.defaultProps = {
  variant: buttonsVariants.PRIMARY,
  size: sizes.BASE,
  type: 'button',
}

export default Button
