import MobileDetected from 'mobile-detect'

export function getBrowserInfo() {
  const md = new MobileDetected(navigator.userAgent)
  const isMobile = !!((md.phone() || md.tablet() || md.mobile()) && md.isPhoneSized())
  const remSize = parseFloat(getComputedStyle(document.documentElement).fontSize)

  return {
    remSize,
    isMobile,
  }
}
