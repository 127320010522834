export const createPaymentMethodTabs = {
  CARD: 'card',
  ACH: 'ach',
}

export const paymentResultMods = {
  LOADING: 'LOADING',
  ACH_SUCCESS: 'ACH_SUCCESS',
  ALREADY_PAID: 'ALREADY_PAID',
  SERVER_ERROR: 'SERVER_ERROR',
  CREDIT_CARD_SUCCESS: 'CREDIT_CARD_SUCCESS',
  CREDIT_CARD_FAILURE: 'CREDIT_CARD_FAILURE',
  ACH_FAILURE: 'ACH_FAILURE',
}

export const paymentMethodTypes = {
  ACH_PAYMENT_METHOD: 'AchPaymentMethod',
  PAYCHECK_PAYMENT_METHOD: 'PaycheckPaymentMethod',
  CREDIT_CARD_PAYMENT_METHOD: 'CreditCardPaymentMethod',
}
