import { DateTime } from 'luxon'
import { SERVER_DATE_FORMAT } from '../../../../ui-kit/utils/dateUtils'
import { getFromAvailableFilters } from './getFromAvailableFilters'
import { filterChange } from './filterChange'
import { checkIfConvert } from './checkIfConvert'

export const parseCurrentUserFilters = (filterOptions, t, updateFilterList) => {
  if (!t || !updateFilterList) {
    return
  }

  const newFilterList = filterOptions.map((filter) => {
    return {
      key: filter.value,
      label: filter.label,
      type: filter.type,
      options: filter.filterOptions?.map((item) => ({
        value: item.key,
        label: t(item.title),
      })),
      dataType: filter.dataType,
      loadOptions: filter.loadOptions,
    }
  })
  updateFilterList(newFilterList)
}

export const getAvailableFilterOptions = (availableFilters, columns) => {
  if (!availableFilters || !columns) {
    return []
  }

  return columns.reduce((acc, column) => {
    if (column.filterId) {
      const filter = getFromAvailableFilters(availableFilters, column.filterId)
      filter &&
        acc.push({
          label: column.filterTitle || column.headerName,
          value: column.filterId,
          type: column.filterType || filter?.type,
          dataType: column.filterDataType,
          filterOptions: column.filterOptions || filter?.options,
          loadOptions: column.loadOptions,
        })
    }
    return acc
  }, [])
}

export const filterValueChange = (
  value,
  key,
  isApplyOnBlur,
  selectedFilter,
  setSelectedFilters,
  setQueryParams,
  refs,
) => {
  const valueArray = value.split(',').map((val) => val.trim())
  filterChange(
    valueArray,
    key,
    isApplyOnBlur,
    selectedFilter,
    setSelectedFilters,
    setQueryParams,
    refs,
  )
}

export const datesFilterChange = (
  dates,
  key,
  selectedFilter,
  setSelectedFilters,
  setQueryParams,
  refs,
) => {
  if (!Array.isArray(dates)) {
    return
  }

  const formattedDates = dates
    .map((date) => (date && DateTime.fromJSDate(date).toFormat(SERVER_DATE_FORMAT)) || '')
    .filter(Boolean)

  filterChange(formattedDates, key, false, selectedFilter, setSelectedFilters, setQueryParams, refs)
}

export const convertToCents = (value, filter) => {
  return checkIfConvert(value, filter) ? value * 100 : value
}

export const convertToDollar = (value, filter) => {
  return checkIfConvert(value, filter) ? value / 100 : value
}

export const autocompleteValuesChange = (
  values,
  key,
  selectedFilter,
  setSelectedFilters,
  setQueryParams,
  refs,
  selectedOptions,
  setSelectedOptions,
) => {
  const valuesArray = values?.map((item) => item.value) || []
  setSelectedOptions?.({ ...selectedOptions, [key]: values })
  filterChange(valuesArray, key, false, selectedFilter, setSelectedFilters, setQueryParams, refs)
}
