import React from 'react'
import FormattedDate from '../../../ui-kit/components/text/FormattedDate'
import { Tooltip } from '../../../ui-kit'

export const getCustomerPaymentsFiltersColumns = (t) => [
  {
    filterId: 'date',
    filterTitle: t('paymentDate'),
  },
  {
    filterId: 'paymentMethodType',
    filterTitle: t('paymentMethod'),
  },
  {
    filterId: 'location',
    filterTitle: t('location'),
  },
  {
    filterId: 'status',
    filterTitle: t('paymentStatus'),
  },
]

export const getCustomerPaymentsColumns = (t) => [
  {
    field: 'createdAt',
    headerName: t('date'),
    renderCell: (values) => <FormattedDate date={values?.row?.createdAt} />,
    flex: 10,
  },
  {
    field: 'id',
    headerName: t('id'),
    flex: 10,
  },
  {
    field: 'accountNumber',
    headerName: t('accountNo'),
    flex: 10,
  },
  {
    field: 'companyName',
    headerName: t('accountName'),
    renderCell: (values) => (
      <Tooltip content={values?.row?.companyName}>{values?.row?.companyName}</Tooltip>
    ),
    flex: 10,
  },
  {
    field: 'payeeName',
    headerName: t('createdBy'),
    renderCell: (values) => values?.row?.payeeName,
    flex: 10,
  },
  {
    field: 'location',
    headerName: t('location'),
    renderCell: (values) => values?.row?.locationName,
    flex: 8,
  },
  {
    field: 'amountCents',
    headerName: t('amount'),
    flex: 8,
    renderCell: (values) => values?.row?.amount,
    align: 'right',
    headerAlign: 'right',
  },
  {
    field: 'feeAmountCents',
    headerName: t('ccFee'),
    flex: 8,
    renderCell: (values) => values?.row?.feeAmount,
    align: 'right',
    headerAlign: 'right',
    sortable: false,
  },
  {
    field: 'chargedAmountCents',
    headerName: t('charged'),
    flex: 9,
    renderCell: (values) => values?.row?.chargedAmount,
    align: 'right',
    headerAlign: 'right',
  },
  {
    field: 'paymentMethod',
    headerName: t('paymentMethod'),
    flex: 12,
    renderCell: (values) => values?.row?.paymentMethodTitle,
  },
  {
    field: 'status',
    headerName: t('status'),
    flex: 10,
    renderCell: (values) => t(values?.row?.status),
  },
  {
    field: 'memo',
    headerName: t('memo'),
    flex: 10,
    sortable: false,
    renderCell: (values) => (
      <span className="text-ellipsis overflow-hidden">{values?.row?.memo}</span>
    ),
  },
]

export const getCustomerPaymentsTotalItems = (total, numberOfPayments = 0) => [
  { captionKey: 'numberOfPayments', value: numberOfPayments, isPlainText: true },
  { captionKey: 'amount', value: total?.amount, isPlainText: true },
  { captionKey: 'ccFee', value: total?.feeAmount, isPlainText: true },
  { captionKey: 'charged', value: total?.chargedAmount, isPlainText: true },
]

export const getRefundsFiltersColumns = (t) => [
  {
    filterId: 'date',
    filterTitle: t('refundDate'),
  },
  {
    filterId: 'paymentMethodType',
    filterTitle: t('paymentMethod'),
  },
  {
    filterId: 'location',
    filterTitle: t('location'),
  },
  {
    filterId: 'status',
    filterTitle: t('paymentStatus'),
  },
]

export const getRefundsColumns = (t) => [
  {
    field: 'createdAt',
    headerName: t('refundDate'),
    renderCell: (values) => <FormattedDate date={values?.row?.createdAt} />,
    flex: 10,
  },
  {
    field: 'id',
    headerName: t('refundId'),
    flex: 10,
  },
  {
    field: 'paymentId',
    headerName: t('paymentId'),
    renderCell: (values) => values?.row?.paymentTransaction?.id,
    flex: 10,
  },
  {
    field: 'accountNumber',
    headerName: t('accountNo'),
    flex: 10,
  },
  {
    field: 'companyName',
    headerName: t('accountName'),
    renderCell: (values) => (
      <Tooltip content={values?.row?.companyName}>{values?.row?.companyName}</Tooltip>
    ),
    flex: 10,
  },
  {
    field: 'createdBy',
    headerName: t('createdBy'),
    renderCell: (values) => values?.row?.createdBy,
    flex: 10,
  },
  {
    field: 'amountCents',
    headerName: t('refundAmount'),
    flex: 8,
    renderCell: (values) => values?.row?.amount,
    align: 'right',
    headerAlign: 'right',
  },
  {
    field: 'paymentMethod',
    headerName: t('paymentMethod'),
    flex: 12,
    renderCell: (values) => values?.row?.paymentMethodTitle,
  },
  {
    field: 'status',
    headerName: t('status'),
    flex: 10,
    renderCell: (values) => t(values?.row?.status),
  },
  {
    field: 'memo',
    headerName: t('memo'),
    flex: 10,
    sortable: false,
    renderCell: (values) => (
      <span className="text-ellipsis overflow-hidden">{values?.row?.memo}</span>
    ),
  },
]

export const getRefundsTotalItems = (total) => [
  { captionKey: 'refundAmount', value: total?.amount, isPlainText: true },
]
