import { useQuery } from '@tanstack/react-query'
import { useRollbar } from '@rollbar/react'
import { captureCritical } from '../utils/rollbar'
import { useNotifications } from './useNotifications'
import { useDispatch } from 'react-redux'
import { parseRestError } from '../utils/parseRestError'

const useCustomQuery = ({ queryOptions = {}, onCompleted, onFailed, rollbarOptions = {} }) => {
  const rollbar = useRollbar()
  const dispatch = useDispatch()
  const { newNotification } = useNotifications()

  const {
    data,
    isLoading: loading,
    refetch,
    remove,
  } = useQuery({
    retry: false,
    refetchOnWindowFocus: false,
    ...queryOptions,
    onSuccess: (response) => {
      if (!response) {
        return
      }

      try {
        onCompleted?.(response)
      } catch (error) {
        captureCritical(rollbar, error, { target: 'useCustomQuery onSuccess', ...rollbarOptions })
      }
    },
    onError: (error) => {
      parseRestError({
        key: 'useCustomQuery',
        error,
        rollbar,
        dispatch,
        onFailed,
        rollbarOptions,
        newNotification,
      })
    },
  })

  return { data, loading, refetch, remove }
}

export { useCustomQuery }
