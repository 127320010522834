import React from 'react'
import { validateBankRoutingNumber, validateRequiredField } from '../../utils/validators'
import { Field } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { Flex, Icon, Input, Tooltip } from '../../ui-kit'
import RadioGroup from '../../ui-kit/components/inputs/RadioGroup'

export const extendWithAchDefaultValues = (achData) => {
  return {
    checkType: 'personal',
    accountType: 'checking',
    ...achData,
  }
}
export const validateBankAccountFields = (values) => ({
  routingNumber:
    validateRequiredField(values.routingNumber) || validateBankRoutingNumber(values.routingNumber),
  accountNumber: validateRequiredField(values.accountNumber),
  accountHolderName: validateRequiredField(values.accountHolderName),
})

const BankTransferForm = () => {
  const { t } = useTranslation()

  return (
    <Flex className="mt-5" column>
      <Flex alignItems="center">
        <Field name="achPaymentMethod.accountHolderName">
          {({ input, meta }) => (
            <Input
              className="mr-2.5 w-full"
              errorMessage={
                meta.error && meta.touched && meta.submitFailed ? meta.error : undefined
              }
              id={input.name}
              placeholder={t(input.name.split('.')[1])}
              {...input}
            />
          )}
        </Field>
        <Tooltip content={t('accountHolderNameTooltip')}>
          <Icon className="w-8" color="text-warmBlack-400" name="questionMarkCircleIcon" />
        </Tooltip>
      </Flex>
      <Flex alignItems="center" className="mt-4">
        <Field name="achPaymentMethod.routingNumber">
          {({ input, meta }) => (
            <Input
              className="mr-2.5 w-full"
              errorMessage={
                meta.error && meta.touched && meta.submitFailed ? meta.error : undefined
              }
              id={input.name}
              maxLength={9}
              placeholder={t(input.name.split('.')[1])}
              {...input}
            />
          )}
        </Field>
        <Tooltip content={t('bankRoutingNumberTooltip')}>
          <Icon className="w-8" color="text-warmBlack-400" name="questionMarkCircleIcon" />
        </Tooltip>{' '}
      </Flex>
      <Flex alignItems="center" className="mt-4">
        <Field name="achPaymentMethod.accountNumber">
          {({ input, meta }) => (
            <Input
              className="mr-2.5 w-full"
              errorMessage={
                meta.error && meta.touched && meta.submitFailed ? meta.error : undefined
              }
              id={input.name}
              maxLength={30}
              placeholder={t(input.name.split('.')[1])}
              {...input}
            />
          )}
        </Field>
        <Tooltip content={t('bankAccountNumberTooltip')}>
          <Icon className="w-8" color="text-warmBlack-400" name="questionMarkCircleIcon" />
        </Tooltip>{' '}
      </Flex>

      <Field name="achPaymentMethod.accountType">
        {({ input }) => (
          <RadioGroup
            defaultCheckedValue={input.value}
            itemClassName={'w-1/2'}
            itemMargin={'ml-16'}
            name={input.name}
            onValueChange={input.onChange}
            options={[
              { id: 'checking', label: t('checking'), value: 'checking' },
              {
                id: 'savings',
                label: t('savings'),
                value: 'savings',
              },
            ]}
            wrapperClassName={'flex items-center pl-5 pr-[60px] mt-5'}
          />
        )}
      </Field>
      <Field name="achPaymentMethod.checkType">
        {({ input }) => (
          <RadioGroup
            defaultCheckedValue={input.value}
            itemClassName={'w-1/2'}
            itemMargin={'ml-16'}
            name={input.name}
            onValueChange={input.onChange}
            options={[
              { id: 'personal', label: t('personal'), value: 'personal' },
              {
                id: 'business',
                label: t('business'),
                value: 'business',
              },
            ]}
            wrapperClassName={'flex items-center pl-5 pr-[60px] mt-5'}
          />
        )}
      </Field>
    </Flex>
  )
}

BankTransferForm.propTypes = {}

export default BankTransferForm
