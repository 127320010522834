import React, { useEffect, useState } from 'react'
import { useParams, Outlet } from 'react-router-dom'
import { Route, Routes } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import PT from 'prop-types'
import { setVendorData } from '../../store/actions/auth'
import NotFound from '../NotFound'
import { LoadingSpinner } from '../../ui-kit'
import axios from 'axios'
import { useCustomQuery } from '../../hooks/useCustomQuery'
import { PUBLIC_VENDOR_INFO_KEY } from '../../constants/queryKeys'

const VendorPropsHoc = () => {
  const { vendorName } = useParams()
  const vendorNameLowerCase = vendorName ? vendorName.toLowerCase() : ''
  const dispatch = useDispatch()
  const [isReady, setIsReady] = useState(false)

  const { loading } = useCustomQuery({
    onFailed: () => {
      window.location.replace('https://gosuppli.com')
      setIsReady(true)
    },
    onCompleted: (response) => {
      if (response?.name) {
        document.title = `Suppli - ${response.name}`
      }

      response && dispatch(setVendorData(response))
      setIsReady(true)
    },
    queryOptions: {
      enabled: !!vendorNameLowerCase,
      queryKey: [PUBLIC_VENDOR_INFO_KEY],
      queryFn: () => axios.get(`/${vendorNameLowerCase}/guest/vendor`),
    },
    rollbarOptions: { operationName: 'public_vendor_info', target: 'VendorPropsHoc' },
  })

  useEffect(() => {
    dispatch(setVendorData({}))
  }, [])

  return (
    <>
      {!loading && isReady && <Outlet />}
      {(loading || !isReady) && <LoadingSpinner className="w-full h-full" loading />}
    </>
  )
}
const VendorSlugRoute = ({ children }) => {
  return (
    <Routes>
      <Route element={<NotFound />} path="*" />
      <Route element={<VendorPropsHoc />}>
        <Route element={<>{children}</>} path={'/:vendorName/*'} />
      </Route>
    </Routes>
  )
}

export default VendorSlugRoute
VendorSlugRoute.propTypes = {
  children: PT.node,
}
