import axios from 'axios'

export const useRequest = async ({
  url,
  method,
  data,
  successCallback,
  errorCallback,
  navigate,
}) => {
  try {
    const response = await axios[method](`${url}`, data).catch()
    successCallback && successCallback(response)
    return { data: response }
  } catch (error) {
    if (error?.response?.status === 404) {
      return navigate('/')
    } else {
      errorCallback && errorCallback(error?.response?.data?.errors)
    }
    return { error: error?.response }
  }
}
