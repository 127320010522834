export const paymentsReportsListMap = {
  CUSTOMER_PAYMENTS: 'customerPayments',
  REFUNDS: 'refunds',
}

export const paymentsReports = {
  [paymentsReportsListMap.CUSTOMER_PAYMENTS]: {
    csvUrl: '/reports/payment_transaction_report.csv',
    jsonUrl: '/reports/payment_transaction_report.json',
    xlsxUrl: '/reports/payment_transaction_report.xlsx',
    fileNameKey: paymentsReportsListMap.CUSTOMER_PAYMENTS,
  },
  [paymentsReportsListMap.REFUNDS]: {
    csvUrl: '/reports/refund_transaction_report.csv',
    jsonUrl: '/reports/refund_transaction_report.json',
    xlsxUrl: '/reports/refund_transaction_report.xlsx',
    fileNameKey: paymentsReportsListMap.REFUNDS,
  },
}
