import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useRollbar } from '@rollbar/react'
import { captureCritical } from '../utils/rollbar'
import { useNotifications } from './useNotifications'
import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { parseRestError } from '../utils/parseRestError'

export const UPDATE_OPERATIONS = {
  ADD: 'ADD',
  UPDATE: 'UPDATE',
  DELETE: 'DELETE',
}

const useCustomMutation = ({
  onFailed,
  onCompleted,
  rollbarOptions = {},
  mutationOptions = {},
  customErrorHandling,
}) => {
  const rollbar = useRollbar()
  const dispatch = useDispatch()
  const queryClient = useQueryClient()
  const { newNotification } = useNotifications()

  const { mutateAsync: mutate, isLoading: loading } = useMutation({
    ...mutationOptions,
    onSuccess: (response, variables) => {
      try {
        onCompleted?.(response, variables)
      } catch (error) {
        captureCritical(rollbar, error, {
          target: 'useCustomMutation onSuccess',
          ...rollbarOptions,
        })
      }
    },
    onError: (error) => {
      if (customErrorHandling) {
        try {
          customErrorHandling?.(error)
        } catch (error) {
          captureCritical(rollbar, error, {
            target: 'useCustomMutation onSuccess',
            ...rollbarOptions,
          })
        }
      } else {
        parseRestError({
          key: 'useCustomMutation',
          error,
          rollbar,
          dispatch,
          onFailed,
          rollbarOptions,
          newNotification,
        })
      }
    },
  })

  const updateQuery = useCallback(
    ({ queryKey, record, operation = UPDATE_OPERATIONS.UPDATE }) => {
      queryClient.setQueryData(queryKey, (oldData) => {
        if (!oldData) {
          return
        }

        if (Array.isArray(oldData.data)) {
          let updatedRecords = []

          switch (operation) {
            case UPDATE_OPERATIONS.ADD:
              updatedRecords = [record, ...oldData.data]
              break
            case UPDATE_OPERATIONS.DELETE:
              updatedRecords = oldData.data.filter((item) => item.id !== record.id)
              break
            case UPDATE_OPERATIONS.UPDATE:
              updatedRecords = oldData.data.map((item) => (item.id === record.id ? record : item))
              break
          }

          return {
            ...oldData,
            data: updatedRecords,
          }
        } else if (typeof oldData === 'object') {
          return {
            ...oldData,
            ...record,
          }
        }
      })
    },
    [queryClient],
  )

  return [mutate, { loading }, updateQuery]
}

export { useCustomMutation }
