import React, { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import PropTypes from 'prop-types'
import PT from 'prop-types'
import MenuItem from './MenuItem'

const MenuDropdown = ({ options, children, className, alignment }) => {
  const ITEM_HEIGHT = 48

  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  const handleClose = () => {
    setAnchorEl(null)
  }
  const topAlignmentClasses = alignment === 'top' ? '-top-2 transform -translate-y-full mr-2' : ''

  return (
    <Menu
      MenuListProps={{
        'aria-labelledby': 'long-button',
      }}
      PaperProps={{
        style: {
          maxHeight: ITEM_HEIGHT * options.length,
          width: 'fit-content',
          borderRadius: '6px',
          boxShadow:
            '0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05), ' +
            '0px 0px 0px 1px rgba(0, 0, 0, 0.05)',
          padding: '0',
        },
      }}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      as="div"
      className={className}
      id="long-menu"
      onClose={handleClose}
      open={open}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}>
      <Menu.Button className="focus:outline-none w-full">{children}</Menu.Button>

      <Transition
        as={Fragment}
        enter="transition ease-out"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95">
        <Menu.Items
          className={`${topAlignmentClasses} origin-top-right
          focus:ring-orange-100
        absolute right-0 mt-2 w-56 rounded-md
        shadow-lg bg-white ring-black ring-opacity-5 focus:outline-none z-50`}>
          <div className="py-1">
            {options.map((option) => (
              <MenuItem key={option.label} option={option} />
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  )
}

export default MenuDropdown

MenuDropdown.propTypes = {
  options: PropTypes.arrayOf(
    PT.shape({
      label: PT.string,
      href: PT.string,
      onClick: PT.func,
    }),
  ),
  children: PropTypes.element,
  className: PropTypes.string,
  alignment: PropTypes.string,
}
