import React from 'react'
import { Button, Flex, Input } from '../../../ui-kit'
import { useTranslation } from 'react-i18next'
import { Field, Form } from 'react-final-form'
import PT from 'prop-types'
import { validatePhoneNumber, validateRequiredField } from '../../../utils/validators'
import { normalizePhone } from '../../../utils'
import { PHONE_MASK } from '../../../utils/normalizePhoneNumber'

const TextForm = ({ handleSubmitForm }) => {
  const { t } = useTranslation()
  const initialValues = {
    phone: '',
  }

  return (
    <Form
      initialValues={initialValues}
      onSubmit={handleSubmitForm}
      render={({ handleSubmit }) => {
        return (
          <form className="flex flex-col py-0 px-2" onSubmit={handleSubmit}>
            <Flex alignItems="end" className="w-full mr-4 lex-row">
              <Field name="phone" parse={normalizePhone}>
                {({ input, meta }) => {
                  return (
                    <Input
                      className="w-72 mb-1"
                      errorMessage={
                        meta.error && meta.touched && meta.submitFailed ? meta.error : undefined
                      }
                      id={input.name}
                      label={t('addMobilePhone')}
                      placeholder={PHONE_MASK}
                      {...input}
                    />
                  )
                }}
              </Field>
              <Button
                className="ml-2 mb-1"
                label={t('add')}
                testData="add-text-guest"
                type="submit"
              />
            </Flex>
          </form>
        )
      }}
      validate={(values) => ({
        phone: validateRequiredField(values.phone) || validatePhoneNumber(values.phone),
      })}
    />
  )
}

TextForm.propTypes = {
  handleSubmitForm: PT.func,
}

export default TextForm
