import axios from 'axios'
import { merge } from 'lodash'
import { camelizeKeys } from 'humps'
import appConfig from './appConfig'
import { camelToSnake } from '../utils/utils'
import { updateAuthToken } from '../store/actions/auth'
import store from '../store'

export const getAuthToken = () => {
  const redux = localStorage.getItem('redux')

  if (redux) {
    const reduxData = JSON.parse(redux)

    return reduxData.auth.tokens.auth
  }
}

const interceptRequests = () => {
  axios.defaults.baseURL = appConfig.API_URL
  axios.interceptors.request.use(
    (config) => {
      if (!config.isFromData) {
        config.data = camelToSnake(config.data)
      }
      return merge({}, config, {
        headers: merge(config.headers, {
          Authorization: config.headers.Authorization || getAuthToken() || null,
        }),
      })
    },

    (error) => {
      console.error('API Request Error: --------------------- v')
      return Promise.reject(error)
    },
  )
}

const interceptResponses = () => {
  axios.interceptors.response.use((response) => {
    if (response.headers.authorization) {
      store.dispatch(updateAuthToken(response.headers.authorization))
    }
    return response.data instanceof Blob ? response.data : camelizeKeys(response.data)
  })
}

const enableAxiosInterceptors = () => {
  interceptRequests()
  interceptResponses()
}

export default enableAxiosInterceptors
