import { snakeCase, toInteger } from 'lodash'
import { DateTime } from 'luxon'
import { applyUserSignOut } from '../store/actions/auth'
import axios from 'axios'
import { SERVER_DATE_FORMAT } from '../ui-kit/utils/dateUtils'

export const MAX_NUMBER = 2147483647

export const getPaginationData = (model) => {
  const paginationData = model?.pagination
  const totalCount = paginationData?.totalCount || 0
  const totalPages = paginationData?.totalPages || 0
  const page = paginationData?.currentPage || 0
  const pageSize = paginationData?.items || 0
  let to = page * pageSize

  if (to > totalCount) {
    to = totalCount
  }

  let from = 0

  if (totalCount === 0) {
    from = 0
  } else {
    from = page * pageSize - pageSize + 1
  }

  return { totalCount, totalPages, from, to }
}

export const getModelsByIds = (array = [], ids = []) => {
  return array.filter((item) => ids.indexOf(item.id) > -1)
}

export const camelToSnake = (obj) => {
  const newObj = {}
  for (let key in obj) {
    let newKey = key.replace(/([A-Z])/g, '_$1').toLowerCase()
    newObj[newKey] =
      typeof obj[key] === 'object' && !Array.isArray(obj[key]) ? camelToSnake(obj[key]) : obj[key]
  }
  return newObj
}

export const getSumByField = (array = [], fieldName) => {
  return array.reduce((sum, item) => {
    if (item[fieldName] && !isNaN(Number.parseFloat(item[fieldName]))) {
      return sum + +item[fieldName]
    } else {
      return sum
    }
  }, 0)
}

export const getTableParams = (queryParams) => {
  const sort = queryParams.sort || 'payment_date.desc'
  const page = toInteger(queryParams.page) || 1
  const search = queryParams.search || null
  return { sort, page, search }
}

export const excludeErrorMsg = (error) => {
  if (Array.isArray(error) && error.length) {
    return error.map((error) => error.message).join(', ')
  }
}

export const handleError = (error, notification) => {
  if (Array.isArray(error) && error.length) {
    notification({ error: excludeErrorMsg(error) })
  } else if (error?.message) {
    notification({ error: error?.message })
  }
}

export const parseToFinanceFormat = (value, allFractionDigits = false) => {
  if ((!value && value !== 0) || isNaN(Number.parseFloat(value))) {
    return ''
  }

  const number = Number.parseFloat(value)

  return allFractionDigits ? number.toString() : number.toFixed(2)
}

export const getActiveTab = (location, mainPath) => {
  const pathArray = location.pathname.split('/')
  const indexInvoice = pathArray.indexOf(mainPath)
  return pathArray[indexInvoice + 1]
}

export const getTabsMap = (tabs, activeTab, handleTabClick, t, counters) =>
  tabs.map((tab, i) => ({
    label: t(tab),
    onClick: () => handleTabClick(tab),
    active: activeTab === tab,
    counter: counters?.[i],
  }))

export const getNumberWithCommas = (number) => {
  if (Number.isNaN(Number(number))) return null

  return number && number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export const transformCentsToDollars = (number) => {
  return (number || 0) / 100
}

export const checkOnUniqueElement = (values) => {
  const set = new Set(values)
  return set.size > 1
}

export const parseFilterQuery = (url) => {
  if (url) {
    const filters = url.split('&&')
    return filters
      .map((filter) => {
        const [key, stringValues] = filter.split('.')
        const values = stringValues.split('||')
        if (key === 'actionItem') {
          return values.map((value) => ({
            key: value,
            values: ['true'],
          }))
        }
        return {
          key: snakeCase(key),
          values,
        }
      })
      .flat()
  }
  return []
}

export const parseDisplayFilterQuery = (url) => {
  if (url) {
    const filters = url.split('&&')
    return filters.map((filter) => {
      const [key, stringValues] = filter.split('.')
      const values = stringValues.split('||')
      return {
        key: snakeCase(key),
        values,
      }
    })
  }
  return []
}

export const getDateToDatePicker = (date) => {
  return (date && DateTime.fromFormat(date, SERVER_DATE_FORMAT).toJSDate()) || ''
}

export const reformatDate = (date, formatFrom, formatTo) => {
  return (date && DateTime.fromFormat(date, formatFrom).toFormat(formatTo)) || ''
}

export const getCreditsTableParams = (queryParams) => {
  const sort = queryParams.sort || 'created_at.desc'
  const page = toInteger(queryParams.page) || 1
  const search = queryParams.search || null
  return { sort, page, search }
}

export const makeOptions = (arr) => {
  if (arr?.every((el) => typeof el === 'string' || typeof el === 'number')) {
    return arr.map((el) => ({
      label: el,
      value: el,
      name: el,
    }))
  } else return null
}

export const statusDisputeMapping = {
  create: { label: 'disputed', color: 'red' },
  resolve: { label: 'resolved', color: 'green' },
}

export const getOnlyNumbers = (value) => {
  if (typeof value === 'string') {
    return value.replace(/[^\d]/g, '')
  }
}

export const writeTextToClipboard = async (text) => {
  if (!text) {
    return false
  }

  try {
    await navigator.clipboard.writeText(text)
    return true
  } catch (err) {
    console.warn(err)
    return false
  }
}

export const appendFormData = (formData, data, parentKey) => {
  if (data && typeof data === 'object' && !(data instanceof File)) {
    Object.keys(data).forEach((key) => {
      appendFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key)
    })
  } else {
    const value = data === null ? '' : data
    formData.append(parentKey, value)
  }
}

export const logoutUser = ({ dispatch, navigate, authToken, vendorSlug, navigationUrl }) => {
  if (!dispatch || !navigate) {
    return
  }

  dispatch(applyUserSignOut())
  navigationUrl && navigate(navigationUrl)

  if (!authToken || !vendorSlug) {
    return
  }

  axios.delete(`${vendorSlug}/auth/sign_out`, {
    headers: {
      Authorization: authToken,
    },
  })
}

export const filterNullableValues = (obj) =>
  obj
    ? Object.entries(obj).reduce((acc, [key, value]) => {
        if (value !== null) {
          acc[key] = value
        }
        return acc
      }, {})
    : obj
