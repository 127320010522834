import React from 'react'
import { Button, Flex, Input } from '../../../ui-kit'
import { useTranslation } from 'react-i18next'
import { Field, Form } from 'react-final-form'
import PT from 'prop-types'
import { validateEmail, validateRequiredField } from '../../../utils/validators'

const EmailForm = ({ handleSubmitForm }) => {
  const { t } = useTranslation()
  const initialValues = {
    email: '',
  }

  return (
    <Form
      initialValues={initialValues}
      onSubmit={handleSubmitForm}
      render={({ handleSubmit }) => {
        return (
          <form className="flex flex-col py-0 px-2" onSubmit={handleSubmit}>
            <Flex alignItems="end" className="w-full mr-4 lex-row">
              <Field name="email">
                {({ input, meta }) => {
                  return (
                    <Input
                      className="w-72 mb-1"
                      errorMessage={
                        meta.error && meta.touched && meta.submitFailed ? meta.error : undefined
                      }
                      id={input.name}
                      label={t('addEmailAddress')}
                      placeholder={t('emailAddress')}
                      type="email"
                      {...input}
                    />
                  )
                }}
              </Field>
              <Button
                className="ml-2 mb-1"
                label={t('add')}
                testData="add-guest-email"
                type="submit"
              />
            </Flex>
          </form>
        )
      }}
      validate={(values) => ({
        email: validateRequiredField(values.email) || validateEmail(values.email),
      })}
    />
  )
}

EmailForm.propTypes = {
  handleSubmitForm: PT.func,
}

export default EmailForm
