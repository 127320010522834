import { membershipRoles } from './pages/settings/teamManagement/membershipRoles'

const getNavigationMap = (membershipRole) => [
  {
    name: 'payments',
    href: '/payments',
    icon: 'home',
    root: true,
  },
  {
    name: 'reports',
    href: '/reports',
    icon: 'chartBar',
  },
  {
    name: 'settings',
    href: '/settings',
    icon: 'adjustments',
    hidden: membershipRole !== membershipRoles.ADMIN,
  },
]

export default getNavigationMap
