import React, { useEffect, useState } from 'react'
import { Input, Text } from '../../ui-kit'
import sizes from '../../ui-kit/sizes'
import colors from '../../ui-kit/colors'
import fontWeight from '../../ui-kit/fontWeight'
import { Form, Field } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { validateEmail, validatePhoneNumber, validateRequiredField } from '../../utils/validators'
import { useBreadcrumbs } from '../../hooks/useBreadcrumbs'
import { useCurrentUser } from '../../hooks/useCurrentUser'
import Button from '../../ui-kit/components/buttons/Button'
import buttonsVariants from '../../ui-kit/buttonsVariants'
import { denormalizePhoneNumber, normalizePhone } from '../../utils'
import { useNotifications } from '../../hooks/useNotifications'
import PageHeader from '../../components/headers/PageHeader'
import ChangePasswordModal from './ChangePasswordModal'
import { applyCurrentUserUpdate } from '../../store/actions/auth'
import { useDispatch } from 'react-redux'
import { useCustomMutation } from '../../hooks/useCustomMutation'
import axios from 'axios'
import getVendorSlug from '../../utils/getVendorSlug'

const MyProfile = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const currentUser = useCurrentUser()
  const vendorSlug = getVendorSlug()
  const { setBreadcrumbs } = useBreadcrumbs()
  const { newNotification } = useNotifications()
  const [modalOpened, setModalOpened] = useState(false)

  const [updateVendorUser, { loading }] = useCustomMutation({
    onCompleted: (vendorUser) => {
      dispatch(applyCurrentUserUpdate(vendorUser))
      newNotification({ success: t('userProfileUpdatedSuccessfully') })
    },
    rollbarOptions: { operationName: 'update_vendor_user', target: 'MyProfile' },
    mutationOptions: {
      enabled: !!currentUser?.id,
      mutationFn: (data) =>
        axios.patch(`${vendorSlug}/vendor_users/${currentUser.id}`, {
          vendorUser: { ...data },
        }),
      mutationKey: ['update_vendor_user'],
    },
  })

  const onSubmit = async (values) => {
    const data = {
      ...values,
      phoneNumber: denormalizePhoneNumber(values.phoneNumber),
    }

    if (values.officePhoneNumber) {
      data.officePhoneNumber = denormalizePhoneNumber(values.officePhoneNumber)
    }

    await updateVendorUser(data)
  }

  useEffect(() => {
    setBreadcrumbs([
      { label: t('profile'), link: '/myprofile' },
      { label: t('personalInformation') },
    ])
  }, [])

  const initialValues = {
    email: currentUser.email,
    lastName: currentUser.lastName,
    firstName: currentUser.firstName,
    phoneNumber: currentUser.phoneNumber ? normalizePhone(currentUser.phoneNumber) : '',
    officePhoneNumber: currentUser.officePhoneNumber
      ? normalizePhone(currentUser.officePhoneNumber)
      : '',
  }

  return (
    <>
      <PageHeader headerTitle={t('myProfile')} />
      <div className="bg-white w-full rounded drop-shadow-md mt-6 flex p-6">
        <div className="flex flex-col w-72">
          <Text size={sizes.XL2}>{t('personalInformation')}</Text>
          <Text className="mt-4" color={colors.GREY} size={sizes.BASE}>
            {t('profileDescription')}
          </Text>
        </div>
        <div className="pl-6 pt-6">
          <Text fontWeight={fontWeight.MEDIUM} size={sizes.LG}>
            {t('profile')}
          </Text>

          <Form
            initialValues={initialValues}
            onSubmit={onSubmit}
            render={({ handleSubmit, dirty }) => {
              return (
                <form className="flex flex-col mt-6 w-96" onSubmit={handleSubmit}>
                  <div className="w-full">
                    <Field name="firstName">
                      {({ input, meta }) => {
                        return (
                          <Input
                            errorMessage={
                              meta.error && meta.touched && meta.submitFailed
                                ? meta.error
                                : undefined
                            }
                            id={input.name}
                            label={t('firstName')}
                            placeholder={t('firstName')}
                            tabIndex="1"
                            {...input}
                          />
                        )
                      }}
                    </Field>
                  </div>

                  <div className="w-full mt-6">
                    <Field name="lastName">
                      {({ input, meta }) => {
                        return (
                          <Input
                            errorMessage={
                              meta.error && meta.touched && meta.submitFailed
                                ? meta.error
                                : undefined
                            }
                            id={input.name}
                            label={t('lastName')}
                            placeholder={t('lastName')}
                            tabIndex="1"
                            {...input}
                          />
                        )
                      }}
                    </Field>
                  </div>

                  <div className="w-full mt-6">
                    <Field name="email">
                      {({ input, meta }) => {
                        return (
                          <Input
                            errorMessage={
                              meta.error && meta.touched && meta.submitFailed
                                ? meta.error
                                : undefined
                            }
                            id={input.name}
                            label={t('email')}
                            placeholder={t('email')}
                            tabIndex="1"
                            type="email"
                            disabled
                            {...input}
                          />
                        )
                      }}
                    </Field>
                  </div>

                  <div className="w-full mt-6">
                    <Field name="phoneNumber" parse={normalizePhone}>
                      {({ input, meta }) => {
                        return (
                          <Input
                            errorMessage={
                              meta.error && meta.touched && meta.submitFailed
                                ? meta.error
                                : undefined
                            }
                            id={input.name}
                            label={t('mobilePhone')}
                            placeholder={t('phoneNumber')}
                            tabIndex="1"
                            {...input}
                          />
                        )
                      }}
                    </Field>
                  </div>

                  <div className="w-full mt-6">
                    <Field name="officePhoneNumber" parse={normalizePhone}>
                      {({ input, meta }) => {
                        return (
                          <Input
                            errorMessage={
                              meta.error && meta.touched && meta.submitFailed
                                ? meta.error
                                : undefined
                            }
                            id={input.name}
                            label={t('officePhone')}
                            placeholder={t('phoneNumber')}
                            tabIndex="1"
                            {...input}
                          />
                        )
                      }}
                    </Field>
                  </div>

                  <div className="w-52 mt-6">
                    <Button
                      className="w-full"
                      disabled={loading || !dirty}
                      iconAlign="left"
                      label={t('save')}
                      size={sizes.BASE}
                      testData="submit-profile"
                      type="submit"
                      variant={buttonsVariants.PRIMARY}
                    />
                  </div>
                  <div className="mt-6">
                    <Text size={sizes.LG}>{t('password')}</Text>
                  </div>
                  <div className="w-52 mt-3">
                    <Button
                      className="w-full"
                      iconAlign="left"
                      label={t('changePassword')}
                      onClick={() => setModalOpened(true)}
                      size={sizes.BASE}
                      testData="change-password"
                      variant={buttonsVariants.SECONDARY}
                    />
                  </div>
                </form>
              )
            }}
            validate={(values) => ({
              email: validateRequiredField(values.email) || validateEmail(values.email),
              lastName: validateRequiredField(values.lastName),
              firstName: validateRequiredField(values.firstName),
              phoneNumber: validatePhoneNumber(values.phoneNumber),
              officePhoneNumber: values.officePhoneNumber
                ? validatePhoneNumber(values.officePhoneNumber)
                : undefined,
            })}
          />
        </div>
      </div>
      {modalOpened && <ChangePasswordModal isOpened={modalOpened} setIsOpened={setModalOpened} />}
    </>
  )
}

export default MyProfile
