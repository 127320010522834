import React, { useCallback, useState, useMemo } from 'react'
import cx from 'classnames'
import {
  Button,
  LoadingSpinner,
  NarrowList,
  Text,
  Dialog,
  Tooltip,
  ConfirmationDialog,
  FormattedDate,
  Flex,
} from '../../ui-kit'
import sizes from '../../ui-kit/sizes'
import { useTranslation } from 'react-i18next'
import styles from './teamManagement/styles.module.scss'
import EditUserForm from '../../components/user/EditUserForm'
import InviteUserForm from '../../components/user/InviteUserForm'
import UserActions from './teamManagement/UserActions'
import { useNotifications } from '../../hooks/useNotifications'
import { useDirtyFormAlert } from '../../hooks/useDirtyFormAlert'
import buttonsVariants from '../../ui-kit/buttonsVariants'
import colors from '../../ui-kit/colors'
import fontWeight from '../../ui-kit/fontWeight'
import Heading from '../../ui-kit/text/Heading'
import getVendorSlug from '../../utils/getVendorSlug'
import { useCustomQuery } from '../../hooks/useCustomQuery'
import axios from 'axios'
import { useCurrentUser } from '../../hooks/useCurrentUser'
import { UPDATE_OPERATIONS, useCustomMutation } from '../../hooks/useCustomMutation'
import { VENDOR_USERS_KEY } from '../../constants/queryKeys'
import { normalizePhone } from '../../utils'

const TeamManagement = () => {
  const { t } = useTranslation()
  const { newNotification } = useNotifications()
  const currentUser = useCurrentUser()
  const vendorSlug = getVendorSlug()
  const [editableUser, setEditableUser] = useState(null)
  const [isOpenedDeleteModal, setIsOpenedDeleteModal] = useState(false)
  const [isEditUserModalOpen, setIsEditUserModalOpen] = useState(false)
  const [isInviteUserModalOpen, setIsInviteUserModalOpen] = useState(false)

  const { data: vendorUsers, loading } = useCustomQuery({
    queryOptions: {
      queryKey: [VENDOR_USERS_KEY],
      enabled: !!vendorSlug,
      queryFn: () => axios.get(`${vendorSlug}/vendor_users`),
    },
    rollbarOptions: { operationName: 'vendor_users', target: 'TeamManagement' },
  })
  const userList = vendorUsers?.data || []

  const teamManagementErrorMapping = {
    taken: (source) => t('isAlreadyExists', { source }),
    invalid: (source) => t('isInvalid', { source }),
  }

  const [createVendorUser, { loading: createVendorUserLoading }, updateQuery] = useCustomMutation({
    onCompleted: (vendorUser) => {
      updateQuery({
        queryKey: [VENDOR_USERS_KEY],
        record: vendorUser,
        operation: UPDATE_OPERATIONS.ADD,
      })
      newNotification({ success: t('vendorUserAddedSuccessfully') })
      setIsInviteUserModalOpen(false)
    },
    rollbarOptions: { operationName: 'create_vendor_user', target: 'TeamManagement' },
    mutationOptions: {
      mutationFn: (data) => {
        return axios.post(`${vendorSlug}/vendor_users`, {
          vendorUser: { ...data },
        })
      },
      mutationKey: ['create_vendor_user'],
    },
    onFailed: (error) => {
      let errorMsg = ''
      const errorData = error?.data
      if (errorData?.errors?.email?.length) {
        errorMsg = errorData?.errors?.email.reduce((acc, item) => {
          const mappedMsg = teamManagementErrorMapping[item.error]
            ? teamManagementErrorMapping[item.error](t('Email'))
            : ''
          return mappedMsg ? acc.concat(` ${mappedMsg}.`) : acc
        }, errorMsg)
      }
      if (errorData?.errors?.phone_number?.length) {
        errorMsg = errorData?.errors?.phone_number.reduce((acc, item) => {
          const mappedMsg = teamManagementErrorMapping[item.error]
            ? teamManagementErrorMapping[item.error](t('phoneNumber'))
            : ''
          return mappedMsg ? acc.concat(` ${mappedMsg}.`) : acc
        }, errorMsg)
      }
      errorMsg && newNotification({ error: errorMsg })
    },
  })
  const [updateVendorUser, { loading: updateVendorUserLoading }] = useCustomMutation({
    onCompleted: (vendorUser) => {
      updateQuery({ queryKey: [VENDOR_USERS_KEY], record: vendorUser })
      newNotification({ success: t('userUpdatedSuccessfully') })
      setIsEditUserModalOpen(false)
    },
    rollbarOptions: { operationName: 'update_vendor_user', target: 'TeamManagement' },
    mutationOptions: {
      mutationFn: (data) =>
        axios.patch(`${vendorSlug}/vendor_users/${editableUser.id}`, {
          vendorUser: { ...data },
        }),
      mutationKey: ['update_vendor_user'],
    },
  })
  const [deleteVendorUser, { loading: deleteVendorUserLoading }] = useCustomMutation({
    onCompleted: () => {
      updateQuery({
        queryKey: [VENDOR_USERS_KEY],
        record: editableUser,
        operation: UPDATE_OPERATIONS.DELETE,
      })
      newNotification({ success: t('userWasDeletedSuccessfully') })
      setIsOpenedDeleteModal(false)
    },
    rollbarOptions: { operationName: 'delete_vendor_user', target: 'TeamManagement' },
    mutationOptions: {
      mutationFn: () => axios.delete(`${vendorSlug}/vendor_users/${editableUser.id}`),
      mutationKey: ['delete_vendor_user'],
    },
  })
  const [inviteVendorUser, { loading: inviteVendorUserLoading }] = useCustomMutation({
    onCompleted: (vendorUser) => {
      updateQuery({ queryKey: [VENDOR_USERS_KEY], record: vendorUser })
      newNotification({ success: t('userInvited') })
    },
    rollbarOptions: { operationName: 'invite_vendor_user', target: 'TeamManagement' },
    mutationOptions: {
      mutationFn: (id) => axios.patch(`${vendorSlug}/vendor_users/${id}/resend_invitation`),
      mutationKey: ['invite_vendor_user'],
    },
  })

  const handleEditUser = (user) => {
    setEditableUser(user)
    setIsEditUserModalOpen(true)
  }

  const handleDeleteUser = (user) => {
    setEditableUser(user)
    setIsOpenedDeleteModal(true)
  }
  const handleArchive = async () => {
    await deleteVendorUser()
  }

  const handleSendInvitation = async (user) => {
    if (inviteVendorUserLoading) {
      return
    }

    await inviteVendorUser(user.id)
  }

  const colClasses = 'flex flex-col justify-center'
  const renderTitleChild = () => (
    <Button
      iconName="plusCircle"
      iconType="outline"
      label={t('addUser')}
      onClick={() => {
        setIsInviteUserModalOpen(true)
      }}
      size={sizes.BASE}
      testData="add-user-cta"
      variant={buttonsVariants.SECONDARY}
    />
  )
  const renderUsersList = () =>
    userList.map((user) => (
      <>
        <div className={cx(styles.listSection)}>
          <Text className={colClasses} size={sizes.SM}>
            {user.firstName || user.lastName ? `${user.firstName} ${user.lastName}` : '--'}
          </Text>
        </div>
        <div className={cx(styles.listSection, styles.listSectionExtraOffset)}>
          <Tooltip content={user.email}>
            <Text className={colClasses} size={sizes.SM}>
              {user.email || '--'}
            </Text>
          </Tooltip>
        </div>
        <div className={cx(styles.listSectionFixed)}>
          <Text className={colClasses} size={sizes.SM}>
            {normalizePhone(user.phoneNumber) || '--'}
          </Text>
        </div>
        <div className={cx(styles.listSectionFixed)}>
          <Text className={colClasses} size={sizes.SM}>
            {normalizePhone(user.officePhoneNumber) || '--'}
          </Text>
        </div>
        <div className={cx(styles.listSectionFixed)}>
          <Text className={colClasses} size={sizes.SM}>
            <FormattedDate date={user.currentSignInAt} format="MM/dd/yyyy hh:mm:ss a" />
          </Text>
        </div>
        <div className={cx(styles.listIconSection, styles.listIconSectionShort)}>
          <UserActions
            onDelete={() => handleDeleteUser(user)}
            onEdit={() => handleEditUser(user)}
            sendInvitation={() => handleSendInvitation(user)}
            user={user}
            userId={currentUser?.id}
            userMembershipRole={currentUser?.membershipRole}
          />
        </div>
      </>
    ))

  const closeForm = useCallback(() => {
    if (isEditUserModalOpen) {
      setIsEditUserModalOpen(false)
    } else if (isInviteUserModalOpen) {
      setIsInviteUserModalOpen(false)
    }
  }, [isEditUserModalOpen, setIsEditUserModalOpen, isInviteUserModalOpen, setIsInviteUserModalOpen])
  const { isFormDirty, setDirtyFormState, requestClose } = useDirtyFormAlert({
    closeForm,
  })

  const renderModalContent = () => {
    if (isEditUserModalOpen) {
      return (
        <EditUserForm
          closeForm={closeForm}
          isFormDirty={isFormDirty}
          loading={updateVendorUserLoading}
          requestClose={requestClose}
          setDirtyFormState={setDirtyFormState}
          setIsOpenedModal={setIsEditUserModalOpen}
          updateVendorUser={updateVendorUser}
          user={editableUser}
        />
      )
    }
    if (isInviteUserModalOpen) {
      return (
        <InviteUserForm
          closeForm={closeForm}
          createVendorUser={createVendorUser}
          isFormDirty={isFormDirty}
          loading={createVendorUserLoading}
          requestClose={requestClose}
          setDirtyFormState={setDirtyFormState}
        />
      )
    }
  }
  const listColumns = useMemo(
    () => [
      {
        title: t('name'),
        className: cx(styles.listSection),
      },
      {
        title: t('email'),
        className: cx(styles.listSection, styles.listSectionExtraOffset),
      },
      {
        title: t('mobilePhone'),
        className: cx(styles.listSectionFixed),
      },
      {
        title: t('officePhone'),
        className: cx(styles.listSectionFixed),
      },
      {
        title: t('lastLogin'),
        className: cx(styles.listSectionFixed),
      },
      {
        title: '',
        className: cx(styles.listIconSection, styles.listIconSectionShort),
      },
    ],
    [t, styles],
  )

  return (
    <>
      <div>
        <Flex column>
          <Flex className="w" justifyContent="between" row>
            <Heading className="mb-4 text-xl" fontWeight={fontWeight.MEDIUM}>
              {t('teamManagement')}
            </Heading>

            {renderTitleChild()}
          </Flex>
          <div className="w-full">
            {loading && <LoadingSpinner />}
            {!loading && (
              <NarrowList columns={listColumns} listItems={renderUsersList()} withColumns />
            )}
          </div>
        </Flex>
      </div>
      {(isEditUserModalOpen || isInviteUserModalOpen) && (
        <Dialog
          isOpened={isEditUserModalOpen || isInviteUserModalOpen}
          setIsOpened={requestClose}
          title={isEditUserModalOpen ? t('editUser') : t('addUser')}>
          {renderModalContent()}
        </Dialog>
      )}

      <ConfirmationDialog
        confirmationMessage={
          <Text size={sizes.SM}>
            {t('removeUserDescription')}
            <Text color={colors.BLACK} fontWeight={fontWeight.BOLD} size={sizes.SM}>
              {' '}
              {editableUser?.fullName}
            </Text>
            ?
          </Text>
        }
        isOpened={isOpenedDeleteModal}
        isSubmitButtonYesDisabled={deleteVendorUserLoading}
        onSubmitButtonNoClick={() => setIsOpenedDeleteModal(false)}
        onSubmitButtonYesClick={handleArchive}
        setIsOpened={setIsOpenedDeleteModal}
        title={t('removeUser')}
      />
    </>
  )
}

export default TeamManagement
