import React from 'react'
import sizes from '../../ui-kit/sizes'
import { useTranslation } from 'react-i18next'
import fontWeight from '../../ui-kit/fontWeight'
import { Flex, Text } from '../../ui-kit'
import { useCurrentVendor } from '../../hooks/useCurrentVendor'

const SupplierMobileInfo = () => {
  const { t } = useTranslation()
  // TODO no extension in vendor
  const vendor = useCurrentVendor()
  return (
    <Flex alignItems="center pb-4" column>
      <Text color="text-black-500" fontWeight={fontWeight.MEDIUM} size={sizes.XL}>
        {t('welcomeToThe')}
      </Text>
      <Text align="center" fontWeight={fontWeight.SEMIBOLD} size={sizes.XL}>
        {vendor?.name}
      </Text>
      <Text color="text-black-500" fontWeight={fontWeight.MEDIUM} size={sizes.XL}>
        {t('paymentPage')}
      </Text>
      <Text className="text-center mt-4" color="text-warmBlack-400" size={sizes.SM}>
        {t('24/7AccessToManageYourAccount')}
      </Text>
    </Flex>
  )
}

SupplierMobileInfo.propTypes = {}

SupplierMobileInfo.defaultProps = {}

export default SupplierMobileInfo
