import PropTypes from 'prop-types'
import React, { useState, useMemo } from 'react'
import { Field, Form } from 'react-final-form'
import { Flex, Text } from '../../../ui-kit'
import { validateRequiredField } from '../../../utils/validators'
import { useTranslation } from 'react-i18next'
import { reasonList } from './reasonList'
import colors from '../../../ui-kit/colors'
import Textarea from '../../../ui-kit/components/inputs/Textarea'
import sizes from '../../../ui-kit/sizes'
import Button from '../../../ui-kit/components/buttons/Button'
import buttonsVariants from '../../../ui-kit/buttonsVariants'
import NDropdown from '../../../ui-kit/components/dropdown/NDropdown'
import { useBeforeUnload } from '../../../hooks/useBeforeUnload'
import AlertModal from '../../../ui-kit/components/alertModal/AlertModal'
import { invoiceRefundableDataShape } from '../../../types/invoiceShapes'
import FormattedDate from '../../../ui-kit/components/text/FormattedDate'
import { parseWithMoneyFormat } from '../../../ui-kit/components/text/Money'
import CurrencyInput from '../../../ui-kit/components/inputs/CurrencyInput'

const IssueRefundForm = ({
  data,
  closeForm,
  isFormDirty,
  requestClose,
  setDirtyFormState,
  refundTransaction,
  loading,
}) => {
  const onSubmit = async (values) => {
    const amountCentsValue = Number(String(values.amountCents).replaceAll(',', ''))
    const variables = {
      amountCents: Math.round(amountCentsValue * 100),
      reason: values.reason,
      memo: values.memo,
    }
    await refundTransaction(variables)
  }

  const { t } = useTranslation()
  const initialValues = useMemo(
    () => ({
      amountCents: data.refundableAmountCents ? data.refundableAmountCents / 100 : 0,
      reason: '',
      memo: '',
    }),
    [data],
  )

  const fieldValidation = (value) => {
    if (Math.round(value * 100) > data.refundableAmountCents) {
      return t('invalidValue')
    }
  }

  return (
    <Form
      initialValues={initialValues}
      onSubmit={onSubmit}
      render={({ handleSubmit, dirty }) => {
        useBeforeUnload({ when: dirty })
        dirty !== isFormDirty && setDirtyFormState(dirty)

        return (
          <form className="flex flex-col w-[50rem] px-2" onSubmit={handleSubmit}>
            <div className="w-full">
              <Flex justifyContent="between">
                <div>
                  <Text className="pr-1" color="text-black-500" size={sizes.SM}>
                    {t('id')}:
                  </Text>
                  <Text size={sizes.SM}>{data.id}</Text>
                </div>
                {!data?.refundableAmountCents && (
                  <div>
                    <Text color={colors.RED}>{t('paymentFullyRefunded')}</Text>
                  </div>
                )}
              </Flex>
              <div className="flex flex-col w-full">
                <div className="mt-6">
                  <div className={'flex flex-row w-full gap-4'}>
                    <div className="w-3/12 flex flex-col">
                      <Text size={sizes.SM}>{t('date')}</Text>
                      <Text color={colors.GREY} size={sizes.XS}>
                        <FormattedDate date={data.createdAt} />
                      </Text>
                    </div>
                    <div className="w-3/12 flex flex-col">
                      <Text size={sizes.SM}>{t('transactionId')}</Text>
                      <Text color={colors.GREY} size={sizes.XS}>
                        {data.id}
                      </Text>
                    </div>
                    <div className="w-3/12 flex flex-col">
                      <Text size={sizes.SM}>{t(data.paymentMethod.type)}</Text>
                      <Text color={colors.GREY} size={sizes.XS}>
                        {data.paymentMethod.title}
                      </Text>
                    </div>
                    <div className="w-3/12 flex flex-col">
                      <Field name="amountCents" validate={fieldValidation}>
                        {({ input, meta }) => {
                          return (
                            <CurrencyInput
                              errorMessage={
                                meta.error && meta.touched && meta.submitFailed
                                  ? meta.error
                                  : undefined
                              }
                              helperText={`${t('available')}: ${parseWithMoneyFormat(
                                data.refundableAmountCents,
                              )}`}
                              id={input.name}
                              name={input.name}
                              onChange={input.onChange}
                              value={input.value}
                            />
                          )
                        }}
                      </Field>
                    </div>
                  </div>
                </div>

                <div className="mt-6">
                  <Field name="reason">
                    {({ input, meta }) => {
                      const [hasValue, setHasValue] = useState(input.value !== '')
                      return (
                        <NDropdown
                          errorMessage={
                            !hasValue && meta.error && meta.submitFailed ? meta.error : undefined
                          }
                          id={input.name}
                          inputClassName="w-full"
                          label={t('reason')}
                          listClass="max-h-40 p-1 pt-0 pb-0"
                          name={input.name}
                          onBlur={() => {
                            setHasValue(input.value !== '')
                          }}
                          onChange={input.onChange}
                          onFocus={() => {
                            setHasValue(true)
                          }}
                          options={reasonList}
                          placeholder={t('reason')}
                          value={input.value}
                        />
                      )
                    }}
                  </Field>
                </div>

                <div className="w-full mt-6">
                  <Field name="memo">
                    {({ input, meta }) => {
                      return (
                        <Textarea
                          errorMessage={
                            meta.error && meta.touched && meta.submitFailed ? meta.error : undefined
                          }
                          id={input.name}
                          inputClassName="h-44"
                          label={t('memoOptional')}
                          placeholder={t('memo')}
                          {...input}
                        />
                      )
                    }}
                  </Field>
                </div>
              </div>

              <div className="w-full mt-6 flex flex-row justify-end">
                <Button
                  className="mr-2"
                  label={t('cancel')}
                  onClick={requestClose}
                  testData="cancel-invoice-refund"
                  variant={buttonsVariants.TERTIARY}
                />
                <Button
                  disabled={loading || !data?.refundableAmountCents}
                  label={t('issueRefund')}
                  testData="submit-issue-refund"
                  type="submit"
                />
              </div>
            </div>

            <AlertModal confirmClose={closeForm} />
          </form>
        )
      }}
      validate={(values) => ({
        reason: validateRequiredField(values.reason),
      })}
    />
  )
}

IssueRefundForm.propTypes = {
  data: invoiceRefundableDataShape,
  loading: PropTypes.bool,
  closeForm: PropTypes.func.isRequired,
  isFormDirty: PropTypes.bool.isRequired,
  requestClose: PropTypes.func.isRequired,
  setIsOpenedModal: PropTypes.func,
  setDirtyFormState: PropTypes.func.isRequired,
  refundTransaction: PropTypes.func.isRequired,
}

export default IssueRefundForm
