import React from 'react'
import { Navigate } from 'react-router-dom'
import getVendorSlug from '../../utils/getVendorSlug'
import PT from 'prop-types'

const NavigateWithVendor = ({ to, replace }) => {
  const vendorSlug = getVendorSlug()
  return <Navigate replace={replace} to={`${vendorSlug}${to}`} />
}

export default NavigateWithVendor

NavigateWithVendor.propTypes = {
  to: PT.string.isRequired,
  replace: PT.bool,
}
NavigateWithVendor.defaultProps = {
  replace: false,
}
