import {
  APPLY_CURRENT_USER_UPDATE,
  APPLY_USER_SIGN_IN,
  APPLY_USER_SIGN_OUT,
  SET_VENDOR_DATA,
  UPDATE_AUTH_TOKEN,
} from '../reducers/auth'

const applyUserSignIn = (userAuth, currentUser) => ({
  type: APPLY_USER_SIGN_IN,
  payload: { userAuth, currentUser },
})

const updateAuthToken = (userAuth) => ({
  type: UPDATE_AUTH_TOKEN,
  payload: { userAuth },
})

const applyUserSignOut = () => ({
  type: APPLY_USER_SIGN_OUT,
})

const applyCurrentUserUpdate = (currentUser) => ({
  type: APPLY_CURRENT_USER_UPDATE,
  payload: { currentUser },
})

const setVendorData = (payload) => ({
  type: SET_VENDOR_DATA,
  payload,
})

export { applyUserSignIn, updateAuthToken, applyUserSignOut, applyCurrentUserUpdate, setVendorData }
